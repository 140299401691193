import React from 'react';
import { Pie } from "react-chartjs-2";

const DisconnectionDetailsChart = ({ data = [0, 0, 0, 0, 0, 0] }) => {

  const disconnectionDetailsChartData = {
    labels: ["Meter Removed", "Disconnected", "Vacant", "Pole Disconnected", "Govt. Installation", "Amount Paid"],
    datasets: [
      {
        label: "Disconnection Details",
        backgroundColor: ["#3B1FED", "#D81B60", "#9C27B0", "#FF9800", "#00ffe8", "#117a65"],
        // hoverBackgroundColor: ["#501800", "#D81B60", "#9C27B0", "#FF9800"],
        data,
      },
    ],
  };

  return <Pie
    data={disconnectionDetailsChartData}
    options={{
      tooltips: {
        enabled: false,
      },
      plugins: {
        labels: false,
        title: {
          display: false,
          text: "Average Rainfall per month",
          fontSize: 10,
        },
        legend: {
          display: true,
          position: "right",
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      showAllTooltips: true,
    }}
  />
}

export default DisconnectionDetailsChart;