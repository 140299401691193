import React from "react";

const Modal = ({ children, title, visible, onModalClose, okBtnDisabled = false, okBtnLoading = false, okAction=()=>{},bodyStyle }) => {

  const style = {
    display: visible ? 'block' : 'none'
  };
  return (
    <React.Fragment>
      <div className="modal export-excel-modal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={style}>
        <div className="modal-dialog">
          <div className="modal-content">
            {/* ////////////////Header////////////////// */}
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel">
                {title}
              </h5>
              <button
                onClick={onModalClose}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close">
              </button>
            </div>
            {/* //////////////////////////Body//////////////////// */}
            <div className="modal-body"
              style={{ maxHeight: '80vh', overflow: "auto",...bodyStyle }}>
              {children}
            </div>
            {/* ///////////////////////////Footer//////////////////// */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onModalClose}>
                Cancel
              </button>
              <button
                type="button" 
                onClick={okAction}
                disabled={okBtnDisabled}
                className="btn btn-primary">
                OK{'\t'}
                {okBtnLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
              </button>
            </div>
          </div>
        </div>
      </div>
      {visible && (
        <div className="modal-backdrop fade show"></div>
      )}
    </React.Fragment>
  );
};

export default Modal;
