import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import meterImage from '../../assets/img/meter.svg';
import doorlockImage from '../../assets/img/doorlock.png';
import newInstallationImage from '../../assets/img/new-installation.png';
import unbilledImage from '../../assets/img/unbilled.png';
import abnormalImage from '../../assets/img/abnormal.png';
import subnormalImage from '../../assets/img/subnormal.png';

import vacantImage from '../../assets/img/vacant.png';
import zerpConsumptionImage from '../../assets/img/zerp-consumption.svg';
import mnrImage from '../../assets/img/mnr.svg';
import rightarrowImage from '../../assets/img/main-tab-arrow.svg';
import MyInstallations from './Tables/MyInstallations';
import NewInstallations from './Tables/NewInstallations';
import Unbilled from './Tables/Unbilled';
import Vacant from './Tables/Vacant';
import ZeroConsumption from './Tables/ZeroConsumption';
import MNR from './Tables/MNR';
import Doorlock from './Tables/Doorlock';
import { getBillingCount } from '../../services/api/billingService'
import { AuditMonthContext } from '../../context/AuditMonthContext';
import { NotifierContext } from '../../context/NotifierContext';
import FullScreenLoader from '../../components/atoms/FullScreenLoader'
// import { MrCodeContext } from '../../context/MrCodeContext'
import { getMRs } from '../../services/api/mrService'
import Abnormal from './Tables/Abnormal';
import Subnormal from './Tables/Subnormal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const List = (props) => {
  const { auditMonthYear, mrCode, setMrCode } = useContext(AuditMonthContext)
  const notifier = useContext(NotifierContext);

  const [categoriesArray, setCategoriesArray] = useState([
    {
      name: "My Installations",
      slug: "my_installations",
      counts: '0',
      icon: meterImage,
    },
    {
      name: "New Installations",
      slug: "new_installations",
      counts: '0',
      icon: newInstallationImage,
    },
    {
      name: "Unbilled",
      slug: "unbilled",
      counts: '0',
      icon: unbilledImage,
    },
    {
      name: "Vacant",
      slug: "vacant",
      counts: '0',
      icon: vacantImage,
    },
    {
      name: "Zero Consumption",
      slug: "zero_consumption",
      counts: '0',
      icon: zerpConsumptionImage,
    },
    {
      name: "MNR",
      slug: "mnr",
      counts: '0',
      icon: mnrImage,
    },
    {
      name: "Doorlock",
      slug: "doorlock",
      counts: '0',
      icon: doorlockImage,
    },
    {
      name: "Abnormal",
      slug: "abnormal",
      counts: '0',
      icon: abnormalImage,
    },
    {
      name: "Subnormal",
      slug: "subnormal",
      counts: '0',
      icon: subnormalImage,
    }
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBillingCount();
    fetchMrCodes()
  }, [auditMonthYear])

  const fetchBillingCount = async () => {
    try {
      const response = await getBillingCount(auditMonthYear);

      let categoriesArrayCopy = [...categoriesArray];
      const { myInstallation,
        newInstallation,
        unbilled,
        vacant,
        zeroConsumption,
        mnr,
        doorLock,
        abnormal,
        subnormal
      } = response.data.billingCount
      const { billingCount } = response.data

      categoriesArrayCopy[0].counts = billingCount ? myInstallation : '0';
      categoriesArrayCopy[1].counts = billingCount ? newInstallation : '0';
      categoriesArrayCopy[2].counts = billingCount ? unbilled : '0';
      categoriesArrayCopy[3].counts = billingCount ? vacant : '0';
      categoriesArrayCopy[4].counts = billingCount ? zeroConsumption : '0';
      categoriesArrayCopy[5].counts = billingCount ? mnr : '0';
      categoriesArrayCopy[6].counts = billingCount ? doorLock : '0';
      categoriesArrayCopy[7].counts = billingCount ? abnormal : '0';
      categoriesArrayCopy[8].counts = billingCount ? subnormal : '0';

      setCategoriesArray(categoriesArrayCopy);
      setLoading(false)
    }

    catch (err) {
      notifier.notifyError(err)
    }
  }

  const fetchMrCodes = async () => {
    try {
      const response = await getMRs("", 1, 100);
      let mrCodes = response.data.MRList.map(item => item.userInfo.mrCode)
      setMrCode(mrCodes)
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }


  var components = {
    my_installations: MyInstallations,
    new_installations: NewInstallations,
    unbilled: Unbilled,
    vacant: Vacant,
    zero_consumption: ZeroConsumption,
    mnr: MNR,
    doorlock: Doorlock,
    abnormal: Abnormal,
    subnormal: Subnormal
  };

  let query = useQuery();
  let history = useHistory();

  let slug = query.get("tab") || 'my_installations';

  let ComponentName = components[query.get("tab") || 'my_installations']

  const [categories, setCategories] = useState(categoriesArray);

  const redirectHandler = (slug) => {
    history.push('/billing?tab=' + slug);
  }

  return (
    <React.Fragment>
      {
        loading ?
          <FullScreenLoader />
          :
          <div className="my-installation-content">
            <div className="container pl-0 pt-0 pb-0">
              <div className="row">
                <div className="col-md-4 col-lg-3">
                  <div className="tc-audit-no-list">
                    {
                      categories.map((category, index) => {
                        return (
                          <div onClick={() => redirectHandler(category.slug)} key={index} className={`tc-audit-no-collapse ${slug == category.slug ? 'active' : ''} pt-3 pb-3 ps-2 pe-2`}>
                            <card className="collapse-text">
                              <span className="iconbox"><img className="img-fluid" src={category.icon} /></span>
                              <div className="mb-0 tc-audit-no-title ms-2 w-100">
                                <p className="mb-1 tc-no">{category.name}</p>
                                <p className="mb-0 tc-no-text">{category.counts}</p>
                              </div>
                            </card>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                <ComponentName />
              </div>
            </div>
          </div>
      }
    </React.Fragment>
  );
}

export default List;