import React, { useState, useEffect, useContext } from 'react';
import Filter from '../../Shared/Filter';
import "react-datepicker/dist/react-datepicker.css";
import filterIcon from '../../../assets/img/filter.png';
import excelIcon from '../../../assets/img/export-dark-icon.png';
import BillingTabSearch from '../Shared/BillingTabSearch';
import { getOtherInstallations } from '../../../services/api/billingService'
import { AuditMonthContext } from '../../../context/AuditMonthContext';
import { NotifierContext } from '../../../context/NotifierContext';
import NoDataFound from '../../../components/atoms/NoDataFound'
import Paginator from '../../../components/molecules/Paginator';
import Ellipsis from '../../../components/molecules/Ellipsis'
import { perPageCount } from '../../../assets/offlineData/apiCallDetails'
import { installationsTariffs } from '../../../assets/offlineData/FilterOptions'
import { clearTime } from '../../../services/utils/setTimeOut'
import exclamation from '../../../assets/img/info.svg';
import { DateToYYYYMM } from '../../../services/utils/date';


const Abnormal = () => {


    const [searchRRNumber, setSearchRRNumber] = useState("")
    const [loading, setLoading] = useState(true);

    ////////////////////////////////////////////////Filter States///////////////////////////////////////////
    const [selectedMR, setSelectedMR] = useState("");
    const [selectedReadingDay, setSelectedReadingDay] = useState("");

    const [tariff, setTariff] = useState([])
    const [duration, setDuration] = useState([])
    const [filterVisible, setFilterVisible] = useState(false);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageData, setPageData] = useState({
        currentPage: 1,
        nextPage: 1,
        previousPage: 1,
        lastPage: 1
    })
    const [installationData, setInstallationData] = useState([])
    const [installationsCount, setInstallationsCount] = useState("")

    const { auditMonthYear, mrCode } = useContext(AuditMonthContext)
    const notifier = useContext(NotifierContext);

    const filterHandler = (event) => {
        event.preventDefault();
        setFilterVisible(!filterVisible);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchAbnormalData(1)
        }, clearTime);
        return () => {
            clearTimeout(timer);
        }
    }, [searchRRNumber, auditMonthYear])

    const fetchAbnormalData = async (pageNumber) => {
        try {
            setLoading(true)
            const response = await getOtherInstallations(auditMonthYear, pageNumber, searchRRNumber, '', selectedReadingDay, selectedMR, duration, tariff, [], "abnormal");
            setInstallationData(response.data.installations);
            setInstallationsCount(response.data.count)
            const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
            setPageData({
                ...pageData,
                currentPage: currentPage,
                nextPage: nextPage,
                previousPage: previousPage,
                lastPage: lastPage,
            })
            setLoading(false)
        }
        catch (err) {
            notifier.notifyError(err)
            setLoading(false)
        }
    }

    const searchByMrCodeAndReadingDay = async () => {
        if (!selectedMR && !selectedReadingDay) {
            notifier.notifyError("Please select Mr code or Reading day")
            return
        }
        fetchAbnormalData(1)
    }

    const applyFilters = async () => {
        fetchAbnormalData(1)
        setFilterVisible(false)
    }

    const resetFilter = async () => {
        try {
            setTariff([])
            setDuration([])
            setLoading(true)
            const response = await getOtherInstallations(auditMonthYear, 1, searchRRNumber, '', selectedReadingDay, selectedMR, [], [], [], "abnormal");
            setInstallationData(response.data.installations);
            setInstallationsCount(response.data.count)
            const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
            setPageData({
                ...pageData,
                currentPage: currentPage,
                nextPage: nextPage,
                previousPage: previousPage,
                lastPage: lastPage,
            })
            setLoading(false)
            setFilterVisible(false)
        }
        catch (err) {
            notifier.notifyError(err)
            setLoading(false)
        }
    }

    const onClearMrCodeAndReadingDay = async () => {
        try {
            setSelectedMR('')
            setSelectedReadingDay('')
            setLoading(true)
            const response = await getOtherInstallations(auditMonthYear, 1, searchRRNumber, '', "", "", duration, tariff, [], "abnormal");
            setInstallationData(response.data.installations);
            setInstallationsCount(response.data.count)
            const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
            setPageData({
                ...pageData,
                currentPage: currentPage,
                nextPage: nextPage,
                previousPage: previousPage,
                lastPage: lastPage,
            })
            setLoading(false)
        }
        catch (err) {
            notifier.notifyError(err)
            setLoading(false)
        }
    }

    const filterData = [
        // {
        //     heading: "Duration",
        //     shape: "square",
        //     options: [
        //         ...durations
        //     ],
        //     value: duration,
        //     setValue: (selectedValue) => { duration.includes(selectedValue) ? setDuration(duration.filter(item => item !== selectedValue)) : setDuration([...duration, selectedValue]) }
        // },
        {
            heading: "Tariff",
            shape: "square",
            options: [
                ...installationsTariffs
            ],
            value: tariff,
            setValue: (selectedValue) => { tariff.includes(selectedValue) ? setTariff(tariff.filter(item => item !== selectedValue)) : setTariff([...tariff, selectedValue]) }
        },
    ]

    const getInstallationConsumption = (auditArray) => {
        const result = auditArray.filter(item => DateToYYYYMM(item.month) === auditMonthYear)
        return result[0]?.billing?.consumption ?? "----"
    }

    const getInstallationEMax = (auditArray) => {
        const result = auditArray.filter(item => DateToYYYYMM(item.month) === auditMonthYear)
        return result[0]?.billing?.EMax ?? "----"
    }

    return (
        <React.Fragment>
            <Filter
                footerStyle={{ position: 'relative' }}
                filterData={filterData}
                isFilterVisible={filterVisible}
                disableApply={(tariff.length >= 1 || duration.length >= 1) ? false : true}
                onFilterClose={filterHandler}
                onFiltersApply={() => applyFilters()}
                onResetApply={resetFilter} />
            <div className="col-md-9">
                <div className="row mt-35">
                    <div className="col-md-12">
                        <div className="comman-head">
                            <div className="installation-title">
                                <h1>Abnormal</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="main-tc-name-form mb-3">
                            <BillingTabSearch
                                MRs={mrCode}
                                selectedMR={selectedMR}
                                setSelectedMR={setSelectedMR}
                                showClearButton={selectedMR || selectedReadingDay ? true : false}
                                onClear={onClearMrCodeAndReadingDay}
                                selectedReadingDay={selectedReadingDay}
                                setSelectedReadingDay={setSelectedReadingDay}
                                onSearch={() => searchByMrCodeAndReadingDay()}
                            />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="list-view-box">
                                        <div className="search-heading">
                                            <div className="digit-installation-title mb-3">
                                                <h3 className="mb-4 ms-0 font-medium-500">
                                                    {installationsCount} Installations</h3>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="search-space">
                                                        <input type="text"
                                                            placeholder="RR Number"
                                                            maxLength="25"
                                                            value={searchRRNumber}
                                                            onChange={(e) => { setSearchRRNumber(e.target.value); }}
                                                            className="form-control search" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 text-right">
                                                    <div className="export-filter">
                                                        <a className="export-btn">
                                                            <img className="fa img-fluid" src={excelIcon} />&nbsp; <span>Export to Excel</span>
                                                        </a>
                                                        <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                                                            <img src={filterIcon} className="" width="18" height="12" />Filter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="pl-15" scope="col">#</th>
                                                        <th scope="col">MR Code</th>
                                                        <th scope="col">Reading Day</th>
                                                        <th scope="col">Account ID</th>
                                                        <th scope="col">RR Number</th>
                                                        <th scope="col">Tariff</th>
                                                        <th scope="col">Consumer Name</th>
                                                        <th scope="col">Sanction Load</th>
                                                        {/* <th scope="col">Duration</th> */}
                                                        <th scope="col">Consumption</th>
                                                        <th scope="col">E-Max Consumption</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        loading ? <NoDataFound loading={loading} /> : installationData.length === 0 && <NoDataFound message="No Installations Found!" />
                                                    }
                                                    {
                                                        !loading &&
                                                        installationData.map((installation, index) => {
                                                            let presentMonthData = installation?.audit.filter((data) => auditMonthYear == data?.month?.slice(0, 7))
                                                            let from = presentMonthData[0] ? 'from' in presentMonthData[0]?.billing : false
                                                            return (
                                                                < tr key={index}
                                                                //  style={{ background: index < 3 && 'rgba(60, 104, 195,0.2)' }}
                                                                >
                                                                    <td className="pl-15" scope="row">{((pageData.currentPage - 1) * perPageCount) + (index + 1)}</td>
                                                                    <td>{installation.MR?.mrCode ?? "----"}</td>
                                                                    <td style={{ textAlign: "center" }}>{installation.readingDay || "----"}</td>
                                                                    <td>
                                                                        {installation.accountId ?? "----"}
                                                                        {(index >= 0 && index <= 4) && <img className="img-fluid mb-2 mx-3" src={exclamation} />}

                                                                    </td>
                                                                    <td>{installation.RRNumber ?? "----"}</td>
                                                                    <td>{installation.tariff?.short ?? "----"}</td>
                                                                    <Ellipsis>
                                                                        {'consumerName' in installation ? installation.consumerName : '-'}
                                                                    </Ellipsis>
                                                                    <td>{installation.sanctionedLoad?.kW} KW, {installation?.sanctionedLoad?.hp ?? "--"} HP</td>
                                                                    {/* <td> {`${from ? presentMonthData[0].billing?.from : '-'} Months`}</td> */}
                                                                    <td style={{ textAlign: "center" }}> {getInstallationConsumption(installation.audit)}</td>
                                                                    <td style={{ textAlign: "center" }}> {getInstallationEMax(installation.audit)}</td>


                                                                    {/* <td>{reading ? reading : '-'}</td>
                                  <td>{remark ? remarks.unBilled.map((value) =>
                                    remark == value.value && value.label
                                  ) : '-'}</td> */}
                                                                </tr>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="float-end" aria-label="Page navigation example">
                    <Paginator
                        currentPage={pageData.currentPage}
                        lastPage={pageData.lastPage}
                        onPressNext={() => { fetchAbnormalData(pageData.currentPage + 1) }}
                        onPressPrevious={() => { fetchAbnormalData(pageData.currentPage - 1) }}
                    />
                </nav>

            </div>
        </React.Fragment>
    );
}

export default Abnormal;