import React, {  useState } from 'react';
import { API } from 'aws-amplify';


const Test = () => {
    const [data, setData] = useState({
        message: "",
        data: [{
            name: ""
        }]
        
    })

    const handleButtonPress = async () => {
        try {
            const response = await API.get('baseURL', '/ae/meter-readers')
            setData(response)
        }
        catch (err) {
            console.log(err.response)
            setData(prevData=>{return(
                {...prevData,message:err.response.data.message}
            )})
        }

    }
    return (
        <div style={{}}>
            <button onClick={handleButtonPress}>TEST</button>
            <div>{data.message}</div>
            <ul>
                {data.length && data.data.map(item => (
                    <li>{item.name}</li>
                ))}
            </ul>

        </div>
    );
}

export default Test;