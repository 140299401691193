import { API } from 'aws-amplify'

export const getMRs = (searchString, pageNumber,perPage) => {
    return API.get('baseURL', '/ae/meter-readers', {
        queryStringParameters: {
            ...(searchString && {
                ...(isNaN(Number(searchString)) ? { name: searchString } : { mrCode: searchString })
            }),
            page:pageNumber,
            perPage

        }
    })
}

export const addMR = (body) => {
    return API.post('baseURL','/ae/meter-readers',{
        body
    })
}

export const updateMR = (id,body) => {
    return API.patch('baseURL', `/ae/meter-readers/${id}`, {
        body
    })
}