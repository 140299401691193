import React from 'react';
import { Bar } from "react-chartjs-2";

const CollectionDetailsChart = () => {

  const collectionDetailsChartData = {
    labels: ["wk1", "wk2", "wk3", "wk4", "wk5"],
    datasets: [
      {
        label: "Collection",
        backgroundColor: "rgba(156, 39, 176, 1)",
        borderColor: "rgba(156, 39, 176, 1)",
        borderWidth: 0,
        data: [34, 39, 80, 31, 56],
        barThickness: 14,
        maxBarThickness: 17,
      },
      {
        label: "Short Fall",
        backgroundColor: "rgba(59, 31, 237, 1)",
        borderColor: "rgba(59, 31, 237, 1)",
        borderWidth: 0,
        data: [65, 59, 30, 81, 120],
        barThickness: 14,
        maxBarThickness: 17,
      },
    ],
  };

  return <Bar
    data={collectionDetailsChartData}
    options={{
      plugins: {
        legend: {
          position: "bottom",
          display: true,
          align: "start",
        },
        title: {
          display: false,
          text: "Chart.js Doughnut Chart",
        },
        tooltips: {
          enabled: true,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    }}
  />
}

export default CollectionDetailsChart;