import React from "react";
import NoDataFound from '../../components/atoms/NoDataFound'

const TagTCModal = ({ isModalVisible = false,
    onModalClose = () => { },
    onPressOK = () => { },
    options = [],
    searchValue,
    onSearch,
    loadingTc = false,
    selectedTC,
    onSelectTC,
    btnDisable = false
}) => {


    const style = {
        display: isModalVisible ? 'block' : 'none'
    };
    return (
        <React.Fragment>
            <div className="modal export-excel-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={style}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">Tag / Re-tag TC</h6>
                            <button onClick={onModalClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: '70vh', overflow: 'auto' }}>
                            {/* <div className="search-space" style={{ marginBottom: 20, marginTOp: 20 }}>
                                <input type="text"
                                    value={searchValue}
                                    onChange={(searchTerm) => onSearch(searchTerm.target.value)}
                                    placeholder="Search for TCs"
                                    className="form-control search" />
                            </div> */}
                            {
                                loadingTc ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <NoDataFound loading={true} />
                                    </div>
                                    :
                                    <>
                                        {options.length === 0 && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <NoDataFound message={"No Data Found!"} />
                                        </div>}
                                    {options.map((option, index) => {

                                        return (
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    value={selectedTC.number}
                                                    onChange={() => onSelectTC(option)}
                                                    // checked={selectedTC.hasOwnProperty('transformer') ? selectedTC.transformer.number === option.number : selectedTC.number === option.number}
                                                    checked={option.number === selectedTC.number}
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1" />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault1"
                                                    onClick={() => onSelectTC(option)}>{`${option.number}, ${option.name}`}
                                                </label>
                                            </div>
                                        )
                                    })}
                                    </>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onModalClose}>Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={selectedTC?.number && !btnDisable ? false : true}
                                onClick={onPressOK}>OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isModalVisible && (
                <div className="modal-backdrop fade show"></div>
            )}
        </React.Fragment>
    );
};

export default TagTCModal;
