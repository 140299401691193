import React, { useState, useContext, useEffect } from 'react'
import filterIcon from '../../assets/img/filter.png';
import Filter from '../Shared/Filter';
import Modal from '../../components/atoms/Modal';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import excelIcon from '../../assets/img/export-dark-icon.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Paginator from '../../components/molecules/Paginator';
import { NotifierContext } from '../../context/NotifierContext';
import { AuditMonthContext } from '../../context/AuditMonthContext';
import NoDataFound from '../../components/atoms/NoDataFound'
import Ellipsis from '../../components/molecules/Ellipsis'
import { perPageCount } from '../../assets/offlineData/apiCallDetails'
import Select from 'react-select';
import { installationsTariffs, remarks, durations, arrears } from '../../assets/offlineData/FilterOptions'
import BillingTabSearch from '../Billing/Shared/BillingTabSearch';
import { getDisconnections, getDisconnectionsByMrCodeAndReadingDay, filterDisconnections } from '../../services/api/disconnectionService'
import { getMRs } from '../../services/api/mrService'
import { clearTime } from '../../services/utils/setTimeOut'
import DisconnectionUpload from './DisconnectionUpload';


const List = () => {

  const [selectedMR, setSelectedMR] = useState("");
  const [selectedReadingDay, setSelectedReadingDay] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);

  const [searchRRNumber, setSearchRRNumber] = useState("")
  const [loading, setLoading] = useState(false);

  ////////////////////////////////////////////////Filter States///////////////////////////////////////////
  const [remark, setRemark] = useState([])
  const [tariff, setTariff] = useState([])
  const [duration, setDuration] = useState([])
  const [arrear, setArrear] = useState([])
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [pageData, setPageData] = useState({
    currentPage: 1,
    nextPage: 1,
    previousPage: 1,
    lastPage: 1
  })
  const [installationData, setInstallationData] = useState([])
  const [mrCode, setMrCode] = useState([])
  const { auditMonthYear } = useContext(AuditMonthContext)
  const notifier = useContext(NotifierContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchRRNumber.length >= 1 ? searchRRNumber.match(/^[A-Za-z0-9]*$/) && fetchDisconnectionData() :
        !(tariff.length >= 1 || duration.length >= 1 || arrear.length >= 1 || remark.length >= 1) &&
        !(selectedMR || selectedReadingDay) &&
        searchRRNumber.length == 0 && fetchDisconnectionData()
    }, clearTime);
    return () => {
      clearTimeout(timer);
    }
  }, [searchRRNumber, pageData.currentPage, auditMonthYear])


  useEffect(() => {
    fetchMrCodes()
  }, [])

  const fetchMrCodes = async () => {
    try {
      const response = await getMRs("", 1, 100);
      let mrCodes = response.data.MRList.map(item => item.userInfo.mrCode)
      setMrCode(mrCodes)
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }

  const fetchDisconnectionData = async () => {
    setLoading(true)
    setSelectedMR("")
    setSelectedReadingDay("")
    try {
      setLoading(true)
      const response = await getDisconnections(auditMonthYear, pageData.currentPage, searchRRNumber);
      setInstallationData(response.data.installations);
      const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
      setPageData({
        ...pageData,
        currentPage: currentPage && currentPage,
        nextPage: nextPage && nextPage,
        previousPage: previousPage && previousPage,
        lastPage: lastPage && lastPage,
      })
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const searchByMrCodeAndReadingDay = async () => {
    if (!selectedMR && !selectedReadingDay) {
      notifier.notifyError("Please select Mr code or Reading day")
      return
    }
    setLoading(true)
    setSearchRRNumber("")
    setDuration([])
    setArrear([])
    setTariff([])
    setRemark([])
    try {
      setLoading(true)
      const response = await getDisconnectionsByMrCodeAndReadingDay(selectedReadingDay, auditMonthYear, pageData.currentPage, selectedMR);
      setInstallationData(response.data.installations);
      const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
      setPageData({
        ...pageData,
        currentPage: currentPage && currentPage,
        nextPage: nextPage && nextPage,
        previousPage: previousPage && previousPage,
        lastPage: lastPage && lastPage,
      })
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const applyFilters = async () => {
    setLoading(true)
    setSelectedMR("")
    setSelectedReadingDay("")
    await setPageData({ ...pageData, currentPage: 1 })
    try {
      const response = await filterDisconnections(auditMonthYear, pageData.currentPage, duration, arrear, tariff, remark);
      setInstallationData(response.data.installations);
      const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
      setPageData({
        ...pageData,
        currentPage: currentPage && currentPage,
        nextPage: nextPage && nextPage,
        previousPage: previousPage && previousPage,
        lastPage: lastPage && lastPage,
      })
      setFilterVisible(false)
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const resetFilter = async () => {
    setTariff([])
    setRemark([])
    fetchDisconnectionData()
    setDuration([])
    setArrear([])
  }

  const onClearMrCodeAndReadingDay = () => {
    setSelectedMR('')
    setSelectedReadingDay('')
    fetchDisconnectionData()
  }

  const filterData = [
    {
      heading: "Duration",
      shape: "square",
      options: [
        ...durations
      ],
      value: duration,
      setValue: (selectedValue) => { duration.includes(selectedValue) ? setDuration(duration.filter(item => item !== selectedValue)) : setDuration([...duration, selectedValue]) }
    },
    {
      heading: "Arrears",
      shape: "square",
      options: [
        ...arrears
      ],
      value: arrear,
      setValue: (selectedValue) => { arrear.includes(selectedValue) ? setArrear(arrear.filter(item => item !== selectedValue)) : setArrear([...arrear, selectedValue]) }
    },
    {
      heading: "Tariff",
      shape: "square",
      options: [
        ...installationsTariffs
      ],
      value: tariff,
      setValue: (selectedValue) => { tariff.includes(selectedValue) ? setTariff(tariff.filter(item => item !== selectedValue)) : setTariff([...tariff, selectedValue]) }
    },
    {
      heading: "Remark",
      shape: "rounded",
      options: [
        ...remarks.disconnect
      ],
      value: remark,
      setValue: (selectedValue) => { remark.includes(selectedValue) ? setRemark(remark.filter(item => item !== selectedValue)) : setRemark([...remark, selectedValue]) }
    }
  ]


  const displayStyle = {
    display: "-webkit-inline-box",
  };

  const [modalVisible, setModalVisible] = useState(false);

  const filterHandler = () => {
    setFilterVisible(!filterVisible);
  }

  const modalHandler = () => {
    setModalVisible(!modalVisible);
  }

  return (
    <React.Fragment>
      <Filter
        footerStyle={{ position: 'relative' }}
        filterData={filterData}
        isFilterVisible={filterVisible}
        onFilterClose={filterHandler}
        disableApply={(tariff.length >= 1 || duration.length >= 1 || arrear.length >= 1 || remark.length >= 1) ? false : true}
        onFiltersApply={() => applyFilters()}
        onResetApply={resetFilter} />
      {/* <Modal
        type="export"
        title="Select file format"
        isModalVisible={modalVisible}
        onModalClose={modalHandler} /> */}
      <div className="disconnection-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="comman-head">
                <div className="management-title">
                  <h1>Disconnections</h1>
                </div>
              </div>
            </div>
            {/* <DisconnectionUpload
              onStatusChange={fetchDisconnectionData}
            /> */}
            <div className="col-md-12 ">
              <BillingTabSearch
                MRs={mrCode}
                selectedMR={selectedMR}
                showClearButton={selectedMR || selectedReadingDay ? true : false}
                onClear={onClearMrCodeAndReadingDay}
                setSelectedMR={setSelectedMR}
                selectedReadingDay={selectedReadingDay}
                setSelectedReadingDay={setSelectedReadingDay}
                onSearch={() => searchByMrCodeAndReadingDay()}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="list-view-box">
                <div className="search-heading">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="search-space">
                        <input type="text"
                          placeholder="RR Number"
                          maxLength="25"
                          value={searchRRNumber}
                          onChange={(e) => { setSearchRRNumber(e.target.value); setPageData({ ...pageData, currentPage: 1 }) }}
                          className="form-control search" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 text-right">
                      <div className="export-filter">
                        <a className="export-btn">
                          <img className="fa img-fluid" src={excelIcon} />&nbsp; <span>Export to Excel</span>
                        </a>
                        <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                          <img src={filterIcon} className="" width="18" height="12" />Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead className="text-center">
                      <tr>
                        <th className="pl-15" scope="col">#</th>
                        <th scope="col">MR Code</th>
                        <th scope="col">Reading Day</th>
                        <th scope="col">Account ID</th>
                        <th scope="col">RR Number</th>
                        <th scope="col">Tariff</th>
                        <th scope="col">Consumer Name</th>
                        <th scope="col">Sanction Load</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Arrears</th>
                        <th scope="col">Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ? <NoDataFound colSpan={12} loading /> : installationData.length === 0 && <NoDataFound colSpan={12} loading={loading} />
                      }
                      {
                        !loading &&
                        installationData.map((installation, index) => {
                          let presentMonthData = installation?.auditMonth.filter((data) => auditMonthYear == data?.month?.slice(0, 7))
                          let reading = presentMonthData[0]?.reading?.value ? presentMonthData[0].reading.value : false
                          let remark = presentMonthData[0]?.reading?.remark ? presentMonthData[0].reading.remark : false
                          let from = presentMonthData[0] ? 'from' in presentMonthData[0] : false
                          let arrears = presentMonthData[0]?.arrears ? presentMonthData[0].arrears : false
                          return (

                            < tr key={index} >
                              <td className="pl-15" scope="row">{((pageData.currentPage - 1) * perPageCount) + (index + 1)}</td>
                              <td>{installation.MR?.mrCode ?? "----"}</td>
                              <td>{installation.readingDay || "----"}</td>
                              <td>{installation.accountId ?? "----"}</td>
                              <td>{installation.RRNumber ?? "----"}</td>
                              <td>{installation.tariff?.short ?? "----"}</td>
                              <Ellipsis>
                                {'consumerName' in installation ? installation.consumerName : '-'}
                              </Ellipsis>
                              <td>{installation.sanctionedLoad.kW} KW, {installation?.sanctionedLoad?.hp ?? "--"} HP</td>
                              <td> {`${from ? presentMonthData[0]?.from : '-'} Months`}</td>
                              <td>{arrears ? arrears : '-'}</td>
                              <td>
                                {remark ? remarks.disconnect.map((value) =>
                                  remark == value.value && value.label
                                ) : '-'}
                              </td>
                            </tr>
                          )
                        }
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <nav className="float-end" aria-label="Page navigation example">
            <Paginator
              currentPage={pageData.currentPage}
              lastPage={pageData.lastPage}
              onPressNext={() => { setPageData({ ...pageData, currentPage: pageData.currentPage + 1 }) }}
              onPressPrevious={() => { setPageData({ ...pageData, currentPage: pageData.currentPage - 1 }) }}
            />
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
}

export default List;