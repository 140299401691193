import { API } from 'aws-amplify'
import { perPageCount } from '../../assets/offlineData/apiCallDetails'

export const getDisconnections = (month, currentPage, RRNumber) => {
    const queryStringParameters = {
        month,
        page: currentPage,
        ...(RRNumber && { RRNumber }),
        perPage: perPageCount,
    }

    return API.get('baseURL', `/ae/disconnections`, {
        queryStringParameters
    }
    )
}

export const getDisconnectionsByMrCodeAndReadingDay = (readingDay, month, currentPage, mrCode) => {
    const queryStringParameters = {
        ...(readingDay && { readingDay }),
        month,
        page: currentPage,
        ...(mrCode && { mrCode }),
        perPage: perPageCount,
    }

    return API.get('baseURL', `/ae/disconnections`, {
        queryStringParameters
    }
    )
}

export const filterDisconnections = (month, currentPage, duration, arrears, tariff, remark) => {
    const queryStringParameters = {
        month,
        page: currentPage,
        ...(duration.length >= 1 && { from: duration }),
        ...(arrears.length >= 1 && { arrears }),
        ...(tariff.length >= 1 && { tariff }),
        ...(remark.length >= 1 && { remark }),
        perPage: perPageCount,
    }
    console.log(queryStringParameters)

    return API.get('baseURL', `/ae/disconnections`, {
        queryStringParameters
    }
    )
}


