import { API } from 'aws-amplify'
import { perPageCount } from '../../assets/offlineData/apiCallDetails'

export const getBillingCount = (month) => {
    return API.get('baseURL', `/ae/installations/counts/months/${month}`)
}

export const getInstallations = (monthYear, currentPage, untagged, RRNumber, readingDay, mrCode, tariff = [], remark = [], duration = []) => {
    const queryStringParameters = {
        month: monthYear,
        page: currentPage,
        perPage: perPageCount,
        ...(untagged && { untagged }),
        ...(RRNumber && { RRNumber }),
        ...(readingDay && { readingDay }),
        ...(mrCode && { mrCode }),
        ...(duration.length >= 1 && { 'from': duration }),
        ...(tariff.length >= 1 && { tariff }),
        ...(remark.length >= 1 && { 'billingStatus': remark }),

    }
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters
    }
    )
}

export const getInstallationsByMrCodeAndReadingDay = (readingDay, monthYear, currentPage, untagged, mrCode) => {
    const queryStringParameters = {
        ...(readingDay && { readingDay }),
        month: monthYear,
        page: currentPage,
        perPage: perPageCount,
        ...(untagged && { untagged }),
        ...(mrCode && { mrCode })
    }
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters
    }
    )
}

export const filterInstallations = (monthYear, currentPage, duration, tariff, untagged, remark) => {
    const queryStringParameters = {
        month: monthYear,
        page: currentPage,
        perPage: perPageCount,
        ...(untagged && { untagged }),
        ...(duration.length >= 1 && { 'from': duration }),
        ...(tariff.length >= 1 && { tariff }),
        ...(remark.length >= 1 && { 'billingStatus': remark }),
    }
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters
    }
    )
}

export const getOtherInstallations = (monthYear,
    currentPage,
    RRNumber,
    billingStatus,
    readingDay,
    mrCode,
    duration = [],
    tariff = [],
    remark = [],
    variant=""
) => {
    
    const queryStringParameters = {
        month: monthYear,
        page: currentPage,
        ...(RRNumber && { RRNumber }),
        ...(billingStatus && { billingStatus }),
        perPage: perPageCount,
        ...(readingDay && { readingDay }),
        ...(mrCode && { mrCode }),
        ...(duration.length >= 1 && { from: duration }),
        ...(tariff.length >= 1 && { tariff }),
        ...(remark.length >= 1 && { billingRemark: remark }),
        ...(variant && { [variant]:true }),

    }
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters
    }
    )
}

export const getOtherInstallationsByMrCodeAndReadingDay = (readingDay, monthYear, currentPage, mrCode, billingStatus) => {
    const queryStringParameters = {
        ...(readingDay && { readingDay }),
        month: monthYear,
        page: currentPage,
        ...(mrCode && { mrCode }),
        billingStatus,
        perPage: perPageCount,
    }
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters
    }
    )
}

export const filterOtherInstallations = (monthYear, currentPage, duration, tariff, remark, billingStatus) => {
    const queryStringParameters = {
        month: monthYear,
        page: currentPage,
        ...(duration.length >= 1 && { from: duration }),
        ...(tariff.length >= 1 && { tariff }),
        ...(remark.length >= 1 && { billingRemark: remark }),
        billingStatus,
        perPage: perPageCount,
    }
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters
    }
    )
}

export const getTCs = (readingDay, page, perPage, month, number, mrCode) => {
    return API.get('baseURL', `/ae/transformer-centres`, {
        queryStringParameters: {
            ...(readingDay && { 'readingDay': readingDay }),
            page,
            perPage,
            month,
            ...(number && { number }),
            ...(mrCode && { mrCode })
        }
    })
}

export const tagInstallation = (tcId, installationId, month) => {
    return API.patch('baseURL', `/ae/transformer-centres/${tcId}/installations/${installationId}/tag`, {
        queryStringParameters: {
            month
        }
    })
}

export const exportInstallations = (monthYear, untagged, RRNumber, readingDay, mrCode, tariff = [], remark = []) => {
    const queryStringParameters = {
        month: monthYear,
        ...({ untagged }),
        ...(RRNumber && { RRNumber }),
        ...(readingDay && { readingDay }),
        ...(mrCode && { mrCode }),
        // ...(duration.length >= 1 && { 'from': duration }),
        ...(tariff.length >= 1 && { tariff }),
        ...(remark.length >= 1 && { 'billingStatus': remark }),

    }
    return API.get('baseURL', `/ae/installations/export`, {
        queryStringParameters
    }
    )
}