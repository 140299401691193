import React, { useEffect, useState } from 'react';
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom';
import Header from './screens/Shared/Header';
import Sidebar from './screens/Shared/Sidebar';
import TCAudit from './screens/EnergyAudit/TCAudit/TCAudit';
import MRList from './screens/MRManagement/List';
import Dashboard from './screens/Dashboard/Index';
import AddMR from './screens/MRManagement/Add';
import FeederList from './screens/Feeders/List';
import Billing from './screens/Billing/List';
import Disconnections from './screens/Disconnections/List';
import './assets/scss/index.scss';
import './assets/scss/style.scss';
import './assets/scss/xyg.scss';
import './assets/css/font-awesome.min.css'
import AddSubstation from './screens/Feeders/AddSubstation';
import Login from './screens/Auth/Login';
import ForgotPassword from './screens/Auth/ForgotPassword';
import ResetPassword from './screens/Auth/ResetPassword';
import Profile from './screens/Profile/Profile';
import ProfileAddEdit from './screens/Profile/profile-detail-fill';
import TCAuditDetail from './screens/EnergyAudit/TCAudit/TCAuditDetail';
import TCDetail from './screens/EnergyAudit/TCDetails/TCDetail';
import { awsconfig } from './aws-exports'
import Amplify from "aws-amplify";
import Test from './screens/Test/Test';
import NotifierProvider from './context/NotifierContext';
import { ToastProvider } from 'react-toast-notifications'
import { AuthContext } from './context/AuthContext';
import EditPowerStation from './screens/Feeders/EditPowerStation';
import EditFeeder from './screens/Feeders/EditFeeder';
import AuditMonthProvider from './context/AuditMonthContext';
import AuditResultProvider from './context/AuditResultContext';

Amplify.configure(awsconfig);

const App = (props) => {

  const sidebarHandler = () => {
    setSideBarPosition(!sideBarMinimized);
  }
  const [sideBarMinimized, setSideBarPosition] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    signInSuccessHandler()
  }, [])

  const signInSuccessHandler = () => {
    const refreshToken = localStorage.getItem('refreshToken')
    setLoggedIn((refreshToken !== null) ? true : false);
  }

  let sectionClasses = ['common-scroll-content', sideBarMinimized ? 'common-toggle-content' : ''].join(' ');

  return (
    <HashRouter>
      <AuthContext.Provider value={{ signInSuccessHandler }}>
        <ToastProvider placement="bottom-right" autoDismiss={true}>
          <NotifierProvider>
            <AuditMonthProvider>
              <AuditResultProvider>
                {
                  isLoggedIn ?
                    (
                      <React.Fragment>
                        <Header sidebarHandler={sidebarHandler} sideBarMinimized={sideBarMinimized} signoutHandler={signInSuccessHandler} />
                        <div className="main-container">
                          <Sidebar sideBarMinimized={sideBarMinimized} />
                          <div className={sectionClasses}>
                            <Switch>
                              <Route exact path="/dashboard" component={Dashboard} />
                              <Route exact path="/mr-management" component={MRList} />
                              <Route exact path="/mr-management/add" render={props => props?.location?.state?.action === "add" ? <AddMR /> : <Redirect to="/mr-management" />} />
                              <Route exact path="/mr-management/:id/edit" render={props => props?.location?.state?.action === "edit" ? <AddMR /> : <Redirect to="/mr-management" />} />
                              <Route exact path="/feeders" component={FeederList} />
                              <Route exact path="/add-substation" component={AddSubstation} />
                              <Route exact path="/substations/:id/feeders/add" render={props => props?.location?.state?.action === "addFeeder" ? <AddSubstation /> : <Redirect to="/feeders" />} />
                              <Route exact path="/power-station/:id/edit" render={props => props?.location?.state?.action === "editPS" ? <EditPowerStation /> : <Redirect to="/feeders" />} />
                              <Route exact path="/feeder/:id/edit" render={props => props?.location?.state?.action === "editFeeder" ? <EditFeeder /> : <Redirect to="/feeders" />} />
                              <Route exact path="/tc-audit" component={TCAudit} />
                              <Route exact path="/tc-audit/:id" component={TCAuditDetail} render={props => props?.location?.state?.action === "viewTCDetails" ? <TCAuditDetail tcData={props?.location?.state?.tcData} /> : <Redirect to="/tc-audit" />} />
                              <Route exact path="/tc-details" component={TCDetail} />
                              <Route exact path="/test" component={Test} />
                              <Route exact path="/billing" component={Billing} />
                              <Route exact path="/profile" component={Profile} />
                              <Route exact path="/profile/add-edit" component={ProfileAddEdit} />
                              <Route exact path="/disconnections" component={Disconnections} />
                              <Route exact path="/" component={Dashboard} />
                              <Redirect from="/login" to="/dashboard" />
                            </Switch>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                    :
                    (
                      <React.Fragment>
                        <Switch>
                          <Redirect exact from="/" to="/login" />
                          <Route exact path="/login">
                            <Login signInSuccess={signInSuccessHandler} />
                          </Route>
                          <Route exact path="/forgot-password" component={ForgotPassword} />
                          <Route exact path="/reset-password" component={ResetPassword} />
                        </Switch>
                      </React.Fragment>
                    )
                }
              </AuditResultProvider>
            </AuditMonthProvider>
          </NotifierProvider>
        </ToastProvider>
      </AuthContext.Provider>
      {
        process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION" &&
        <div style={{ position: 'fixed', bottom: 0, display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', background: 'gold', width: '100%', opacity: 0.4 }}>
          {process.env.REACT_APP_ENVIRONMENT !== "STAGING" && process.env.REACT_APP_ENVIRONMENT !== "DEV" ? "PLEASE SET ENV VARIABLES" : process.env.REACT_APP_ENVIRONMENT}
        </div>
        // isLoggedIn ?
        //   (
        //     <React.Fragment>
        //       <Header sidebarHandler={sidebarHandler} sideBarMinimized={sideBarMinimized} signoutHandler={signInSuccessHandler}/>
        //       <div className="main-container">
        //         <Sidebar sideBarMinimized={sideBarMinimized} />
        //         <div className={sectionClasses}>
        //           <Switch>
        //             <Route exact path="/dashboard" component={Dashboard} />
        //             <Route exact path="/mr-management" component={MRList} />
        //             <Route exact path="/mr-management/add" component={AddMR} />
        //             <Route exact path="/mr-management/:id/edit" component={AddMR} />
        //             <Route exact path="/feeders" component={FeederList} />
        //             <Route exact path="/tc-audit" component={TCAudit} />
        //             <Route exact path="/tc-audit/:id" component={TCAuditDetail} />
        //             <Route exact path="/tc-details" component={TCDetail} />
        //             <Route exact path="/feeders/add" component={AddSubstation} />
        //             <Route exact path="/substations/:id/feeders/add" component={AddSubstation} />
        //             <Route exact path="/test" component={Test} />
        //             <Route exact path="/billing" component={Billing} />
        //             <Route exact path="/disconnections" component={Disconnections} />
        //             <Route exact path="/profile" component={Profile} />
        //             <Route exact path="/profile/add-edit" component={ProfileAddEdit} />
        //             <Redirect from="/login" to="/dashboard" />
        //             <Redirect exact from="/" to="/dashboard" />
        //           </Switch>
        //         </div>
        //       </div>
        //     </React.Fragment>
        //   )
        //   :
        //   (
        //     <React.Fragment>
        //       <Switch>
        //         <Redirect exact from="/" to="/login" />
        //         <Route exact path="/login">
        //           <Login signInSuccess={signInSuccessHandler} />
        //         </Route>
        //         <Route exact path="/forgot-password" component={ForgotPassword} />
        //         <Route exact path="/reset-password" component={ResetPassword} />
        //       </Switch>
        //     </React.Fragment>
        //   )
      }
    </HashRouter>
  );
}

export default App;
