import { API } from 'aws-amplify'

export const getTCs = (page, perPage, month, number, readingDay, mrCode) => {
    return API.get('baseURL', `/ae/transformer-centres`, {
        queryStringParameters: {
            page,
            perPage,
            month,
            ...(number && { number }),
            ...(readingDay.length >= 1 && { readingDay }),
            ...(mrCode.length >= 1 && { mrCode })
        }
    })
}

export const addTCReading = (transformerId, body) => {
    return API.post('baseURL', `/ae/transformer-centres/${transformerId}/readings`, {
        body
    })
}

export const editTCReading = (transformerId, body) => {
    return API.patch('baseURL', `/ae/transformer-centres/${transformerId}/readings`, {
        body
    })
}




export const getInstallations = (transformerId, month, page, perPage, rrNumber) => {
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters: {
            transformerId,
            month,
            page,
            perPage,
            ...(rrNumber && { RRNumber: rrNumber }),
            count: true
        }
    })
}

export const filterInstallations = (transformerId, month, page, perPage, billingStatus, tcStatus, variant) => {
    return API.get('baseURL', `/ae/installations`, {
        queryStringParameters: {
            transformerId,
            month,
            page,
            perPage,
            ...(billingStatus.length > 0 && { billingStatus }),
            ...(tcStatus.length > 0 && { untagged: (tcStatus[0] === "untagged") ? true : false }),
            ...(variant && { [variant]: true }),
        }
    })
}

export const deleteTC = (transformerId, monthYear) => {
    return API.del('baseURL', `/ae/transformer-centres/${transformerId}`, {
        queryStringParameters: {
            month: monthYear
        }
    })
}

export const addTC = (body) => {
    return API.post('baseURL', `/ae/transformer-centres`, {
        body
    })
}

export const editTC = (body, transformerId, monthYear) => {
    return API.patch('baseURL', `/ae/transformer-centres/${transformerId}`, {
        queryStringParameters: {
            month: monthYear
        },
        body,
    })
}

export const searchFeeder = (number) => {
    return API.get('baseURL', `/ae/feeders`, {
        queryStringParameters: {
            number,
            tagged: true
        }
    })
}

export const exportTCs = (month, number, readingDay, mrCode) => {
    return API.get('baseURL', `/ae/transformer-centres/export`, {
        queryStringParameters: {
            month,
            ...(number && { number }),
            ...(readingDay.length >= 1 && { readingDay }),
            ...(mrCode.length >= 1 && { mrCode })
        }
    })
}

export const getLossAnalysisMetrics = (tcId, month) => {
    return API.get('baseURL', `/ae/transformer-centres/${tcId}/loss-analysis-metrics`, {
        queryStringParameters: {
            month,
        }
    })
}
