import React, { Fragment, useState, useEffect, useContext } from "react";
import downloadIcon from "../../../../assets/img/file_download-24px.png";
import filterIcon from '../../../../assets/img/filter.png';
import plusIcon from "../../../../assets/img/pluse-icon.svg";
import minusIcon from "../../../../assets/img/close-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Filter from '../../../Shared/Filter';
import exclamation from '../../../../assets/img/info.svg';
import EditIcon from '../../../../assets/img/edit-selected-icon@2x.png';
import { NotifierContext } from "../../../../context/NotifierContext";
import { addTCReading, editTCReading, filterInstallations, filterTCs, getInstallations } from "../../../../services/api/tcService";
import Paginator from "../../../../components/molecules/Paginator";
import NoDataFound from "../../../../components/atoms/NoDataFound";
import { AuditMonthContext } from "../../../../context/AuditMonthContext";
import { remarkOptions, tcOptions } from "../../../../assets/offlineData/FilterOptions";
import dayjs from "dayjs";
import { tcReadingRemarks } from "../../../../assets/offlineData/TCOfflineData";
import { DateToYYYYMM } from "../../../../services/utils/date";

const TCDetail = ({ tcData }) => {

  const notifier = useContext(NotifierContext)
  const { auditMonthYear, auditMonth } = useContext(AuditMonthContext)

  /////////////////////TC reading states////////////////////////
  const [readingHistory, setReadingHistory] = useState(tcData.audit);
  const [reading, setReading] = useState("")
  const [remarks, setRemarks] = useState("")
  const [activeItem, setActiveItem] = useState('');
  const [readingMonthYear, setReadingMonthYear] = useState(new Date())
  const [isEditing, setEditingStatus] = useState(false)
  const filteredHistory = readingHistory.filter(item => new Date(item.month).getFullYear() === new Date(readingMonthYear).getFullYear())
  ///////////////////Installation States/////////////////////
  const [installations, setInstallations] = useState([]);
  const [installationsCount, setInstallationsCount] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true)
  const [perPage, setPerPage] = useState(10)
  const [pageData, setPageData] = useState({
    "lastPage": 0,
    "currentPage": 0,
    "nextPage": null,
    "previousPage": null,
    "limit": 20,
    "skip": 0
  })
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false)

  //////////////////////////////////////////Filter states/////////////////////////////////////////////////
  const [filterRemarks, setFilterRemarks] = useState([])
  const [TCs, setTCs] = useState([])
  const [filterVisible, setFilterVisible] = useState(false);

  /////////////////////////////////////////Data to be displayed in Filter modal//////////////////////////
  const filterData = [
    {
      heading: "Remarks",
      shape: "square",
      options: remarkOptions,
      value: filterRemarks,
      setValue: (selectedValue) => {
        filterRemarks.includes(selectedValue) ?
          setFilterRemarks(filterRemarks.filter(item => item !== selectedValue))
          : setFilterRemarks([...filterRemarks, selectedValue])
      }

    },
    {
      heading: "TCs",
      shape: "square",
      options: tcOptions,
      value: TCs,
      setValue: (selectedValue) => {
        TCs.includes(selectedValue) ?
          setTCs(TCs.filter(item => item !== selectedValue))
          : setTCs([selectedValue])
      }

    },
  ]

  ////////////////////////Function to execute upon initial load and change in pagination and audit Month-Year///////////// 
  useEffect(() => {
    (filterRemarks.length > 0 || TCs.length > 0) ? applyFilters() : fetchInstallations()
  }, [pageNumber, auditMonthYear]);


  ////////////////////////Function to execute upon initial load and change in pagination and audit Month-Year///////////// 
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageNumber(1)
      fetchInstallations()
      setReadingHistory(tcData.audit)
    }, 500);
    return () => {
      clearTimeout(timer);
    }
  }, [tcData, searchString])

  ///////////////////////////////////Function to fetch Installations//////////////////////////
  const fetchInstallations = async () => {
    try {
      setLoading(true)
      const response = await getInstallations(tcData.id, auditMonthYear, pageNumber, perPage, searchString)
      setInstallations(response.data.installations)
      setPageData(response.data.pageData)
      setInstallationsCount(response.data.count)
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err);
      setLoading(false)
    }
  }

  /////////////////////////////////Open and close filter/////////////////////////
  const filterHandler = (e) => {
    e.preventDefault();
    setFilterVisible(!filterVisible);
  }


  /////////////////////////////////Open and close Reading History Accordian//////////////////////////
  const accordianHandler = (event) => {
    event.preventDefault();
    if (event.target.type == 'undefined') return;
    var key = event.currentTarget.getAttribute('data-panel');
    if (activeItem == key) {
      setActiveItem('');
    } else {
      setActiveItem(key);
    }
  }

  /////////////////////////////////Function to apply filter//////////////////////////////////////
  const applyFilters = async () => {
    try {
      setLoading(true)
      const response = await filterInstallations(tcData.id, auditMonthYear, pageNumber, perPage, filterRemarks, TCs)
      setInstallations(response.data.installations)
      setPageData(response.data.pageData)
      setInstallationsCount(response.data.count)
      setFilterVisible(false)
      setLoading(false)
    }
    catch (err) {
      setFilterVisible(false)
      notifier.notifyError(err)
      setLoading(false)
    }
  }
  /////////////////////////////////////Handle Add TC Reading//////////////////////////////////

  const editReading = async () => {
    try {
      setSubmitBtnLoader(true)
      const requestBody = {
        month: DateToYYYYMM(readingMonthYear),
        ...(remarks ? { failureReason: remarks } : { finalValue: Number(reading) })
      }
      const response = await editTCReading(tcData.id, requestBody)
      setReadingHistory(response.data.audit)
      setSubmitBtnLoader(false)
      notifier.notifySuccess(response.message)
    }
    catch (err) {
      notifier.notifyError(err)
      setSubmitBtnLoader(false)
    }
  }

  const tcReadingHandler = async () => {
    try {
      setSubmitBtnLoader(true)
      const requestBody = {
        month: DateToYYYYMM(readingMonthYear),
        ...(remarks ? { failureReason: remarks } : { finalValue: Number(reading) })
      }
      const response = isEditing ? await editTCReading(tcData.id, requestBody) : await addTCReading(tcData.id, requestBody)
      setReadingHistory(response.data.audit)
      setSubmitBtnLoader(false)
      notifier.notifySuccess(response.message)
    }
    catch (err) {
      if (err?.response?.data?.errorInfo?.name === 'DuplicateTCReading') {
        setSubmitBtnLoader(false)
        const confirmation = window.confirm("Looks like Reading / Remark for this month already exists. Are you sure you want to change it?")
        if (confirmation) {
          editReading()
        }
        return;
      }
      notifier.notifyError(err)
      setSubmitBtnLoader(false)
    }

  }

  const handleEditTCReading = (finalValue, failureReason, readingMonth) => {
    setEditingStatus(true);
    if (finalValue) {
      setRemarks("")
      setReading(finalValue);
      setReadingMonthYear(new Date(readingMonth))
      return;
    }
    setReading("")
    setRemarks(failureReason);
    setReadingMonthYear(new Date(readingMonth))
  }

  const handleFilterReset = () => {
    setFilterRemarks([]);
    setTCs([]);
    fetchInstallations();
    setFilterVisible(false)
  }


  const getConsumption = () => {
    const consumption = tcData.audit.filter(item => (
      new Date(item.month).getMonth() === auditMonth.getMonth() + 1
      && new Date(item.month).getFullYear() === auditMonth.getFullYear()
    ))
    return consumption[0]?.reading?.consumption ?? '----'
  }

  const getLossPercentage = () => {
    const lossPercentage = tcData.audit.filter(item => (
      new Date(item.month).getMonth() === auditMonth.getMonth()
      && new Date(item.month).getFullYear() === auditMonth.getFullYear()
    ))
    return lossPercentage[0]?.lossPercentage ?? '----'


  }
  const getLossDetails = () => {
    const lossDetails = tcData.audit.filter(item => (
      new Date(item.month).getMonth() === auditMonth.getMonth()
      && new Date(item.month).getFullYear() === auditMonth.getFullYear()
    ))
    return lossDetails[0]


  }

  const getDecemberFinalReading = (date) => {

    const year = new Date(date).getFullYear() + 1;

    const result = readingHistory.filter(item => (
      dayjs(item.month).format("MMMM") === "January"
      && dayjs(item.month).format("YYYY") === `${year}`
    ))
    return result[0]?.reading?.finalValue

  }

  const getDecemberConsumption = (date) => {
    const year = new Date(date).getFullYear() + 1;

    const result = readingHistory.filter(item => (
      dayjs(item.month).format("MMMM") === "January"
      && dayjs(item.month).format("YYYY") === `${year}`
    ))
    return result[0]?.reading?.consumption
  }

  const getInstallationConsumption = (auditArray) => {
    const result = auditArray.filter(item => DateToYYYYMM(item.month) === auditMonthYear)
    return result[0]?.billing?.consumption ?? "N/A"
  }
  return (
    <Fragment>
      <Filter
        filterData={filterData}
        isFilterVisible={filterVisible}
        onFilterClose={filterHandler}
        onFiltersApply={applyFilters}
        onResetApply={handleFilterReset}
      />
      {/* /////////////////////////////////////////////CONSUMPTION AND PERCENTAGE LOSS/////////////////////////////// */}
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="comman-head">
            <div className="tc-name-title">
              <h1 className="mb-0">TC No. {tcData.number}</h1>
            </div>
          </div>
        </div>
        <div className="col-md-12 consumption-point">
          <hr className="horizontal-line" />
          <div className="consumption-load-section mb-2">
            <span className="mt-1 font-light-300">
              Consumption: <b>{getConsumption()}</b>{" "}
              {/* <img className="img-fluid mb-2 ms-3" src={exclamation} /> */}
            </span>
            <hr className="vertical-line mx-4 mt-2" />
            <span className="mt-1 font-light-300">
              Percentage Loss: <b>{getLossPercentage()}</b>{" "}
              <img className="img-fluid mb-2 ms-3" src={exclamation}
                title={`LOSS DETAILS\nMNR : ${getLossDetails()?.billingCount?.mnr ?? "---"}\nDoorLock : ${getLossDetails()?.billingCount?.doorLock ?? "---"}\nZero Consumption :  ${getLossDetails()?.billingCount?.zeroConsumption ?? "---"}\nVacant : ${getLossDetails()?.billingCount?.vacant ?? "---"}\nUnbilled : ${getLossDetails()?.billingCount?.unbilled ?? "---"}`} />
            </span>
          </div>
        </div>

        {/* ////////////////////////////////Add and Edit TC Reading////////////////////////////// */}
        <div className="col-md-12">
          <div className="main-tc-name-form mb-3">
            <div className="main-final-reading-div custom-final-reading-divs">
              <div className="final-reading-title main-reading-custom-title">
                <p className="mb-0 font-medium-500">
                  Final Reading
                </p>
                <div style={{ marginRight: 20 }}>
                  <DatePicker
                    dateFormat="MMMM yyyy"
                    showTwoColumnMonthYearPicker
                    showMonthYearPicker
                    placeholderText="Select Month"
                    selected={readingMonthYear}
                    onChange={(date) => { setReadingMonthYear(date); setEditingStatus(false) }}
                  />
                </div>
              </div>
              <div className="final-reading-form">
                <div className="forms-input">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-label font-regular-400">Enter Reading</label>
                      <input
                        className="form-control"
                        value={reading}
                        disabled={remarks ? true : false}
                        onChange={e => setReading(e.target.value)}
                        type="text"
                        placeholder="Enter Reading"
                      />
                    </div>
                    <div className="col-md-4 select-remark">
                      <label className="form-label font-regular-400">Select Remarks</label>
                      <select
                        className="form-select select-field"
                        aria-label="Select Remarks"
                        disabled={reading ? true : false}
                        value={remarks}
                        onChange={e => setRemarks(e.target.value)}
                      >
                        <option value="">Select Remarks</option>
                        {tcReadingRemarks.map(item => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4 mr-save-btn">
                      <button
                        type="submit"
                        disabled={submitBtnLoader ? true : (remarks || reading) ? false : true}
                        className="btn btn-primary font-regular-400"
                        onClick={tcReadingHandler}>
                        Submit{'\t'}
                        {submitBtnLoader && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //////////////////////////////////////////////////Reading History///////////////////////////////////////// */}
            <div className="history-section mt-3 mb-3 p-0">
              <div className="history-collapse position-relative  pt-3 pb-3 pe-4 ps-4">
                <a
                  onClick={accordianHandler}
                  className="collapse-text"
                  data-bs-toggle="collapse"
                  href="#collapseAudithistory"
                  role="button"
                  data-panel="reading-history-panel"
                  aria-expanded="false"
                  aria-controls="collapseAudithistory">
                  <div className="tc-audit-history-collpase-title w-100">
                    <p className="mb-0 font-medium-500">Reading History</p>
                  </div>
                  <div className="select-icon">
                    <img className={activeItem == 'reading-history-panel' ? 'img-fluid ms-2' : 'img-fluid ms-2 display-n'} src={minusIcon} />
                    <img className={activeItem == 'reading-history-panel' ? 'img-fluid ms-2 display-n' : 'img-fluid ms-2'} src={plusIcon} />
                  </div>
                </a>
                <DatePicker
                  selected={readingMonthYear}
                  onChange={(date) => setReadingMonthYear(date)}
                  showYearPicker
                  showThreeColumnYearPicker
                  dateFormat="yyyy"
                  placeholderText="2021"
                />
              </div>
              <div className={`collapse collapse-detail ${activeItem == 'reading-history-panel' ? 'show' : ''}`} id="collapseAudithistory">
                <div className="audit-history-list">
                  <table className="table  mb-0 table-responsive">
                    <thead>
                      <tr>
                        <th className="ps-4 font-medium-500" scope="col">
                          Month
                        </th>
                        <th scope="col">IR</th>
                        <th scope="col">FR</th>
                        <th scope="col">Consumption</th>
                        <th scope="col">Loss Percentage</th>
                        <th scope="col" className="text-right pr-20">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        filteredHistory.length === 0 ? <NoDataFound message="No Readings found for this year!" /> :
                          filteredHistory.sort((a, b) => a?.month?.slice(5, 7) - b?.month?.slice(5, 7)).map((reading, index) => {

                            if (!(reading?.reading?.consumption || (reading?.reading?.finalValue || reading?.reading?.failureReason))) return;

                            const finalReadingForDecember = getDecemberFinalReading(reading.month)
                            const consumptionForDecember = getDecemberConsumption(reading.month)

                            return (
                              <tr key={index}>
                                {/* Month and Year */}
                                <td className="ps-4 font-regular-400" scope="row">
                                  {dayjs(reading.month).format("MMMM - YYYY")}
                                </td>

                                {/* IR*/}
                                <td>
                                  {reading?.reading?.finalValue ?? reading?.reading?.failureReason ?? '----'}
                                </td>

                                {/* FR*/}
                                <td>
                                  {filteredHistory[index + 1]?.reading?.finalValue
                                    ?? filteredHistory[index + 1]?.reading?.failureReason
                                    ?? (dayjs(reading.month).format("MMMM") === "December" ? finalReadingForDecember ?? '----' : '----')}
                                </td>

                                {/* Consumption*/}
                                <td>
                                  {filteredHistory[index]?.reading?.consumption
                                    // ?? (dayjs(reading.month).format("MMMM") === "December" ? consumptionForDecember ?? '----' : "----")
                                  }
                                </td>

                                {/* LossPercentage*/}
                                <td>
                                  {reading?.lossPercentage ?? '----'}
                                </td>

                                {/* Edit Icon */}
                                <td className="text-right pr-20">
                                  <a
                                    onClick={() => handleEditTCReading(reading?.reading?.finalValue, reading?.reading?.failureReason, reading.month)}
                                    style={{ cursor: 'pointer' }}>
                                    <img className="img-fluid icon-width-height" src={EditIcon} />
                                  </a>
                                </td>
                              </tr>
                            )
                          }).reverse()
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



            {/* //////////////////////////////////////////////Installations/////////////////////////////////////////////// */}
            <div className="installation-list list-view-box">
              <div className="digit-installation-title mb-3">
                <h3 className="mb-0 ms-3 font-medium-500">
                  {installationsCount} Installations</h3>
              </div>
              <div className="search-heading mb-4 pl-0">
                <div className="search-space position-relative me-auto ms-3">
                  <input
                    type="text"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    placeholder="RR Number"
                    className="form-control search-field"
                  />
                </div>
                <div className="download-upload">
                  <button type="button" className="btn btn-primary download-btn">
                    <img src={downloadIcon} className="me-2" />
                    <p className="mb-0 font-regular-400">Download</p>
                  </button>
                  <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                    <img src={filterIcon} className="" width="18" height="12" />Filter
                  </button>
                </div>
              </div>
              <div className="installing-list">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="ps-4" scope="col">
                          #
                        </th>
                        <th scope="col">MR Code</th>
                        <th scope="col">Reading Day</th>
                        <th scope="col">Account ID</th>
                        <th scope="col">RR Number</th>
                        <th scope="col">Tariff</th>
                        <th scope="col">Consumer Name</th>
                        <th scope="col">Sanctioned Load</th>
                        <th scope="col">Consumption</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ?
                          <NoDataFound colSpan={12} message="Loading..." />
                          :
                          installations.length === 0 ?
                            <NoDataFound colSpan={12} message="No Installations found!" />
                            :
                            installations.map((installation, index) => {
                              return (
                                <tr key={index}>
                                  <td className="ps-4" scope="row">{((pageData.currentPage - 1) * perPage) + (index + 1)}</td>
                                  <td>{installation?.MR?.mrCode ?? "----"}</td>
                                  <td>{installation.readingDay || "----"}</td>
                                  <td>{installation.accountId ?? "----"}</td>
                                  <td>{installation.RRNumber ?? "----"}</td>
                                  <td>{installation.tariff?.short ?? "----"}</td>
                                  <td>{installation?.consumerName ?? "N/A"}</td>
                                  <td>{installation?.sanctionedLoad?.kW ?? "N/A"} KW, {installation?.sanctionedLoad?.hp ?? "--"} HP</td>
                                  <td>{installation.audit ? getInstallationConsumption(installation.audit) : "N/A"}</td>
                                </tr>
                              )
                            })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="float-end" aria-label="Page navigation example">
        <Paginator
          currentPage={pageData.currentPage}
          lastPage={pageData.lastPage}
          onPressNext={() => { setPageNumber(prevState => prevState >= pageData.lastPage ? prevState : prevState + 1) }}
          onPressPrevious={() => { setPageNumber(prevState => prevState <= 1 ? prevState : prevState - 1) }}
        />
      </nav>
    </Fragment>
  );
};

export default TCDetail;
