import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '../../assets/img/edit-selected-icon-blue.png';
import { fetchAEProfileDetails } from '../../services/api/profileService'
import { NotifierContext } from '../../context/NotifierContext';
import FullScreenLoader from '../../components/atoms/FullScreenLoader'

export default function List() {
  const notifier = useContext(NotifierContext);

  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    sectionName: '',
    sectionCode: '',
    designation: 'AE',
    contactNumber: '',
    emailId: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    pinCode: ''
  })

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAEProfile()
  }, [])

  const fetchAEProfile = async () => {
    try {
      const response = await fetchAEProfileDetails()
      const { name, gender, location, phoneNumber, email, address } = response?.data
      setProfile({
        ...profile,
        firstName: name?.firstName,
        lastName: name?.lastName,
        gender,
        sectionName: location?.name?.sectionName,
        sectionCode: location?.sectionCode,
        contactNumber: phoneNumber,
        emailId: email,
        addressLine1: address?.line1,
        addressLine2: address?.line2,
        city: address?.city,
        state: address?.state,
        pinCode: address?.pinCode
      })
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const { firstName, lastName, gender, sectionName, sectionCode, designation, contactNumber, emailId, addressLine1, addressLine2, city, state,
    pinCode } = profile

  return (
    <React.Fragment>
      <div className="profile-content">
        <div className="container">
          <div className="row align-items-center mb-3">
            <div className="col-12 col-md-6">
              <h1 className="mb-0">My Profile</h1>
            </div>
            <div className="col-12 col-md-6 text-right">
              <Link
                // to="/profile/add-edit" params={{ profile }}
                to={{
                  pathname: `/profile/add-edit`,
                  state: { profile }
                }}
                type="submit" className="btn btn-primary ghost-btn">
                <img className="img-fluid icon-width-height" src={EditIcon} alt="Edit Icon" />Edit
              </Link>
            </div>
          </div>
          {
            loading ?
              <FullScreenLoader />
              :
              <div className="row">
                <div className="col-md-12">
                  <div className="list-view-box mt-2">
                    <h3 className="mb-4">Personal Details</h3>
                    <div className="profile-form">
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">First Name</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{firstName ? firstName : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Last Name</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{lastName ? lastName : ''}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Gender</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{gender}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Section Name</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{sectionName}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Section Code</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{sectionCode}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Designation</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{designation}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Contact Number</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{contactNumber}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Email ID</label>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                          <p className="label-value">{emailId}</p>
                        </div>
                      </div>
                      <hr className="divider" />
                      <h3 className="mb-4 mt-4">Address</h3>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Address</label>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <p className="label-value">{addressLine1} {addressLine2} {city} {state} {pinCode}</p>

                          {/* <p className="label-value">#34/13/1, 7<sup>th</sup> Cross, Shakthiganapathinagara Main Road, Basaveshwaranagara, Bangalore 560079, Karnataka.</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
}
