import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import closeIcon from '../../assets/img/close-icon.png';
import closeIcon1 from '../../assets/img/close-icon@2x.png';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import Select from 'react-select/async';
import { NotifierContext } from '../../context/NotifierContext';
import { addFeeder, addPowerStation, searchFeeders, searchPowerStations, tagAEToFeeder, tagAEToPS } from '../../services/api/feederService';
import { addFeederValidation, addPowerStationValidation } from '../../services/validators/feederManagement';

const AddSubstation = (props) => {

  const selectInputRef = useRef();
  const notifier = useContext(NotifierContext)
  const history = useHistory()
  const location = useLocation()

  const selectedStationCode = location?.state?.stationCode
  const selectedStationName = location?.state?.stationName
  const selectedVoltageClass = location?.state?.voltageClass
  const selectedAction = location?.state?.action

  const [isDisabled, setIsDisabled] = useState(false)

  const [feederFields, setFeederFields] = useState({ name: "", mdm_code: "", number: "" });
  const [stationFields, setStationFields] = useState({ stationCode: selectedStationCode ?? null, voltageClass: "", stationName: "" })
  const [isFormValid, setFormValid] = useState(false);
  const [feeders, setFeeders] = useState([]);

  useEffect(() => {
    /** Debouncing */
    const timer = setTimeout(() => {
      let checkForm = true;
      for (const [key, value] of Object.entries(feederFields)) {
        if (validateField(key) !== '') {
          checkForm = false;
        }
      }
      setFormValid(checkForm);
    }, 200);
    return () => {
      clearTimeout(timer);
    }
  }, [feederFields])

  //Fetch list of stations on search of stations
  const fetchStations = async (input) => {
    try {
      if (input.length > 1) {
        const response = await searchPowerStations(input)
        const searchResult = response.data.map(i => {
          return ({
            label: i.stationCode,
            value: i.stationCode,
            voltageClass: i?.voltageClass || "",
            stationName: i.name,
            createdAt: i.createdAt
          })
        })
        if (searchResult.length === 0) return ([{ label: `New Station :  ${input}`, value: input }])
        return searchResult.some(item => item.label === input) ? searchResult : [{ label: `New Station :  ${input}`, value: input }, ...searchResult]
      }
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }

  //Fetch list of feeder on search of feeders
  const fetchFeeders = async (input) => {
    try {
      if (input.length > 1) {
        if (!stationFields.stationCode) throw new Error("Please Fill Station Code")
        const response = await searchFeeders(input, stationFields.stationCode)
        const searchResult = response.data.feederList.map(i => {
          return ({
            label: i.mdmCode,
            value: i.mdmCode,
            feederNumber: i?.number || "",
            feederName: i?.name || "",
            powerStation: i.powerStation
          })
        })
        if (searchResult.length === 0) return ([{ label: `New Feeder :  ${input}`, value: input }])
        return searchResult.some(item => item.label === input) ? searchResult : [{ label: `New Feeder :  ${input}`, value: input }, ...searchResult]
      }
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }

  //Function for handling searchable dropdown value change of station search
  const handleStationChange = (option) => {
    setStationFields({
      stationCode: option?.value ?? null,
      voltageClass: option?.voltageClass || "",
      stationName: option?.stationName || "",
      ...(option?.createdAt && { createdAt: option.createdAt })
    })
  }

  //Function for handling searchable dropdown value change of feeder search
  const handleFeederChange = (option) => {
    setFeederFields({
      name: option?.feederName || "",
      mdm_code: option?.value ?? null,
      number: option?.feederNumber || "",
      ...(option?.powerStation?.stationCode && { stationCode: option?.powerStation?.stationCode })
    })
    // setFeederFields({
    //   ...feederFields,
    //   ...(option?.feederName && { name: option?.feederName || "" }),
    //   ...(option?.value && { mdm_code: option?.value ?? null }),
    //   ...(option?.feederNumber && { number: option?.feederNumber || "" }),
    //   ...(option?.powerStation?.stationCode && { stationCode: option?.powerStation?.stationCode })
    // })
  }

  //handle change in input fields of feeders
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const feederFieldsObject = {
      ...feederFields,
      [name]: value
    }
    setFeederFields(feederFieldsObject);
  }

  const validateField = (name) => {
    let error = '';
    /** Generic test */
    error = (feederFields[name] === null || feederFields[name].length == 0) ? 'Please enter valid value' : '';
    return error;
  }

  //Function to delete feeder
  const deleteFeeder = (index) => {
    const feederList = [...feeders]
    feederList.splice(index, 1)
    setFeeders(feederList)
  }

  //Function to save feeder info locally.
  const saveHandler = async (event) => {
    try {
      event.preventDefault();
      if (!isFormValid) return;

      //If the feeder is info is fetched from api call
      if (feederFields?.stationCode) {
        setFeeders([...feeders, feederFields])
        selectInputRef.current.select.select.clearValue();
        setFeederFields({ name: "", mdm_code: "", number: "" })
        return;
      }

      const validatedData = addFeederValidation(feederFields)
      setFeeders([...feeders, validatedData])
      selectInputRef.current.select.select.clearValue();
      setFeederFields({ name: "", mdm_code: "", number: "" })
      return;
    }
    catch (err) {
      notifier.notifyError(err)
    }


  }


  //Function to add both power station and feeders
  const submitHandler = async () => {
    try {
      setIsDisabled(true)
      const validatedData = addPowerStationValidation(stationFields)
      const stationResponse = stationFields?.createdAt ? await tagAEToPS(stationFields.stationCode) : await addPowerStation(validatedData)
      notifier.notifySuccess(`${stationResponse.message} ${stationResponse.data.stationCode}`)
      const feederResponse = await Promise.all(feeders.map(feeder => feeder?.stationCode ? tagAEToFeeder(feeder.stationCode, feeder.mdm_code) : addFeeder(feeder, stationResponse.data.stationCode)))
      feederResponse.map(item => notifier.notifySuccess(`${item.message}`))
      history.goBack()
    }
    catch (err) {
      notifier.notifyError(err)
      setIsDisabled(false)
    }

  }

  //Function to add only feeders
  const handleAddFeeder = async () => {
    try {
      setIsDisabled(true)
      const response = await Promise.all(feeders.map(feeder => feeder?.stationCode ? tagAEToFeeder(feeder.stationCode, feeder.mdm_code) : addFeeder(feeder, stationFields.stationCode)))
      response.map(item => notifier.notifySuccess(`${item.message}`))
      history.goBack()

    }
    catch (err) {
      notifier.notifyError(err)
      setIsDisabled(false)
    }
  }


  return (
    <React.Fragment>
      <div className="add-substation">
        <div className="container pt-3">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="code-section">
                <span>Feeder Management&nbsp; |</span>
                <span className="add-new-substation-text"><a>&nbsp; Add{selectedAction === "addFeeder" ? selectedStationName : " New Station"}</a></span>
              </div>
              <hr className="horizontal-line" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="substation-title">
                <h1 className="mb-0">Add {selectedAction ? "Feeders" : "New Station"}</h1>
              </div>
            </div>
            <div className="col-md-6">
              <div className="close-mr">
                <Link onClick={history.goBack} className="btn btn-primary bg-transparent"><img src={closeIcon} />Close
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="main-substation-form p-3 mb-3">

                {/* ///////////////////////////////////////////////////////////Add Station Section//////////////////////////////////////////////////////// */}

                <form>
                  <div className="forms-input pb-10">
                    <div className="form-substation-shadow">
                      <div className="row">
                        <div className="col-md-3 search-feeder">
                          <label className="form-label font-regular-400">Station Code*</label>
                          <Select
                            name="form-field-name"
                            isDisabled={selectedAction === "addFeeder" ? true : false}
                            loadOptions={fetchStations}
                            onChange={handleStationChange}
                            placeholder="Search or Enter Station Code"
                            {...(selectedAction === "addFeeder" && { value: { label: selectedStationCode, value: selectedStationCode } })} //conditionally add props to a component
                            // value={selectedAction === "addFeeder" ? { label: selectedStationCode, value: selectedStationCode } : stationFields.stationCode}
                            isSearchable
                            isClearable
                          />
                          {/* <input className="form-control" type="text" placeholder="Station Code (Optional)" /> */}
                          <img className="img-fluid fa" src={searchIcon} />
                        </div>
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">Voltage Class</label>
                          <select
                            className="form-select"
                            disabled={(selectedAction === "addFeeder" || stationFields.createdAt) ? true : false}
                            aria-label="Default select example"
                            value={selectedVoltageClass ?? stationFields.voltageClass}
                            onChange={(option) => setStationFields({ ...stationFields, voltageClass: option.target.value })}
                          >
                            <option>Voltage Class</option>
                            <option value="33">33KV</option>
                            <option value="66">66KV</option>
                            <option value="100">100KV</option>
                            <option value="220">220KV</option>
                            <option value="400">400KV</option>
                          </select>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">Station Name *</label>
                          <input
                            className="form-control"
                            type="text"
                            disabled={(selectedAction === "addFeeder" || stationFields.createdAt) ? true : false}
                            placeholder="Station Name"
                            value={selectedStationName ?? stationFields.stationName}
                            onChange={(input) => setStationFields({ ...stationFields, stationName: input.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {/* <hr className="divider" /> */}
                <div className="main-feeder-div">
                  <div className="feeder-title">
                    <h3 className="mb-0">Add Feeder</h3>
                  </div>

                  {/*////////////////////////////////////////////////////////Add Feeder Section//////////////////////////////////////////////////////////*/}


                  <form className="feeder-form">
                    <div className="forms-input">
                      <div className="row">
                        <div className="col-md-3 search-feeder">
                          <label className="form-label">Feeder MDM Code</label>
                          <Select
                            ref={selectInputRef}
                            name="mdm_code"
                            loadOptions={fetchFeeders}
                            onChange={handleFeederChange}
                            placeholder="Search or Add by MDM Code"
                            isSearchable
                            isClearable
                          // onChange={(option) => {
                          //   setFormFields({ ...formFields, mdm_code: option?.value ?? '' })
                          // }}

                          />
                          {/* <input onChange={handleChange} name="mdm_code" value={formFields['mdm_code']} className="form-control" type="text" placeholder="Search or Add by MDM Code" /> */}
                          <img className="img-fluid fa" src={searchIcon} />
                        </div>
                        <div className="col-md-3">
                          <label className="form-label">Feeder Number</label>
                          <input
                            onChange={handleChange}
                            name="number"
                            disabled={feederFields.stationCode ? true : false}
                            value={feederFields['number']}
                            className="form-control"
                            type="text"
                            placeholder="Feeder Number" />
                        </div>
                        <div className="col-md-3">
                          <label className="form-label">Feeder Name</label>
                          <input
                            onChange={handleChange}
                            name="name"
                            value={feederFields['name']}
                            disabled={feederFields.stationCode ? true : false}
                            className="form-control"
                            type="text"
                            placeholder="Feeder Name" />
                        </div>
                        <div className="col-md-3 mr-save-btn">
                          <button
                            onClick={saveHandler}
                            type="submit"
                            className={`btn btn-primary ${isFormValid ? 'custom-active' : ''}`}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {
                  feeders.map((feeder, index) => {
                    return (
                      <div className="feeder-section mt-3" key={index}>
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-10 col-10">
                            <div className="feeder-name">
                              <p className="mb-0">Feeder MDM Code : <b>{feeder?.mdm_code ?? feeder.mdmCode}</b></p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-10 col-10">
                            <div className="feeder-number">
                              <p className="mb-0">Feeder Number : <b>{feeder.number}</b></p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-3 col-sm-10 col-10">
                            <div className="feeder-name">
                              <p className="mb-0">Feeder Name : <b>{feeder.name}</b></p>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-end" >
                            <div className="delete-btn" >
                              <img className="img-fluid icon-width-height-24" style={{ cursor: 'pointer' }} src={closeIcon1} onClick={() => deleteFeeder(index)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
                <div className="row">
                  <div className="col-md-12 mr-submit-btn">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ background: isDisabled && '#c0c1c2', border: isDisabled && '#c0c1c2' }}
                      disabled={isDisabled || (selectedAction === "addFeeder" && feeders.length === 0)}
                      onClick={selectedAction === "addFeeder" ? handleAddFeeder : submitHandler}>
                      Submit {'\t'}
                      {isDisabled && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddSubstation;
