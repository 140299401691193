import React, { useContext, useState, useEffect } from 'react';
import downloadImage from '../../../../assets/img/file_download-24px.png';
import filterIcon from '../../../../assets/img/filter.png';
import ViewImage from '../../../../assets/img/view-selected-icon@2x.png';
import Filter from '../../../Shared/Filter';
import NoDataFound from '../../../../components/atoms/NoDataFound';
import { auditIndividualTC, getAuditMetrics, getAuditResults } from '../../../../services/api/tcAuditService';
import { AuditMonthContext } from '../../../../context/AuditMonthContext';
import { NotifierContext } from '../../../../context/NotifierContext';
import FullScreenLoader from '../../../../components/atoms/FullScreenLoader';
import Paginator from '../../../../components/molecules/Paginator';
import Ellipsis from '../../../../components/molecules/Ellipsis';
import { executionType as executionTypeEnums, tcReadingRemarks } from '../../../../assets/offlineData/TCOfflineData';
import { getMRs } from '../../../../services/api/mrService';
import { Link, useHistory } from 'react-router-dom';
import { DateToYYYYMM, fetchNextMonth } from '../../../../services/utils/date';
import { AuditResultContext } from '../../../../context/AuditResultContext';
import Spinner from '../../../../components/atoms/Spinner';

const AuditResult = () => {

    const history = useHistory();
    const notifier = useContext(NotifierContext)
    const { auditMonthYear, auditMonth } = useContext(AuditMonthContext)

    const enumMappings = {
        METER_NOT_RECORDING: "Meter Not Recording",
        NO_DISPLAY: "No Display",
        OTHERS: "Others",
        CT_BURNT_OUT: "CT Burnt Out"
    }

    const { activeDataTab, setDataTab,
        activeTransformerAuditedButton, setActiveTransformerAuditedButton,
        activeTransformerUnauditedButton, setActiveTransformerUnauditedButton

    } = useContext(AuditResultContext);
    const [filterVisible, setFilterVisible] = useState(false);
    const [transformers, setTransformers] = useState([]);
    const [TCCount, setTCCount] = useState("");
    const [loading, setLoading] = useState(true);
    const [auditBtnLoader, setAuditBtnLoader] = useState(false);
    const [auditBtnLoaderIndex, setAuditBtnLoaderIndex] = useState(-1);
    const [tableLoading, setTableLoading] = useState(true);
    const [auditMetrics, setAuditMetrics] = useState({
        negativeLoss: "---",
        zeroToFive: "---",
        fiveToTen: "---",
        tenToFifteen: "---",
        fifteenToTwenty: "---",
        twentyAndAbove: "---",
        untagged: "---",
        withoutConsumption: "---",
        withRemark: "---",
        audited: "---",
        totalTransformers: "---",
        unaudited: "---"
    })
    const [pageNumber, setPageNumber] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [searchString, setSearchString] = useState("")
    const [pageData, setPageData] = useState({
        "lastPage": 0,
        "currentPage": 0,
        "nextPage": null,
        "previousPage": null,
        "limit": 20,
        "skip": 0
    })
    const [executionTypeFilters, setExecutionTypeFilters] = useState([])
    const [MRs, setMRs] = useState([])
    const [selectedMRs, setSelectedMRs] = useState([])
    const [remarksFilter, setRemarksFilter] = useState([])


    useEffect(() => {
        fetchAuditMetrics()
        setPageNumber(1)
        fetchAuditResults()
        fetchMRs()
    }, [auditMonthYear])

    useEffect(() => {
        /** Debouncing */
        const timer = setTimeout(() => {
            fetchAuditResults()
        }, 500);
        return () => {
            clearTimeout(timer);
        }
    }, [activeDataTab, searchString, pageNumber, activeTransformerAuditedButton, activeTransformerUnauditedButton])

    const fetchAuditMetrics = async () => {
        try {
            setLoading(true)
            const response = await getAuditMetrics(auditMonthYear)
            setAuditMetrics(response.data)
            setLoading(false)
        }
        catch (err) {
            notifier.notifyError(err)
        }
    }

    const fetchAuditResults = async () => {
        try {
            setTableLoading(true)
            const response = await getAuditResults(auditMonthYear, pageNumber, perPage, searchString, activeDataTab, activeTransformerAuditedButton, activeTransformerUnauditedButton, executionTypeFilters, selectedMRs, remarksFilter)
            setTransformers(response.data.transformers)
            setPageData(response.data.pageData)
            setTCCount(response.data.tcCount)
            // console.log(response.data.tcCount)
            setTableLoading(false)
        }
        catch (err) {
            notifier.notifyError(err)
        }
    }

    const fetchMRs = async () => {
        try {
            const response = await getMRs("", 1, 100)
            setMRs(response.data.MRList.map(item => { return ({ label: item.userInfo.mrCode, value: item.userInfo.mrCode }) }))
        }
        catch (err) {
            notifier.notifyError(err)
        }
    }

    const handleIndividualAudit = async (transformerId, index) => {
        try {
            setAuditBtnLoader(true)
            setAuditBtnLoaderIndex(index)
            const response = await auditIndividualTC(transformerId, auditMonthYear)
            notifier.notifySuccess(response.message)
            setAuditBtnLoader(false)
            fetchAuditMetrics()
            fetchAuditResults()
        }
        catch (err) {
            notifier.notifyError(err)
            setAuditBtnLoader(false)
        }
    }

    /////////////////////////////////////Top Tab handler//////////////////////////////////
    const onResultTabHandler = (tab) => {
        setTableLoading(true)
        setActiveTransformerUnauditedButton("")
        setActiveTransformerAuditedButton("")
        if (tab !== "transformers-unaudited") {
            setRemarksFilter([])
        }
        setPageNumber(1)
        setDataTab(tab);
    }


    //////////////////////////////////////Filter Handler Functions/////////////////////////
    const filterHandler = () => {
        setFilterVisible(!filterVisible);
    }

    const applyFilters = (filters) => {
        setPageNumber(1)
        fetchAuditResults()
        setFilterVisible(false)
    }
    const resetFilter = () => {
        setExecutionTypeFilters([])
        setSelectedMRs([])
    }

    //////////////////////////////////Audited Sub Tab Handler///////////////////////////
    const auditedTransformersButtonHandler = (tag) => {
        setActiveTransformerAuditedButton(prevState => prevState === tag ? "" : tag);
    }

    //////////////////////////////////Unaudited Sub Tab Handler///////////////////////////
    const unauditedTransformersButtonHandler = (tag) => {
        if (tag !== "tc-with-remarks" || tag === activeTransformerUnauditedButton) {
            setRemarksFilter([])
        }
        setActiveTransformerUnauditedButton(prevState => prevState === tag ? "" : tag);
    }
    ////////////////////////////Render Data for audit result table/////////////////// 
    const getTCConsumption = (index) => {
        return transformers[index].audit.filter(item => (
            new Date(item.month).getFullYear() === new Date(auditMonth).getFullYear()
            && new Date(item.month).getMonth() === new Date(auditMonth).getMonth()))[0]?.reading?.consumption ?? "----"
    }

    const getInstallationsConsumption = (index) => {
        return transformers[index].audit.filter(item => (
            new Date(item.month).getFullYear() === new Date(auditMonth).getFullYear()
            && new Date(item.month).getMonth() === new Date(auditMonth).getMonth()))[0]?.installationConsumption ?? "----"
    }

    const getLossPercentage = (index) => {
        return transformers[index].audit.filter(item =>
        (new Date(item.month).getFullYear() === new Date(auditMonth).getFullYear()
            && new Date(item.month).getMonth() === new Date(auditMonth).getMonth()))[0]?.lossPercentage ?? "----"
    }

    const getRemark = (index) => {
        return enumMappings[transformers[index].audit.filter(item =>
            DateToYYYYMM(item.month) === DateToYYYYMM(auditMonth))[0]?.reading?.failureReason] ??
            enumMappings[transformers[index].audit.filter(item =>
                DateToYYYYMM(item.month) === DateToYYYYMM(fetchNextMonth(auditMonth)))[0]?.reading?.failureReason] ?? "----"

    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    const viewTCDetails = (index, transformer) => {
        history.push({
            pathname: `/tc-audit/${index + 1}`,
            state: {
                action: "viewTCDetails",
                tcData: transformer
            }
        })
    }

    const renderAuditResults = (tabName) => {
        return (
            <>
                <div className="col-md-12">
                    <div className="main-tc-table list-view-box mt-3">
                        <div className="search-heading">
                            <div className="search-space position-relative me-auto">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="TC Number"
                                        className="form-control search-field me-4"
                                        value={searchString}
                                        onChange={e => { setSearchString(e.target.value); setPageNumber(1) }}
                                    />
                                    <div style={{ width: 120 }}>
                                        <h3 className="font-medium-500 mb-0">
                                            {TCCount} TCs</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="download-result">
                                <a className="download-bt me-3">
                                    <img src={downloadImage} className="me-2" />
                                    <p className="mb-0 font-regular-400">Download Result</p>
                                </a>
                                <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                                    <img src={filterIcon} className="" width="18" height="12" />Filter
                                    {/* {(selectedMRs.length > 0 || executionTypeFilters.length > 0) ? <div style={{ width: 10, height: 10, borderRadius: 10, background: 'red', position: 'relative', top: -10, left: -75 }}></div> : null} */}
                                </button>

                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table mt-0">
                                <thead>
                                    <tr>
                                        <th className="ps-4" scope="col">#</th>
                                        <th scope="col">MR Code</th>
                                        <th scope="col">Reading Day</th>
                                        <th scope="col">TC No.</th>
                                        <th scope="col">TC Name</th>
                                        <th scope="col">Installations</th>
                                        <th scope="col">TC Consumption</th>
                                        <th scope="col">Installations Consumption</th>
                                        <th scope="col">Loss Percentage</th>
                                        {activeDataTab === "transformers-unaudited" && activeTransformerUnauditedButton === "tc-with-remarks" &&
                                            <th scope="col">Remark</th>}
                                        <th scope="col">View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableLoading ? <NoDataFound message="Loading..." /> :
                                            transformers.length === 0 ? <NoDataFound /> :
                                                transformers.map((transformer, index) => {
                                                    return (
                                                        <tr>
                                                            <td className="ps-4" scope="row">{((pageData.currentPage - 1) * perPage) + (index + 1)}</td>
                                                            <td>{transformer?.readingMR?.mrCode ?? "----"}</td>
                                                            <td>{transformer.readingDay || "----"}</td>
                                                            <td>{transformer.number ?? "----"}</td>
                                                            <Ellipsis>{transformer.name ?? "----"}</Ellipsis>
                                                            <td>{transformer?.installationCount ?? "----"}</td>
                                                            <td>{getTCConsumption(index)}</td>
                                                            <td>{getInstallationsConsumption(index)}</td>
                                                            <td>{getLossPercentage(index)}</td>
                                                            {activeDataTab === "transformers-unaudited" && activeTransformerUnauditedButton === "tc-with-remarks" &&
                                                                <td>{getRemark(index)}</td>}
                                                            {tabName === "unaudited" ?

                                                                <td>
                                                                    {(auditBtnLoader && auditBtnLoaderIndex === index) ?
                                                                        <span className="audit me-2" style={{paddingLeft:15,paddingRight:15}}>
                                                                            <Spinner variant="light"/>
                                                                    </span>
                                                                        : <a
                                                                            className="audit me-2"
                                                                            onClick={() => handleIndividualAudit(transformer.id, index)}
                                                                            style={{ cursor: 'pointer' }}>
                                                                            Audit
                                                                        </a>}
                                                                    <Link
                                                                        onClick={() => viewTCDetails(index, transformer)}>
                                                                        <img className="img-fluid icon-width-height" src={ViewImage} />
                                                                    </Link>
                                                                </td>

                                                                : <td>
                                                                    <a onClick={() => viewTCDetails(index, transformer)} style={{ cursor: 'pointer' }}>
                                                                        {/* <i className="fa fa-eye" aria-hidden="true"></i> */}
                                                                        <img className="img-fluid icon-width-height" src={ViewImage} />
                                                                    </a>
                                                                </td>}
                                                        </tr>
                                                    )
                                                })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <nav aria-label="Page navigation example" className="pagination-1 pb-3">
                        <ul className="pagination mb-0" style={{ marginRight: 15 }}>
                            <Paginator
                                currentPage={pageData.currentPage}
                                lastPage={pageData.lastPage}
                                onPressNext={() => { setPageNumber(prevState => prevState >= pageData.lastPage ? prevState : prevState + 1) }}
                                onPressPrevious={() => { setPageNumber(prevState => prevState <= 1 ? prevState : prevState - 1) }}
                            />
                        </ul>
                    </nav>
                </div>
            </>

        )
    }

    const filterData = [
        {
            heading: "Execution Type",
            shape: "square",
            options: executionTypeEnums,
            value: executionTypeFilters,
            setValue: (selectedValue) => { executionTypeFilters.includes(selectedValue) ? setExecutionTypeFilters(executionTypeFilters?.filter(item => item !== selectedValue)) : setExecutionTypeFilters([selectedValue]) }
        }, ,
        {
            heading: "MR Code",
            shape: "rounded",
            options: MRs,
            value: selectedMRs,
            setValue: (selectedValue) => { selectedMRs.includes(selectedValue) ? setSelectedMRs(selectedMRs.filter(item => item !== selectedValue)) : setSelectedMRs([...selectedMRs, selectedValue]) }
        },
        ...((activeDataTab === 'transformers-unaudited' && activeTransformerUnauditedButton === 'tc-with-remarks') ? [{
            heading: "Remarks",
            shape: "square",
            options: tcReadingRemarks,
            value: remarksFilter,
            setValue: (selectedValue) => { remarksFilter.includes(selectedValue) ? setRemarksFilter(remarksFilter?.filter(item => item !== selectedValue)) : setRemarksFilter([...remarksFilter, selectedValue]) }

        }] : [])
    ]

    return (
        <>
            {loading ? <FullScreenLoader /> : <div className="container audit-result-section">
                <Filter
                    footerStyle={{ position: 'relative' }}
                    filterData={filterData}
                    isFilterVisible={filterVisible}
                    onFilterClose={filterHandler}
                    onFiltersApply={applyFilters}
                    onResetApply={resetFilter}
                />
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item me-2" role="presentation">
                                <button onClick={() => onResultTabHandler('total-transformers')} className={`nav-link ${activeDataTab == 'total-transformers' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                    <p className="mb-0 font-medium-500">Total Transformers</p>
                                    <hr />
                                    <h4 className="mb-0">{auditMetrics.totalTransformers}</h4>
                                </button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button onClick={() => onResultTabHandler('transformers-audited')} className={`nav-link ${activeDataTab == 'transformers-audited' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                    <p className="mb-0 font-medium-500">Transformers Audited</p>
                                    <hr />
                                    <h4 className="mb-0">{auditMetrics.audited}</h4>
                                </button>
                            </li>
                            <li className="nav-item me-2" role="presentation">
                                <button onClick={() => onResultTabHandler('transformers-unaudited')} className={`nav-link ${activeDataTab == 'transformers-unaudited' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                    <p className="mb-0 font-medium-500">Transformers Unaudited</p>
                                    <hr />
                                    <h4 className="mb-0">{auditMetrics.unaudited}</h4>
                                </button>
                            </li>

                            {/* <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn" style={{background:"grey",alignSelf:'flex-end',justifySelf:'flex-end',marginLeft:150}}>
                                <img src={filterIcon} className="" width="18" height="12" />Filter
                            </button> */}


                        </ul>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-9">
                        <div className="tab-content" id="myTabContent">

                            {/* /////////////////////////////////////////////////////////////////////////TOTAL TRANSFORMERS///////////////////////////////////////////////////////////// */}
                            <div className={`tab-pane fade show ${activeDataTab == 'total-transformers' ? 'show active' : ''} first-toggle-tab mb-4`} role="tabpanel" aria-labelledby="home-tab">
                                <div className="row top-transformer-tab">
                                    {renderAuditResults('total')}
                                </div>
                            </div>

                            {/* /////////////////////////////////////////////////////////////////////////TRANSFORMERS AUDITED///////////////////////////////////////////////////////////// */}

                            <div className={`tab-pane fade second-toggle-tab mb-4 ${activeDataTab == 'transformers-audited' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="profile-tab">
                                <div className="row top-transformer-tab">
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-4">
                                            <div className={`cp card-body ${activeTransformerAuditedButton == '<-5' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('<-5')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">Below -5%</p>
                                                <hr />
                                                <h4 className="mb-0">{auditMetrics.negativeFiveAndBelow}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-4">
                                            <div className={`cp card-body ${activeTransformerAuditedButton == '-5-0' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('-5-0')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">-5 to 0%</p>
                                                <hr />
                                                <h4 className="mb-0">{auditMetrics.zeroToNegativeFive}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-5">
                                            <div className={`cp card-body ${activeTransformerAuditedButton == '0-5' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('0-5')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">0 - 5%</p>
                                                <hr />
                                                <h4 className="mb-0">{auditMetrics.zeroToFive}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-6">
                                            <div className={`cp card-body ${activeTransformerAuditedButton == '5-10' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('5-10')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">5 - 10%</p>
                                                <hr />
                                                <h4 className="mb-0">{auditMetrics.fiveToTen}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-7">
                                            <div className={`cp card-body ${activeTransformerAuditedButton == '10-15' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('10-15')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">10 - 15%</p>
                                                <hr />
                                                <h4 className="mb-0">{auditMetrics.tenToFifteen}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-8">
                                            <div className={`cp card-body ${activeTransformerAuditedButton == '15-20' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('15-20')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">15 - 20%</p>
                                                <hr />
                                                <h4 className="mb-0">{auditMetrics.fifteenToTwenty}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-8">
                                            <div className={`cp card-body ${activeTransformerAuditedButton == '>20' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('>20')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">Above 20%</p>
                                                <hr />
                                                <h4 className="mb-0">{auditMetrics.twentyAndAbove}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    {renderAuditResults('audited')}
                                </div>
                            </div>

                            {/* /////////////////////////////////////////////////////////////////////////TRANSFORMERS UNAUDITED///////////////////////////////////////////////////////////// */}

                            <div className={`tab-pane fade third-toggle-tab mb-4 ${activeDataTab == 'transformers-unaudited' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="contact-tab">
                                <div className="row top-transformer-tab">
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-4">
                                            <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-untagged' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-untagged')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">TCs Untagged</p>
                                                <hr />
                                                <h4 className="mb-0 font-medium-500">{auditMetrics.untagged}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-5">
                                            <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-without-fr' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-without-fr')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">TCs Without Consumption</p>
                                                <hr />
                                                <h4 className="mb-0 font-medium-500">{auditMetrics.withoutConsumption}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card custom-card-tc-6">
                                            <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-with-remarks' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-with-remarks')}>
                                                <p className="card-text mb-0 me-auto font-regular-400">TCs With Remarks</p>
                                                <hr />
                                                <h4 className="mb-0 font-medium-500">{auditMetrics.withRemark}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    {renderAuditResults('unaudited')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default AuditResult;