////////////////////////////////////////////////Template for Filter Component///////////////////////////////
export const template = [
    {
        heading: "Example 1",
        shape: "square",
        options: [
            {
                label: "Label A",
                value: "Value A"
            },
            {
                label: "Label B",
                value: "Value B"
            },
            {
                label: "Label C",
                value: "Value C"
            },
            {
                label: "Label D",
                value: "Value D"
            }
        ],
        value: "",
        setValue: () => { }
    },
    {
        heading: "Example 2",
        options: [
            {
                label: "Label A",
                value: "Value A"
            },
            {
                label: "Label B",
                value: "Value B"
            },
            {
                label: "Label C",
                value: "Value C"
            },
            {
                label: "Label D",
                value: "Value D"
            }
        ],
        value: "",
        setValue: () => { }
    },
    {
        heading: " Example 3",
        shape: "square",
        options: [
            {
                label: "Label A",
                value: "Value A"
            },
            {
                label: "Label B",
                value: "Value B"
            },
            {
                label: "Label C",
                value: "Value C"
            },
            {
                label: "Label D",
                value: "Value D"
            }
        ],
        value: "",
        setValue: () => { }
    },
    {
        heading: "Example 4",
        shape: "square",
        options: [
            {
                label: "Label A",
                value: "Value A"
            },
            {
                label: "Label B",
                value: "Value B"
            },
            {
                label: "Label C",
                value: "Value C"
            },
            {
                label: "Label D",
                value: "Value D"
            }
        ],
        value: "",
        setValue: () => { }
    }
]
//////////////////////////////////////Example for Filter Object//////////////////////////////////////
export const exampleOptions = [
    {
        label: "Label A",
        value: "Value A"
    },
    {
        label: "Label B",
        value: "Value B"
    },
    {
        label: "Label C",
        value: "Value C"
    },
    {
        label: "Label D",
        value: "Value D"
    }
]
////////////////////////////////////Remark Options////////////////////////////////////
export const remarkOptions = [
    {
        label: "Un-Billed",
        value: "UNBILLED",
    },
    {
        label: "Vacant",
        value: "VACANT",
    },
    {
        label: "Zero Consumption",
        value: "ZERO_CONSUMPTION",
    },
    {
        label: "MNR",
        value: "MNR",
    },
    {
        label: "Door Lock",
        value: "DOOR_LOCKED",
    },
]
//////////////////////////////////////TC Stations Options////////////////////////////
export const tcOptions = [
    {
        label: "Tagged",
        value: "tagged",
    }, {
        label: "Untagged",
        value: "untagged",
    },
]

export const durations = [
    {
        label: '> 1 month',
        value: 1
    },
    {
        label: '> 2 month',
        value: 2
    },
    {
        label: '> 3 month',
        value: 3,
    },
]

export const arrears = [
    {
        label: '0 - 500',
        value: '0-500',
    },
    {
        label: '501 - 5000',
        value: '501-5000',
    },
    {
        label: '5001 - 25000',
        value: '5001-25000',
    },
    {
        label: '> 25000',
        value: 'ABOVE_25000',
    },
]

export const installationsTariffs = [
    {
        label: 'LT1',
        value: 'LT1',
    },
    {
        label: 'LT2',
        value: 'LT2',
    },
    {
        label: 'LT3',
        value: 'LT3',
    },
    {
        label: 'LT4',
        value: 'LT4',
    },
    {
        label: 'LT5',
        value: 'LT5',
    },
    {
        label: 'LT6',
        value: 'LT6',
    },
    {
        label: 'LT7',
        value: 'LT7',
    },
    {
        label: 'HT1',
        value: 'HT1',
    },
    {
        label: 'HT2',
        value: 'HT2',
    },
    {
        label: 'HT3',
        value: 'HT3',
    },
    {
        label: 'HT4',
        value: 'HT4',
    },
    {
        label: 'HT5',
        value: 'HT5',
    },
]

export const remarks = {
    myInstallations:
        [
            {
                label: 'Un billed',
                value: 'UNBILLED',
            },
            {
                label: 'Vacant',
                value: 'VACANT',
            },
            {
                label: 'MNR',
                value: 'MNR',
            },
            {
                label: 'Zero Consumptions',
                value: 'ZERO_CONSUMPTION',
            },
            {
                label: 'Door locked',
                value: 'DOOR_LOCKED',
            },
        ],
    newInstallations:
        [
            {
                label: 'New Installations',
                value: 'newInstallations',
            },
            {
                label: 'Un billed',
                value: 'unBilled',
            },
            {
                label: 'Vacant',
                value: 'vacant',
            },
            {
                label: 'MNR',
                value: 'mnr',
            },
            {
                label: 'Zero Consumptions',
                value: 'zeroConsumptions',
            },
            {
                label: 'Door lock',
                value: 'DoorLock',
            },
        ],
    unBilled:
        [
            {
                label: 'New RR Number',
                value: 'NEW_RR_NUMBER',
            },
            {
                label: 'Building Demolish',
                value: 'BUILDING_DEMOLISH',
            },
            {
                label: 'Surrendered',
                value: 'SURRENDERED',
            },
            {
                label: 'Meter Removed',
                value: 'METER_REMOVED',
            },
            {
                label: 'Load Changed',
                value: 'LOAD_CHANGE',
            },
            {
                label: 'Tariff Changed',
                value: 'TARIFF_CHANGE',
            },
            {
                label: 'Meter Changed',
                value: 'METER_CHANGE',
            },
            {
                label: 'Reading Configuration',
                value: 'READING_CONFIGURATION',
            },
            {
                label: 'Financial Transaction Error',
                value: 'FINANCIAL_TRANSACTION_ERROR',
            },
            {
                label: 'SP Error',
                value: 'SP_ERROR',
            },
            {
                label: 'Area Not Found',
                value: 'AREA_NOT_FOUND',
            },
            {
                label: 'Other O & M RR Number',
                value: 'OTHER_O&M_RR_NUMBER',
            },
        ],
    mnr:
        [
            {
                label: 'Meter Changed at Location',
                value: 'METER_CHANGED_AT_LOCATION',
            },
            {
                label: '3 O Meter, Pending MRT',
                value: '3.0_METER, PENDING_MRT',
            },
            {
                label: 'Pending at System',
                value: 'PENDING_AT_SYSTEM',
            },
            {
                label: 'Other',
                value: 'OTHERS',
            },
        ],
    disconnect:
        [
            {
                label: "Meter Removed",
                value: "METER_REMOVED"
            },
            {
                label: "Disconnected",
                value: "DISCONNECTED"
            },
            {
                label: "Vacant",
                value: "VACANT"
            },
            {
                label: "Pole disconnected",
                value: "POLE_DISCONNECTED"
            },
            {
                label: "Government installation",
                value: "GOVT_INSTALLATION"
            },
            {
                label: "Amount paid",
                value: "AMOUNT_PAID"
            },
        ],
}


