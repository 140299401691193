import { API } from 'aws-amplify'



///////////////////////////////////File upload and Audit Functions///////////////////////////
export const getPresignedURL = (fileName, yearMonth) => {
    return API.get('baseURL', `/ae/audit-files/${fileName}/months/${yearMonth}`)
}

export const getSampleFilePresignedURL = (fileName) => {
    return API.get('baseURL', `/ae/audit-sample-files/${fileName}`)
}


export const uploadFile = (file, preSignedData) => {

    const { url, fields } = preSignedData

    const formData = new FormData();
    formData.append('bucket', fields.bucket)
    formData.append('key', fields.key)
    formData.append('Content-Type', fields['Content-Type'])
    formData.append('Policy', fields['Policy'])
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm'])
    formData.append('X-Amz-Credential', fields['X-Amz-Credential'])
    formData.append('X-Amz-Date', fields['X-Amz-Date'])
    formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token'])
    formData.append('X-Amz-Signature', fields['X-Amz-Signature'])
    formData.append('file', file)


    return fetch(url, {
        method: 'POST',
        body: formData
    })

}

export const getUploadStatus = (yearMonth) => {
    return API.get('baseURL', `/ae/audit-files?month=${yearMonth}`)
}

//View Success Screen button
export const getDownLoadURL = (fileName, monthYear) => {
    return API.get('baseURL', `/ae/audit-files/${fileName}/months/${monthYear}/log`)
}



//////////////////////////////////////////////////////Audit Result Functions////////////////////////////////
export const getAuditMetrics = (month) => {
    return API.get('baseURL', `/ae/audit-metrics/months/${month}`)
}

export const getAuditResults = (month, page, perPage, searchString, activeDataTab, tcAuditedFilter, tcUnAuditedFilter, executionType, selectedMRs, remarks) => {
    return API.get('baseURL', `/ae/transformer-centres`, {
        queryStringParameters: {
            month,
            page,
            perPage,
            ...((searchString.length > 0) && { number: searchString }),
            ...((activeDataTab === 'transformers-audited') && { audited: true }),
            ...((activeDataTab === 'transformers-unaudited') && { unaudited: true }),

            ...((tcAuditedFilter === '<-5') && { negativeFiveAndBelow: true }),
            ...((tcAuditedFilter === '-5-0') && { zeroToNegativeFive: true }),
            ...((tcAuditedFilter === '0-5') && { zeroToFive: true }),
            ...((tcAuditedFilter === '5-10') && { fiveToTen: true }),
            ...((tcAuditedFilter === '10-15') && { tenToFifteen: true }),
            ...((tcAuditedFilter === '15-20') && { fifteenToTwenty: true }),
            ...((tcAuditedFilter === '>20') && { twentyAndAbove: true }),

            ...((tcUnAuditedFilter === 'tc-untagged') && { untagged: true }),
            ...((tcUnAuditedFilter === 'tc-without-fr') && { withoutConsumption: true }),
            ...((tcUnAuditedFilter === 'tc-with-remarks') && { withRemarks: true }),

            ...((executionType.length > 0) && { executionType: executionType }),
            ...((selectedMRs.length > 0) && { mrCode: selectedMRs }),
            ...((remarks.length > 0) && { remark:remarks }),

        }
    })

}

export const auditIndividualTC = (transformerId, month) => {
    return API.post('baseURL', `/ae/audit/transformer-centres/${transformerId}/months/${month}`)
}

export const auditTCs = (month) => {
    return API.post('baseURL', `/ae/audit/transformer-centres/months/${month}`)

}