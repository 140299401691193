import React, { useState, useEffect, useContext, } from 'react';
import {  useHistory } from 'react-router-dom';
import { validateProfileDetails } from '../../services/validators/profileSchema';
import { NotifierContext } from '../../context/NotifierContext';
import { updateProfileDetails } from '../../services/api/profileService'

export default function ProfileDetail(props) {

  // const [formFields, setFormFields] = useState({ FirstName: '', LastName: '', StationName: '', StationCode: '', designation: '', ContactNumber: '', email: '', address: '', city: '', PinCode: '', state: '', male: true, female: false });
  const [firstFormState, setFirstFormState] = useState({ modalVisible: true });
  const [secondFormState, setSecondFormState] = useState({ modalVisible: false });
  const [isFormValid,] = useState(true);

  const notifier = useContext(NotifierContext);

  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    sectionName: '',
    sectionCode: '',
    designation: 'AE',
    contactNumber: '',
    emailId: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    pinCode: ''
  })

  const [onSubmit, setOnSubmit] = useState(false)
  let history = useHistory();


  useEffect(() => {
    setAEProfile()
    // const timer = setTimeout(() => {
    //   let checkForm = true;
    //   for (const [key, value] of Object.entries(formFields)) {
    //     if (validateField(key) !== '') {
    //       checkForm = false;
    //     }
    //   }
    //   setFormValid(checkForm);
    // }, 200);
    // return () => {
    //   clearTimeout(timer);
    // }
  }, [])

  const setAEProfile = () => {
    const { firstName, lastName, gender, sectionName, sectionCode, contactNumber, emailId, addressLine1, addressLine2, city, state, pinCode } = props?.location?.state?.profile
    setFormFields({
      ...formFields,
      firstName: firstName ? firstName : '',
      lastName: lastName ? lastName : '',
      gender: gender ? gender : '',
      sectionName: sectionName ? sectionName : '',
      sectionCode: sectionCode ? sectionCode : '',
      contactNumber: contactNumber ? contactNumber.substring(3) : '',
      emailId: emailId ? emailId : '',
      addressLine1: addressLine1 ? addressLine1 : '',
      addressLine2: addressLine2 ? addressLine2 : '',
      city: city ? city : '',
      state: state ? state : '',
      pinCode: pinCode ? pinCode : ''
    })
  }

  // const validateField = (name) => {
  //   let error = '';
  //   error = formFields[name].length == 0 ? 'Please enter valid value' : '';
  //   return error;
  // }

  const handleChange = (event, key) => {
    event.preventDefault();
    setFormFields({ ...formFields, firstName: event.target.value })
    const formFieldsObject = {
      ...formFields,
      [key]: event.target.value
    }
    setFormFields(formFieldsObject);
  }

  const onSubmitClick = (event) => {
    event.preventDefault();
    try {
       validateProfileDetails(formFields)
      // const response = await updateProfileDetails(validationResult)     
      setFirstFormState({ modalVisible: false });
      setSecondFormState({ modalVisible: true });
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }

  const onConfirmClick = async () => {
    setOnSubmit(true)
    try {
      const validationResult = validateProfileDetails(formFields)
       await updateProfileDetails(validationResult)
      notifier.notifySuccess("Successfully updated AE Profile")
      history.push("/profile")
    }
    catch (err) {
      notifier.notifyError(err)
      setOnSubmit(false)
    }
  }

  const backHandleClick = (event) => {
    event.preventDefault();
    setFirstFormState({ modalVisible: true });
    setSecondFormState({ modalVisible: false });
  }

  const { addressLine1, addressLine2 } = formFields

  return (
    <React.Fragment>
      {
        firstFormState.modalVisible === true ?
          <div className="profile-content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12">
                  <h1 className="mb-3">My Profile</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="list-view-box mt-2">
                    <h3 className="mb-4">Personal Details</h3>
                    <div className="profile-form">
                      <form>
                        <div className="forms-input">
                          <div className="row">
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">First Name<sup className="red">*</sup></label>
                              <input name="FirstName" onChange={e => handleChange(e, 'firstName')} value={formFields['firstName']} type="text" placeholder="First Name" className="form-control" />
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Last Name<sup className="red">*</sup></label>
                              <input name="LastName" onChange={e => handleChange(e, 'lastName')} value={formFields['lastName']} type="text" placeholder="Last Name" className="form-control" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Gender<sup className="red">*</sup></label>
                              <div className="row">
                                <div onClick={() => setFormFields({ ...formFields, gender: 'male' })} className="col-12 col-md-5">
                                  <input name="male" checked={formFields.gender === 'male' ? true : false} className="form-check-input" type="radio" id="male"
                                  />&nbsp; <label className="form-check-label" htmlFor="male">Male</label>
                                </div>
                                <div onClick={() => setFormFields({ ...formFields, gender: 'female' })} className="col-12 col-md-5">
                                  <input name="female" checked={formFields.gender === 'female' ? true : false} className="form-check-input" type="radio" id="female"
                                  />&nbsp; <label className="form-check-label" htmlFor="female">Female</label>
                                </div>
                                <div onClick={() => setFormFields({ ...formFields, gender: 'others' })} className="col-12 col-md-5">
                                  <input name="others" checked={formFields.gender === 'others' ? true : false} className="form-check-input" type="radio" id="female"
                                  />&nbsp; <label className="form-check-label" htmlFor="others">Other</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Section Name</label>
                              <input name="SectionName" disabled={true} value={formFields['sectionName']} type="text" placeholder="Section Name" className="form-control" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Section Code</label>
                              <input name="SectionCode" disabled={true} value={formFields['sectionCode']} type="text" placeholder="Section Code" className="form-control" />
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Designation</label>
                              <input name="designation" disabled={true} value={formFields['designation']} type="text" placeholder="Designation" className="form-control" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Contact Number</label>
                              <input name="ContactNumber" type="text" pattern="[0-9]*"
                                maxLength="10"
                                onChange={e => handleChange(e, 'contactNumber')} value={formFields['contactNumber']} placeholder="Contact Number" className="form-control" />
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Email ID</label>
                              <input name="email" onChange={e => handleChange(e, 'emailId')} value={formFields['emailId']} type="email" placeholder="Email ID" className="form-control" />
                            </div>
                          </div>
                        </div>
                        <hr className="divider" />
                        <h3 className="mb-4 mt-4">Address</h3>
                        <div className="forms-input">
                          <div className="row">
                            <div className="col-12 col-md-8 mb-4">
                              <label className="form-label font-regular-400">Address Line 1</label>
                              <input name="address" onChange={e => handleChange(e, 'addressLine1')} value={formFields['addressLine1']} type="text" placeholder="Address" className="form-control" />
                            </div>
                            <div className="col-12 col-md-8 mb-4">
                              <label className="form-label font-regular-400">Address Line 2</label>
                              <input name="address" onChange={e => handleChange(e, 'addressLine2')} value={formFields['addressLine2']} type="text" placeholder="Address" className="form-control" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">City</label>
                              <input name="city" onChange={e => handleChange(e, 'city')} value={formFields['city']} type="text" placeholder="Address" className="form-control" />
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                              <label className="form-label font-regular-400">Pin Code</label>
                              <input name="PinCode"
                                maxLength="6"
                                type="number" onChange={e => handleChange(e, 'pinCode')} value={formFields['pinCode']}  placeholder="Pin Code" className="form-control" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-5 mb-4">
                              <label className="form-label font-regular-400">State</label>
                              <input name="state" onChange={e => handleChange(e, 'state')} value={formFields['state']} type="text" placeholder="State" className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-3 text-end tc-submit-btn">
                            <button onClick={onSubmitClick} type="submit" className={`btn btn-primary profile-active-btn ${isFormValid ? '' : 'btn-grey'}`}>Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          null
      }
      {
        secondFormState.modalVisible === true ?
          <div className="profile-content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12">
                  <h1 className="mb-3">My Profile</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="list-view-box mt-2">
                    <h3 className="mb-4">Personal Details</h3>
                    <div className="profile-form">
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">First Name</label>
                          <p className="label-value">{formFields.firstName}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Last Name</label>
                          <p className="label-value">{formFields.lastName}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Gender</label>
                          <p className="label-value">{formFields.gender}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Section Name</label>
                          <p className="label-value">{formFields.sectionName}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Section Code</label>
                          <p className="label-value">{formFields.sectionCode}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Designation</label>
                          <p className="label-value">{formFields.designation}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Contact Number</label>
                          <p className="label-value">+91 {formFields.contactNumber}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-4">
                          <label className="form-label font-medium">Email ID</label>
                          <p className="label-value">{formFields.emailId}</p>
                        </div>
                      </div>
                      <hr className="divider" />
                      <h3 className="mb-4 mt-4">Address</h3>
                      <div className="row">
                        <div className="col-12 col-md-5 mb-4">
                          <label className="form-label font-medium">Address</label>
                          <p className="label-value">{addressLine1} {addressLine2}</p>
                          {/* <p className="label-value">#34/13/1, 7<sup>th</sup> Cross, Shakthiganapathinagara Main Road, Basaveshwaranagara, Bangalore 560079, Karnataka.</p> */}
                        </div>
                      </div>
                      <hr className="divider" />
                      <div className="row">
                        <div className="col-md-12 mt-3 text-end tc-submit-btn">
                          <button onClick={backHandleClick} type="submit" className="btn btn-primary edit-btn">Edit
                          </button>&nbsp;
                          <button onClick={onConfirmClick} disabled={onSubmit} type="submit" className={`btn btn-primary profile-active-btn ${isFormValid ? '' : 'btn-grey'}`}>Confirm
                          </button>&nbsp;

                          {/* <Link to='/profile' type="submit" className="btn btn-primary confirm-btn">Confirm
                          </Link> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          null
      }
    </React.Fragment>
  );
}