import React from "react";
import closeIcon from '../../../assets/img/close-icon1.svg'

const ViewTC = ({ visible, onModalClose, tcData }) => {

    const styles = {
        row: {
            // padding: 10,
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        col: {
            width: '50%',
            padding: 10,
            // background:'pink',
            border: '1px solid black',
            height: 50,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            textOverflow: 'ellipsis'
        },
    }

    return (
        <React.Fragment>
            <div className={`overlay-filter ${visible ? '' : 'd-none'}`}></div>
            <div className={`filter-bar ${visible ? '' : 'd-none'}`}>
                <div style={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
                    <div className="row">
                        <div className="col-md-12 ps-0 pe-0">
                            <div className="header">
                                <ul className="mb-0 ps-0">
                                    <li className="me-auto font-bold font-20 filter-title">TC Details</li>
                                    <li onClick={onModalClose}>
                                        <img className="img-fluid" src={closeIcon} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            style={{ overflow: "auto", maxHeight: '90vh' }}>
                            <div style={styles.row}>
                                <div style={styles.col}>Tagged MRs</div>
                                <div style={styles.col} title={tcData.taggedMRs.map(item => item.mrCode)}>{tcData.taggedMRs.map(item => <span>{item.mrCode}, </span>)}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Reading MRs</div>
                                <div style={styles.col} title={tcData.readingMR.mrCode}>{tcData.readingMR.mrCode}</div>

                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Number</div>
                                <div style={styles.col} title={tcData.number}>{tcData.number}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Name</div>
                                <div style={styles.col} title={tcData.name}>{tcData.name}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Reading Day</div>
                                <div style={styles.col} title={tcData.readingDay}>{tcData.readingDay}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>TC Serial Number</div>
                                <div style={styles.col} title={tcData.serialNumber}>{tcData.serialNumber}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>TC Make</div>
                                <div style={styles.col} title={tcData.make}>{tcData.make}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>TC Capacity</div>
                                <div style={styles.col} title={tcData.capacity}>{tcData.capacity} KVA</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>TIMS Code</div>
                                <div style={styles.col} title={tcData.timsCode}>{tcData.timsCode}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>DTLMS</div>
                                <div style={styles.col} title={tcData.dtlmsCode}>{tcData.dtlmsCode}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>DTR</div>
                                <div style={styles.col} title={tcData.dtrCode}>{tcData.dtrCode}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>GPS Location</div>
                                <div style={styles.col} title={`${tcData.gpsCoordinates?.coordinates?.lat},${tcData.gpsCoordinates?.coordinates?.long}`}>{tcData.gpsCoordinates?.coordinates?.lat}{','} {tcData.gpsCoordinates?.coordinates?.long}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Execution Type</div>
                                <div style={styles.col} title={tcData.executionType}>{tcData.executionType}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Feeder</div>
                                <div style={styles.col} title={`${tcData.feeder?.number},${tcData.feeder?.name}`}>{tcData.feeder?.number}{', '}{tcData.feeder?.name}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Meter Make</div>
                                <div style={styles.col} title={tcData.meter.make}>{tcData.meter.make}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Meter Serial Number</div>
                                <div style={styles.col} title={tcData.meter.serialNumber}>{tcData.meter.serialNumber}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>CT Ratio</div>
                                <div style={styles.col} title={`${tcData.meter?.ctRatio?.primaryCurrent}/${tcData.meter?.ctRatio?.secondaryCurrent}`}>{tcData.meter?.ctRatio?.primaryCurrent}{' / '}{tcData.meter?.ctRatio?.secondaryCurrent}</div>
                            </div>
                            <div style={styles.row}>
                                <div style={styles.col}>Meter Constant</div>
                                <div style={styles.col} title={tcData.meter?.meterConstant}>{tcData.meter?.meterConstant}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewTC;



// import React, { useState } from "react";

// const ViewTC = ({ children, visible, onModalClose, tcData }) => {

//     const style = {
//         display: visible ? 'block' : 'none',
//     };
//     return (
//         <React.Fragment>
//             <div className="modal export-excel-modal"
//                 id="exampleModal"
//                 tabindex="-1"
//                 aria-labelledby="exampleModalLabel"
//                 aria-hidden="true"
//                 style={style}>
//                 <div className="modal-dialog" >
//                     <div className="modal-content" style={{ width: '50vw' }}>
//                         {/* ////////////////Header////////////////// */}
//                         <div className="modal-header">
//                             <h5
//                                 className="modal-title"
//                                 id="exampleModalLabel">
//                                 TC Details
//                             </h5>
//                             <button
//                                 onClick={onModalClose}
//                                 type="button"
//                                 className="btn-close"
//                                 data-bs-dismiss="modal"
//                                 aria-label="Close">
//                             </button>
//                         </div>
//                         {/* //////////////////////////Body//////////////////// */}
//                         <div className="modal-body"
//                             style={{ maxHeight: '80vh', overflow: "auto" }}>
//                             <div>
//                                 Tagged MRs - {tcData.taggedMRs.map(item=><span>{item.mrCode}, </span>)}
//                             </div>
//                             <div>
//                                 Reading MRs - {tcData.readingMR.mrCode}
//                             </div>
//                             <div>
//                                 Number - {tcData.number}
//                             </div>
//                             <div>
//                                 Name - {tcData.name}
//                             </div>
//                             <div>
//                                 Reading Day - {tcData.readingDay}
//                             </div>
//                             <div>
//                                 TC Serial Number - {tcData.serialNumber}
//                             </div>
//                             <div>
//                                 TC Make -{tcData.make}
//                             </div>
//                             <div>
//                                 TC Capacity - {tcData.capacity} KVA
//                             </div>
//                             <div>
//                                 TIMS Code - {tcData.timsCode}
//                             </div>
//                             <div>
//                                 DTLMS - {tcData.dtlmsCode}
//                             </div>
//                             <div>
//                                 DTR - {tcData.dtrCode}
//                             </div>
//                             <div>
//                                 GPS Location - {tcData.gpsCoordinates?.coordinates?.lat}{','} {tcData.gpsCoordinates?.coordinates?.long}
//                             </div>
//                             <div>
//                                 Execution Type - {tcData.executionType}
//                             </div>
//                             <div>
//                                 Feeder - {tcData.feeder?.number}{', '}{tcData.feeder?.name}
//                             </div>
//                             <div>
//                                 Meter Make - {tcData.meter.make}
//                             </div>
//                             <div>
//                                 Meter Serial Number - {tcData.meter.serialNumber}
//                             </div>
//                             <div>
//                                 CT Ratio - {tcData.meter?.ctRatio?.primaryCurrent}{' / '}{tcData.meter?.ctRatio?.secondaryCurrent}
//                             </div>
//                             <div>
//                                 Meter Constant - {tcData.meter?.meterConstant}
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {visible && (
//                 <div className="modal-backdrop fade show"></div>
//             )}
//         </React.Fragment>
//     );
// };

// export default ViewTC;
