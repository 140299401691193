const Ajv = require("ajv").default
const ajv = new Ajv({ allErrors: true })
require("ajv-errors")(ajv, { singleError: true }
)
require("ajv-keywords")(ajv, ["transform"])

export const addMRValidation = (formFields) => {
    const dataFormatter = {
        name: {
            firstName: formFields.name,
        },
        mrCode: formFields.code,
        phoneNumber: `+91${formFields.phone}`,
    }

    const schema = {
        title: 'CreateMR',
        description: 'Create meter-reader user object',
        type: 'object',
        required: ['name', 'phoneNumber', 'mrCode'],
        additionalProperties: false,
        properties: {
            name: {
                type: 'object',
                required: ['firstName'],

                additionalProperties: false,
                properties: {
                    firstName: {
                        type: 'string',
                        pattern: '^[a-zA-Z .]{3,}$',
                        minLength: 3,
                        maxLength: 30,
                        transform: ['trim'],
                        errorMessage: "Enter a valid MR name"
                    },
                },
            },
            mrCode: {
                type: 'string',
                minLength: 6,
                maxLength: 10,
                pattern: '^[0-9]*$',
                errorMessage:"Enter a valid MR code"
            },
            phoneNumber: {
                type: 'string',
                minLength: 13,
                maxLength: 13,
                pattern: '^\\+91[6789][0-9]{9}$',
                errorMessage: "Enter a valid 10 digit phone number"
            },

        },
        errorMessage: {
            required: 'Missing mandatory properties',
            additionalProperties: 'No additional properties allowed',
            properties: {
                firstName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
                lastName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
                phoneNumber: 'Enter a valid Indian phone number',
                mrCode: 'Invalid MR Code',
                gender: 'Invalid gender',
                email: 'Invalid email',
                line1: 'Invalid data in address line1 ',
                line2: 'Invalid data in address line2 ',
                city: 'Invalid data in address city ',
                state: 'Invalid data in address state ',
                pinCode: 'Invalid data in pin Code ',
            },
        },
    };

    const validation = ajv.compile(schema)
    const isValid = validation(dataFormatter)
    if (isValid) return dataFormatter
    throw (validation.errors[0])
}

export const updateMRValidation = (formFields, prevData) => {

    //Properties should not be added if previous page properties are same.
    const dataFormatter = {
        ...(prevData.name !== formFields.name && {
            name: {
                firstName: formFields.name,
                // lastName: "Shetty"
            }
        }),
        ...(prevData.phoneNumber !== formFields.phone && {
            phoneNumber: `+91${formFields.phone}`,
            // mrCode: formFields.code,
            // gender: "male",
            // email: "Ramesh@email.com",
            // address: {
            //     line1: "asdasd",
            //     line2: "asdasd",
            //     city: "asdasd",
            //     state: "asdsad",
            //     pinCode: "560062"
            // }
        })
    }
    const schema = {
        title: 'CreateMR',
        description: 'Create meter-reader user object',
        type: 'object',
        // required: ['name', 'phoneNumber', 'mrCode'],
        additionalProperties: false,
        properties: {
            name: {
                type: 'object',
                required: ['firstName'],

                additionalProperties: false,
                properties: {
                    firstName: {
                        type: 'string',
                        pattern: '^[a-zA-Z .]{3,}$',
                        minLength: 3,
                        maxLength: 30,
                        transform: ['trim'],
                        errorMessage: "Enter a valid MR name"
                    },
                },
            },
            mrCode: {
                type: 'string',
                minLength: 6,
                maxLength: 10,
                pattern: '^[0-9]*$',
                errorMessage: "Enter a valid MR code"
            },
            phoneNumber: {
                type: 'string',
                minLength: 13,
                maxLength: 13,
                pattern: '^\\+91[6789][0-9]{9}$',
                errorMessage: "Enter a valid 10 digit phone number"
            },

        },
        errorMessage: {
            required: 'Missing mandatory properties',
            additionalProperties: 'No additional properties allowed',
            properties: {
                firstName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
                lastName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
                phoneNumber: 'Enter a valid Indian phone number',
                mrCode: 'Invalid MR Code',
                gender: 'Invalid gender',
                email: 'Invalid email',
                line1: 'Invalid data in address line1 ',
                line2: 'Invalid data in address line2 ',
                city: 'Invalid data in address city ',
                state: 'Invalid data in address state ',
                pinCode: 'Invalid data in pin Code ',
            },
        },
    };

    const validation = ajv.compile(schema)
    const isValid = validation(dataFormatter)
    if (isValid) return dataFormatter
    throw (validation.errors[0])
}