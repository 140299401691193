import React, { Fragment, useContext, useState, useEffect } from "react";
import closeIcon from "../../../../assets/img/close-icon.png";
import gpsIcon from "../../../../assets/img/gpc-location.svg";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "react-multi-select-component";
import Select from 'react-select/async';
import { executionType, readingDay, tcCapacity as tcCapacityOptions } from "../../../../assets/offlineData/TCOfflineData";
import { NotifierContext } from "../../../../context/NotifierContext";
import {  editTC, searchFeeder } from "../../../../services/api/tcService";
import { getMRs } from "../../../../services/api/mrService";
import { addTCValidator } from "../../../../services/validators/tcValidator";
import { editTCValidator } from "../../../../services/validators/editTCValidator";
import { AuditMonthContext } from "../../../../context/AuditMonthContext";
import ConfirmationModal from "./ConfirmationModal";

const EditTC = (props) => {

    const { tcData } = props;
    const notifier = useContext(NotifierContext)
    const { auditMonthYear } = useContext(AuditMonthContext)

    const [activeScreen, setActiveScreen] = useState("add_tc_form");
    const [isDisabled, setIsDisabled] = useState(false);
    const [MRs, setMRs] = useState([{ label: 'Loading...', value: 'Loading' }])

    const [visible, setVisibility] = useState(false)


    const [initialState, setInitialState] = useState("")
    const [state, setState] = useState({
        taggedMRs: [],
        readingMR: "",
        readingDay: "",
        tcName: "",
        tcNumber: "",
        tcSerialNumber: "",
        tcMake: "",
        tcCapacity: "",
        timsCode: "",
        dtlmsCode: "",
        dtrCode: "",
        gpsCoordinates: {
            latitude: "",
            longitude: ""
        },
        executionType: "",
        feederId: "",
        meterMake: "",
        meterSerialNumber: "",
        primaryCurrent: "",
        secondaryCurrent: "",
        meterConstant: "",
        initialReadingMonth: "",
        initialReading: "",
        remarks: "",
        feederNumber: "" //not to be considered, only to display feeder while editing
    })

    useEffect(() => {
        if (!state.secondaryCurrent || !state.primaryCurrent || state.primaryCurrent == 0 || state.secondaryCurrent == 0) {
            setState({ ...state, meterConstant: "" })
            return
        }
        setState({
            ...state,
            meterConstant: String(Math.round((state.primaryCurrent / state.secondaryCurrent) * 100) / 100)
        })
    }, [state.secondaryCurrent, state.primaryCurrent])

    useEffect(() => {
        fetchMRs()
    }, [])

    useEffect(() => {
        const { tcData } = props;
        // if (state.tcNumber !== tcData?.number) {
        const taggedMRs = tcData.taggedMRs.map(item => {
            return (
                {
                    label: item.mrCode,
                    value: item.cognitoDetails.sub
                }
            )
        })

        const stateValues = {
            ...state,
            taggedMRs,
            readingMR: tcData?.readingMR?.cognitoDetails?.sub ?? "",
            tcNumber: tcData?.number ?? "",
            tcName: tcData?.name ?? "",
            readingDay: String(tcData?.readingDay) ?? "",
            tcSerialNumber: String(tcData?.serialNumber ?? ""),
            tcMake: tcData?.make ?? "",
            tcCapacity: tcData?.capacity ?? "",
            timsCode: String(tcData?.timsCode ?? ""),
            dtlmsCode: tcData?.dtlmsCode ?? "",
            dtrCode: tcData?.dtrCode ?? "",
            gpsCoordinates: {
                latitude: tcData?.gpsCoordinates?.coordinates?.lat ?? "",
                longitude: tcData?.gpsCoordinates?.coordinates?.long ?? ""
            },
            executionType: tcData?.executionType ?? "",
            meterMake: tcData?.meter?.make,
            meterSerialNumber: String(tcData?.meter?.serialNumber ?? ""),
            primaryCurrent: tcData?.meter?.ctRatio?.primaryCurrent,
            secondaryCurrent: tcData?.meter?.ctRatio?.secondaryCurrent,
            feederNumber: tcData?.feeder?.number ? `${tcData?.feeder?.number}, ${tcData?.feeder?.name}` : "",
            feederId: tcData?.feeder?.id ?? ""

        }
        setState({ ...stateValues })
        setInitialState({ ...stateValues })
        // }
    }, [props.tcData])



    const fetchMRs = async () => {
        try {
            const response = await getMRs("", 1, 100)
            setMRs(response.data.MRList.map(item => {
                return ({
                    label: item.userInfo.mrCode,
                    value: item.cognitoDetails.sub
                })
            }))
        }
        catch (err) {
            notifier.notifyError(err)
        }

    }


    const setGPSCoordinates = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setState({
                ...state, gpsCoordinates: {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
            })
        }, function (error) {
            notifier.notifyError(`${error.message} - Grant Permission to capture TC location`);
        });

    }

    const handleFeederSearch = async (input) => {
        try {
            if (input.length >= 1) {
                const response = await searchFeeder(input)
                return response.data.feederList.map(item => {
                    return (
                        { label: `${item.number}, ${item.name}`, value: item.id }
                    )
                })
            }
        }
        catch (err) {
            notifier.notifyError(err)
        }
    }


    const tcConfirmScreenHandler = (event) => {
        try {
            const response = addTCValidator(state)
            setActiveScreen("confirm_tc_form");
        }
        catch (err) {
            notifier.notifyError(err)
        }
    };

    const tcFormShowHandler = (event) => {
        setActiveScreen("add_tc_form");
    };

    const onCloseButtonHandler = (event) => {
        props.onCloseButton();
    };

    const handleStateChange = (key, value) => {
        value?.target?.validity?.valid && setState({ ...state, [key]: value?.target?.value })

    }

    const submitHandler = () => {

        //To detect any changes in tagged MRs
        const isTaggedMRSame = state.taggedMRs.length === initialState.taggedMRs.length && state.taggedMRs.every((item, index) => item.value === initialState.taggedMRs[index].value)

        //When reading day or MRs are changed.
        if (tcData?.readingDay != state.readingDay || tcData?.readingMR?.cognitoDetails?.sub !== state.readingMR || !isTaggedMRSame) {
            const confirmation = window.confirm("Editing Reading Day or MRs will untag all installations under this TC. Are you sure you want to edit this TC?")
            if (confirmation) {
                setVisibility(true)
            }
            return
        }
        if (tcData?.meter?.ctRatio?.primaryCurrent != state.primaryCurrent
            || tcData?.meter?.ctRatio?.secondaryCurrent !== state.secondaryCurrent
            || tcData?.feeder.id !== state.feederId) {
            setVisibility(true)
            return
        }
        handleEditTC(auditMonthYear)
    }

    const handleEditTC = async (monthYear) => {
        try {
            setIsDisabled(true)
            const data = editTCValidator(state, initialState)
            if (Object.keys(data).length === 0) throw ("You haven't changed any fields.")
            const response = await editTC(data, props.tcData.id, monthYear)
            notifier.notifySuccess(response.message)
            setIsDisabled(false)
            props.onEdit()
            props.onCloseButton()
            return

        }
        catch (err) {
            notifier.notifyError(err)
            setIsDisabled(false)
        }

    }

    const readingMRHandler = (e) => {
        const value = e.currentTarget.value
        setState({
            ...state,
            readingMR: value
        })
    }

    return (
        <Fragment>
            <ConfirmationModal
                visible={visible}
                onModalClose={() => { setVisibility(false) }}
                okAction={handleEditTC}
                tcData={tcData}

            />
            <div className="row add-tc-info">
                <div className="col-md-12 mb-3 mt-3">
                    <div className="code-section">
                        <span>Energy Audit &nbsp;|</span>
                        <span className="add-new-tc-text"><a>&nbsp; Edit TC</a>
                        </span>
                    </div>
                    <hr className="horizontal-line" />
                </div>
            </div>

            <div className="row add-tc-info">
                <div className="col-md-6">
                    <div className="add-tc-title">
                        <h1 className="mb-0">Edit TC - {props?.tcData?.number ?? ""}</h1>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="close-mr">
                        <button onClick={onCloseButtonHandler}
                            type="button" className="btn btn-primary">
                            <img src={closeIcon} />
                            Close
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-4">
                    {activeScreen == "add_tc_form" && (
                        <div className="transformer-detail-form p-3 mb-3">

                            <div className="forms-input">
                                <div className="row custom-row">
                                    <div className="col-md-12 mb-3 mt-2">
                                        <div className="x-former-detail">
                                            <h3 className="mb-2">Transformer Details</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">Select MR Code</label>
                                        <MultiSelect
                                            options={MRs}
                                            value={state.taggedMRs}
                                            onChange={(value) => { setState({ ...state, taggedMRs: value, ...(value.length === 0 && { readingMR: "" }) }) }}
                                            disableSearch="true"
                                            placeholderText='Select MR Code'
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">TC Reading MR</label>
                                        <div className="reading-radio-btn">

                                            <div className="row">
                                                {
                                                    state.taggedMRs.length === 0 ?
                                                        <p>-----</p>
                                                        : state.taggedMRs.map(item => (
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-check mb-0 me-3" key={item.value}>
                                                                    <input
                                                                        checked={state.readingMR === item.value}
                                                                        onChange={readingMRHandler}
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        value={item.value}
                                                                        id="flexCheckDefault"
                                                                        style={{ width: 20, height: 20, marginRight: 10 }} />
                                                                    <label className="form-check-label" for="flexRadioDefault1">{item.label}</label>
                                                                </div>
                                                            </div>
                                                        ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">TC Number *</label>
                                        <input
                                            value={state.tcNumber}
                                            onChange={(e) => { handleStateChange('tcNumber', e) }}
                                            className="form-control"
                                            maxLength={10}
                                            type="text"
                                            placeholder="TC Number"
                                        />
                                    </div>

                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">TC Name *</label>
                                        <input
                                            value={state.tcName}
                                            onChange={(e) => { handleStateChange('tcName', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={500}
                                            placeholder="TC Name"
                                        />
                                    </div>

                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">Reading Day *</label>
                                        <select className="form-select" value={state.readingDay} aria-label="Reading Day" onChange={(e) => handleStateChange('readingDay', e)}>
                                            <option value="">Reading Day</option>
                                            {readingDay.map(item => (
                                                <option value={item} key={`${item}`}>{item}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">TC Serial Number</label>
                                        <input
                                            value={state.tcSerialNumber}
                                            onChange={(e) => { handleStateChange('tcSerialNumber', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={30}
                                            placeholder="TC Serial Number"
                                        />
                                    </div>

                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">TC Make</label>
                                        <input
                                            value={state.tcMake}
                                            onChange={(e) => { handleStateChange('tcMake', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={30}
                                            placeholder="TC Make"
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">TC Capacity</label>
                                        <select
                                            value={state.tcCapacity}
                                            onChange={(e) => { handleStateChange('tcCapacity', e) }}
                                            className="form-select"
                                            aria-label="TC Capacity">
                                            <option value="">TC Capacity</option>
                                            {tcCapacityOptions.map(item => (
                                                <option value={item.value} key={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">TIMS Code</label>
                                        <input
                                            value={state.timsCode}
                                            onChange={(e) => { handleStateChange('timsCode', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={30}
                                            placeholder="Tims Code"
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">DTLMS</label>
                                        <input
                                            value={state.dtlmsCode}
                                            onChange={(e) => { handleStateChange('dtlmsCode', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={30}
                                            placeholder="DTLMS"
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">DTR</label>
                                        <input
                                            value={state.dtrCode}
                                            onChange={(e) => { handleStateChange('dtrCode', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={30}
                                            placeholder="DTR"
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4 position-relative">
                                        <label className="form-label font-regular-400">GPS Location</label>
                                        <input
                                            className="form-control"
                                            value={`${state.gpsCoordinates.latitude}, ${state.gpsCoordinates.longitude}`}
                                            type="text"
                                            disabled
                                            style={{ background: 'white' }}
                                            placeholder="GPS Location"
                                        />
                                        <img className="img-fluid fa-compass"
                                            src={gpsIcon}
                                            onClick={setGPSCoordinates}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4 position-relative feeder-name-select">
                                        <label className="form-label font-regular-400">Execution Type *</label>
                                        <select
                                            value={state.executionType}
                                            onChange={(e) => { handleStateChange('executionType', e) }}
                                            className="form-select"
                                            aria-label="Execution Type">
                                            <option value="">Execution Type</option>
                                            {executionType.map(item => (
                                                <option value={item.value} key={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4 mb-4 position-relative">
                                        <label className="form-label font-regular-400">Feeder Search *</label>
                                        <div className="search-space position-relative me-auto top-feeder-search">
                                            <Select
                                                {...(state.feederNumber && { value: { label: state.feederNumber, value: state.feederId } })}
                                                loadOptions={handleFeederSearch}
                                                onChange={(option) => { option?.value ? setState({ ...state, feederId: option.value, feederNumber: "" }) : setState({ ...state, feederId: "", feederNumber: "" }) }}
                                                placeholder="Search Feeder Number"
                                                isSearchable
                                                isClearable
                                            />
                                        </div>

                                    </div>
                                    <hr className="divider" />
                                    {/* /////////////////////////////////////////////METER DETAILS////////////////////////////////// */}
                                    <div className="col-md-12 mb-3 mt-2">
                                        <div className="x-former-detail">
                                            <h3 className="mb-2">Meter Details *</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">Make *</label>
                                        <input
                                            value={state.meterMake}
                                            onChange={(e) => { handleStateChange('meterMake', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={30}
                                            placeholder="Make"
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400">Serial Number *</label>
                                        <input
                                            value={state.meterSerialNumber}
                                            onChange={(e) => { handleStateChange('meterSerialNumber', e) }}
                                            className="form-control"
                                            type="text"
                                            maxLength={30}
                                            placeholder="Serial Number"
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4"></div>
                                    <div className="col-md-8 mb-4">

                                        <label className="form-label font-regular-400">CT Ratio *</label>

                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-4">
                                                <input
                                                    value={state.primaryCurrent}
                                                    onChange={(e) => { handleStateChange('primaryCurrent', e) }}
                                                    className="form-control"
                                                    pattern="[0-9]*"
                                                    maxLength={10}
                                                    type="text"
                                                    placeholder="Primary Current" />
                                            </div>

                                            <div className="col-12 col-md-6 mb-4">
                                                <input
                                                    value={state.secondaryCurrent}
                                                    onChange={(e) => { handleStateChange('secondaryCurrent', e) }}
                                                    className="form-control"
                                                    pattern="[0-9]*"
                                                    maxLength={10}
                                                    type="text"
                                                    placeholder="Secondary Current" />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <label className="form-label font-regular-400"></label>
                                        <p>Meter Constant - {state.meterConstant}</p>
                                    </div>


                                    <div className="col-md-12 mt-3 text-end tc-submit-btn">
                                        <button
                                            onClick={submitHandler}
                                            disabled={isDisabled}
                                            type="submit"
                                            className="btn btn-primary custom-active"
                                        >
                                            Submit
                                            {'\t'}
                                            {isDisabled && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default EditTC;
