import { Auth } from 'aws-amplify';

export const fetchSectionAndDivisionCode = async (inputData) => {

    try {
        const response = await Auth.currentAuthenticatedUser()
        if (inputData === "sectionCode") return response.attributes?.['custom:sectionCode'] ?? " Error in fetching details"
        if (inputData === "subDivisionCode") return response.attributes?.["custom:subDivisionCode"] ?? " Error in fetching details"

    }
    catch (err) {
        return "Error in fetching details"
    }
}

export const fetchAEDetails = async (inputData) => {
    try {
        const response = await Auth.currentAuthenticatedUser()
        if (inputData === "name") return response.attributes?.name ?? " Error in fetching details"
        if (inputData === "sectionCodeName") return response.attributes?.['custom:sectionCode_name'] ?? " Error in fetching details"
        return "Error in Fetching Details!!"
    }
    catch (err) {
        return "Error in fetching details"
    }
}