import { API } from 'aws-amplify'



export const fetchAEProfileDetails = () => {
    const queryStringParameters = {        
    }
    return API.get('baseURL', `/ae/profile`, {
        queryStringParameters
    }
    )
}

export const updateProfileDetails = (body) => {
    return API.patch("baseURL", "/ae/profile", {
        body
    })
}

