import React, { useContext, useState, useEffect } from "react";
import bigLogo from "../../assets/img/big-menu-logo.png";
import smallLogo from "../../assets/img/mr-logo_54.png";
import account from "../../assets/img/account_circle.svg";
import logout from "../../assets/img/logout.svg";
import menuIcon from "../../assets/img/menu-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from 'aws-amplify';
import { AuditMonthContext } from "../../context/AuditMonthContext";
import { Link } from "react-router-dom";
import { fetchAEDetails } from "../../services/utils/meta";


const Header = (props) => {
  const { auditMonth, setAuditMonth } = useContext(AuditMonthContext)
  const [toggle, setToggle] = useState(false)
  const [aeName, setAEName] = useState("----")
  const [sectionCodeName, setSectionCodeName] = useState("----")


  const style = {
    display: 'block',
  };

  useEffect(() => {
    fetchHeaderDetails()
  }, [])

  const fetchHeaderDetails = async () => {
    const name = await fetchAEDetails("name")
    const sectionName = await fetchAEDetails("sectionCodeName")
    setAEName(name)
    setSectionCodeName(sectionName)

  }

  const handleSignout = async () => {
    try {
      const confirmation = window.confirm("Are you sure you want logout?");
      if (confirmation) {
        await Auth.signOut();
        localStorage.clear();
        props.signoutHandler()
      }
    } catch (error) {
      console.log('error signing out: ', error);
    }

  }

  return (
    <React.Fragment>
      {/* <header className="site-header"> */}
      <nav className="navbar navbar-expand-lg navbar-light main-navbar sticky-top bg-gray">
        <div className="container-fluid">
          <div
            className="custom-collapse navbar-collapse"
            id="navbarTogglerDemo01"
          >
            <a className="navbar-brand desktop-logo d-lg-block" href="#">
              <img
                src={bigLogo}
                alt="icon"
                className={`big-logo ${props.sideBarMinimized ? "import" : ""}`}
              />
              <img
                src={smallLogo}
                alt="icon"
                className={`small-logo ${props.sideBarMinimized ? "important" : ""
                  }`}
              />
            </a>
            <button
              type="button"
              onClick={props.sidebarHandler}
              className="slide-left me-auto"
            >
              <img className="img-fluid" src={menuIcon} alt="icon" />
            </button>
            <ul className="navbar-nav me-4">
              <li className="nav-item roboto font-regular-400">
                <span className="audit-text roboto font-medium-500">Audit Month</span>
                <DatePicker
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showThreeColumnMonthYearPicker
                  placeholderText="Select"
                  selected={auditMonth}
                  onChange={(date) => setAuditMonth(date)}
                />
              </li>
              <hr className="vertical-line" />
              <li className="nav-item dropdown user-profile-icon">
                <Link
                  onClick={() => setToggle(!toggle)}
                  className="nav-link dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  role="button"
                >
                  <span>
                    <b className="roboto font-medium-500">{aeName}</b>
                    <p className="mb-0 roboto font-regular-400">{sectionCodeName}</p>
                  </span>
                </Link>
                {toggle && (
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                    style={style}
                  >
                    <li>
                      <Link className="dropdown-item" onClick={() => setToggle(!toggle)} to="/profile">
                        <img className="img-fluid" src={account} alt="icon" />
                        Profile
                      </Link>
                    </li>
                    <li onClick={handleSignout}>
                      <a className="dropdown-item" href="">
                        <img className="img-fluid" src={logout} alt="icon" />
                        Sign out
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* </header> */}
    </React.Fragment>
  );
};

export default Header;
