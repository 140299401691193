import React from 'react';
import { Bar } from "react-chartjs-2";

const DTCEnergyAuditChart = ({ totalTCs=0, audited=0, unaudited=0 }) => {

  const energyAuditChartData = {
    labels: ["DTC Energy Audit",],
    datasets: [
      {
        label: "Total TCs",
        backgroundColor: "rgba(59, 31, 237, 1)",
        borderColor: "rgba(156, 39, 176, 1)",
        borderWidth: 0,
        data: [totalTCs],
      },
      {
        label: "TC Audited",
        backgroundColor: "rgba(156, 39, 176, 1)",
        borderColor: "rgba(59, 31, 237, 1)",
        borderWidth: 0,
        data: [audited],
      },
      {
        label: "TC Unaudited",
        backgroundColor: "rgba(216, 27, 96, 1)",
        borderColor: "rgba(59, 31, 237, 1)",
        borderWidth: 0,
        data: [unaudited],
      }
    ],
  };

  return <Bar
    data={energyAuditChartData}
    options={{
      elements: {
        bar: {
          borderWidth: 2,
          borderColor: "#D2DDEC"
        },
        point: {
          hoverRadius: 0
        },
      },
      scales: {
        y: {
          display: false
        },
        x: {
          display: false,
          ticks: {
            beginAtZero: true
          }
        }
      },
      plugins: {
        legend: {
          position: "bottom",
          display: true,
          align: "start",
        },
        title: {
          display: false,
          text: "Chart.js Doughnut Chart",
        },
        tooltips: {
          enabled: true,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    }}
  />
}

export default DTCEnergyAuditChart;