import dayjs from "dayjs";


export const DateToDDMMYYYY = (date = new Date()) => {
    return (
        dayjs(date).format("DD/MM/YYYY")
    )
}

export const DateToYYYYMM = (date = new Date()) => {
    return (
        dayjs(date).format("YYYY-MM")
    )
}

export const fetchNextMonth = (date) => {
    if (date.getMonth() === 11) {
        return new Date(date.getFullYear() + 1, 0, 1);
    } else {
        return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }
}