import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import coverImage from '../../assets/img/login.svg';
import { Auth } from 'aws-amplify';
import { NotifierContext } from '../../context/NotifierContext';
import editIcon from '../../assets/img/view-selected-icon@2x.png';
import Modal from '../../components/atoms/Modal'

const Login = (props) => {

  const [formFields, setFormFields] = useState({ section_code: '', password: '' });
  const [isFormValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [modalVisible, setModalVisibility] = useState(false);
  const [newPwd, setNewPwd] = useState("")
  const [user, setUser] = useState("")


  const notifier = useContext(NotifierContext)

  useEffect(() => {
    /** Debouncing */
    const timer = setTimeout(() => {
      let checkForm = true;
      for (const [key, value] of Object.entries(formFields)) {
        if (validateField(key) !== '') {
          checkForm = false;
        }
      }
      setFormValid(checkForm);
    }, 200);
    return () => {
      clearTimeout(timer);
    }
  }, [formFields]);

  const validateField = (name) => {
    let error = '';
    /** Generic test */
    error = formFields[name].length == 0 ? 'Please enter valid value' : '';
    return error;
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!isFormValid) return;
    try {
      setLoading(true)
      const response = await Auth.signIn(formFields.section_code, formFields.password);
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(response)
        setLoading(false)
        setModalVisibility(true)
        return
      }
      notifier.notifySuccess("Successfully Logged In")
      localStorage.setItem('refreshToken', response.signInUserSession.refreshToken.token)
      props.signInSuccess();
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      notifier.notifyError(error)
      setLoading(false)
    }

  }

  //Function to handle for password change for first-time users
  const handlePwdChange = async () => {
    if (newPwd.length == 0) {
      notifier.notifyError("Please Enter new password")
      return;
    }
    if (!newPwd.match(/^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/)) {
      notifier.notifyError("New Password should have 1 uppercase, 1 lowercase letter with minimum length 8")
      return;
    }
    try {
      setLoading(true)
      const response = await Auth.completeNewPassword(user, newPwd, []);
      localStorage.setItem('refreshToken', response.signInUserSession.refreshToken.token)
      props.signInSuccess();
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="unauth-main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-5 pe-0 ps-0">
              <div className="login-left-image">
                <img className="img-fluid" src={coverImage} />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 pe-0 ps-0">
              <div className="login-form-detail">
                <div className="welcome-title text-center mb-5">
                  <h4>Sign In</h4>
                </div>
                <form action="" method="">
                  <div className="mb-3 forms-input">
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                    <label className="mb-2">Section Code</label>
                    <input type="text" onChange={handleChange} value={formFields['section_code']} placeholder="Section Code" name="section_code" className="form-control" />
                  </div>
                  <div className="mb-3 forms-input">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <label className="mb-2">Password</label>
                    <input onChange={handleChange} type={showPassword ? "text" : "password"} value={formFields['password']} placeholder="Password" name="password" className="form-control" />
                    <img src={editIcon} style={{ width: 22, height: 22, position: 'absolute', right: 18, bottom: 15, cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                  </div>
                  <div className="mb-4 form-check remember-forgotten ps-0">
                    <div className="form-check me-auto">
                      {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                      {/* <label className="form-check-label" htmlFor="flexCheckDefault">Remember me</label> */}
                    </div>
                    <div className="forgot">
                      <Link to="/forgot-password">Forgot Password</Link>
                    </div>
                  </div>
                  <button type="submit" onClick={submitHandler} disabled={loading} className={`btn btn-primary mb-3 ${(isFormValid) ? 'custom-active' : ''}`}>
                    LOGIN{'\t'}
                    {loading && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                  </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        okAction={handlePwdChange}
        okBtnDisabled={loading || !newPwd}
        okBtnLoading={loading}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        title="Change your password">
        <div className="login-form-detail" style={{ padding: 10, margin: 0 }}>
          <div className="mb-3 forms-input">
            <i className="fa fa-lock" aria-hidden="true"></i>
            <label className="mb-2" style={{ fontSize: 15 }}>Enter New Password</label>
            <input onChange={(e) => setNewPwd(e.target.value)} type={showPassword ? "text" : "password"} value={newPwd} placeholder="Password" name="password" className="form-control" />
            <img src={editIcon} style={{ width: 22, height: 22, position: 'absolute', right: 18, bottom: 15, cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default Login;