import React, { useEffect, useState, useContext } from 'react';
import Filter from '../../Shared/Filter';
// import Modal from '../../Shared/Modal';
import "react-datepicker/dist/react-datepicker.css";
import filterIcon from '../../../assets/img/filter.png';
import excelIcon from '../../../assets/img/export-dark-icon.png';
import BillingTabSearch from '../Shared/BillingTabSearch';
import TagTCModal from '../../Shared/TagTCModal';
import { getInstallations, getInstallationsByMrCodeAndReadingDay, filterInstallations, getTCs, tagInstallation, exportInstallations } from '../../../services/api/billingService'
import { AuditMonthContext } from '../../../context/AuditMonthContext';
import { NotifierContext } from '../../../context/NotifierContext';
import NoDataFound from '../../../components/atoms/NoDataFound'
import Paginator from '../../../components/molecules/Paginator';
import Ellipsis from '../../../components/molecules/Ellipsis'
import { perPageCount } from '../../../assets/offlineData/apiCallDetails'
import { installationsTariffs, remarks } from '../../../assets/offlineData/FilterOptions'
import { Colors } from '../../../theme/colors';
import { clearTime } from '../../../services/utils/setTimeOut'
import Spinner from '../../../components/atoms/Spinner';

const Table = () => {

  const [loading, setLoading] = useState(true);
  const [searchRRNumber, setSearchRRNumber] = useState("")

  /////////////////Tag TC Modal//////////////////
  const [modalVisible, setModalVisibility] = useState(false);
  const [loadingTc, setLoadingTc] = useState(false)
  const [selectedTC, setSelectedTC] = useState([])
  const [selectedInstallation, setSelectedInstallation] = useState([])
  const [tagTcBtnDisabled, setTagTcBtnDisabled] = useState(false)
  const [TCs, setTCs] = useState([])


  ////////////////////////////////////////////////Filter States///////////////////////////////////////////
  const [selectedMR, setSelectedMR] = useState("");
  const [selectedReadingDay, setSelectedReadingDay] = useState("");

  const [filterVisible, setFilterVisible] = useState(false);
  const [remark, setRemark] = useState([])
  const [tariff, setTariff] = useState([])

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [exportLoading, setExportLoading] = useState(false)
  const [installationsCount, setInstallationsCount] = useState('')
  const [installationData, setInstallationData] = useState([])
  const [pageData, setPageData] = useState({
    currentPage: 0,
    nextPage: 1,
    previousPage: 1,
    lastPage: 0
  })


  const { auditMonthYear, mrCode } = useContext(AuditMonthContext)
  const notifier = useContext(NotifierContext);

  const filterHandler = (event) => {
    event.preventDefault();
    setFilterVisible(!filterVisible);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchMyInstallations(1)
    }, clearTime);
    return () => {
      clearTimeout(timer);
    }
  }, [auditMonthYear, searchRRNumber])


  const fetchMyInstallations = async (pageNumber) => {
    try {
      setLoading(true)
      const response = await getInstallations(auditMonthYear, pageNumber, false, searchRRNumber, selectedReadingDay, selectedMR, tariff, remark);
      setInstallationData(response.data.installations);
      setInstallationsCount(response.data.count)
      setPageData({
        currentPage: response.data.pageData.currentPage,
        nextPage: response.data.pageData.nextPage,
        previousPage: response.data.pageData.previousPage,
        lastPage: response.data.pageData.lastPage,
      })
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const searchByMrCodeAndReadingDay = async () => {
    if (!selectedMR && !selectedReadingDay) {
      notifier.notifyError("Please select MR code or Reading day")
      return
    }
    fetchMyInstallations(1)
  }

  const applyFilters = async () => {
    fetchMyInstallations(1)
    setFilterVisible(false)
  }

  const resetFilter = async () => {
    try {
      setTariff([])
      setRemark([])
      setLoading(true)
      const response = await getInstallations(auditMonthYear, 1, false, searchRRNumber, selectedReadingDay, selectedMR, [], [], []);
      setInstallationData(response.data.installations);
      setInstallationsCount(response.data.count)
      setPageData({
        currentPage: response.data.pageData.currentPage,
        nextPage: response.data.pageData.nextPage,
        previousPage: response.data.pageData.previousPage,
        lastPage: response.data.pageData.lastPage,
      })
      setLoading(false)
      setFilterVisible(false)

    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const onClearMrCodeAndReadingDay = async () => {
    try {
      setSelectedMR('')
      setSelectedReadingDay('')
      setLoading(true)
      const response = await getInstallations(auditMonthYear, 1, false, searchRRNumber, "", "", tariff, remark);
      setInstallationData(response.data.installations);
      setInstallationsCount(response.data.count)
      setPageData({
        currentPage: response.data.pageData.currentPage,
        nextPage: response.data.pageData.nextPage,
        previousPage: response.data.pageData.previousPage,
        lastPage: response.data.pageData.lastPage,
      })
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }

  }

  const handleExport = async () => {
    try {
      setExportLoading(true)
      notifier.notifySuccess("Preparing for download. It may take upto 30sec. Please wait...")
      const response = await exportInstallations(auditMonthYear, false, searchRRNumber, selectedReadingDay, selectedMR, tariff, remark)
      window.location.href = response.data
      notifier.notifySuccess(`Download started.`)
      setExportLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setExportLoading(false)
    }

  }


  const fetchTCByNumber = async (readingDay, MRCode) => {
    try {
      setLoadingTc(true)
      const response = await getTCs(readingDay, 1, 100, auditMonthYear, '', MRCode)
      setTCs(response.data.transformers)
      setLoadingTc(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoadingTc(false)
    }
  }

  const tcTag = async () => {
    setTagTcBtnDisabled(true)

    if (selectedTC.length <= 0) {
      notifier.notifyError("Please select tc to tag")
      return
    }

    try {
      const response = await tagInstallation(selectedTC.id, selectedInstallation.id, auditMonthYear)
      notifier.notifySuccess("Successfully Tagged TC")
      setModalVisibility(false);
      fetchMyInstallations(1)
      setTagTcBtnDisabled(false)

    }
    catch (err) {
      notifier.notifyError(err)
      setTagTcBtnDisabled(false)
    }
  }

  const filterData = [
    {
      heading: "Tariff",
      shape: "square",
      options: [
        ...installationsTariffs
      ],
      value: tariff,
      setValue: (selectedValue) => { tariff.includes(selectedValue) ? setTariff(tariff.filter(item => item !== selectedValue)) : setTariff([...tariff, selectedValue]) }
    },
    {
      heading: "Remark",
      shape: "rounded",
      options: [
        ...remarks.myInstallations
      ],
      value: remark,
      setValue: (selectedValue) => { remark.includes(selectedValue) ? setRemark(remark.filter(item => item !== selectedValue)) : setRemark([...remark, selectedValue]) }
    }
  ]

  return (
    <React.Fragment>
      <Filter
        filterData={filterData}
        isFilterVisible={filterVisible}
        disableApply={(tariff.length >= 1 || remark.length >= 1) ? false : true}
        onFilterClose={filterHandler}
        onFiltersApply={() => applyFilters()}
        onResetApply={resetFilter}
      />
      <TagTCModal
        btnDisable={tagTcBtnDisabled}
        options={TCs}
        loadingTc={loadingTc}
        selectedTC={selectedTC}
        onSelectTC={setSelectedTC}
        isModalVisible={modalVisible}
        onModalClose={(event) => {
          event.preventDefault();
          setModalVisibility(!modalVisible);
        }}
        onPressOK={() => tcTag()}
      />
      <div className="col-md-9">
        <div className="row mt-35">
          <div className="col-md-12">
            <div className="comman-head">
              <div className="installation-title">
                <h1>My Installations</h1>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="main-tc-name-form mb-3">
              <BillingTabSearch
                MRs={mrCode}
                selectedMR={selectedMR}
                showClearButton={selectedMR || selectedReadingDay ? true : false}
                onClear={onClearMrCodeAndReadingDay}
                setSelectedMR={setSelectedMR}
                selectedReadingDay={selectedReadingDay}
                setSelectedReadingDay={setSelectedReadingDay}
                onSearch={() => searchByMrCodeAndReadingDay()}
              />
              <div className="row">
                <div className="col-md-12">
                  <div className="list-view-box">
                    <div className="search-heading">
                      <div className="digit-installation-title mb-3">
                        <h3 className="mb-4 ms-0 font-medium-500">
                          {installationsCount} Installations</h3>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="search-space">
                            <input
                              type="text"
                              maxLength="25"
                              placeholder="RR Number"
                              value={searchRRNumber}
                              onChange={(e) => { setSearchRRNumber(e.target.value) }}
                              className="form-control search" />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 text-right">
                          <div className="export-filter">
                            <a onClick={handleExport} className="export-btn">
                              {exportLoading ?
                                <Spinner />
                                : <>
                                  <img className="fa img-fluid" src={excelIcon} /> &nbsp; <span>Export to CSV</span></>}
                            </a>
                            <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
                              <img src={filterIcon} className="" width="18" height="12" />Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="pl-15" scope="col">#</th>
                            <th scope="col">MR Code</th>
                            <th scope="col">Reading Day</th>
                            <th scope="col">Account ID</th>
                            <th scope="col">RR Number</th>
                            <th scope="col">Tariff</th>
                            <th scope="col">Consumer Name</th>
                            <th scope="col">Sanction Load</th>
                            <th scope="col">TC Number</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {
                            loading ? <NoDataFound loading={loading} /> : installationData.length === 0 && <NoDataFound message="No Installations Found!" />
                          }
                          {
                            !loading &&
                            installationData.map((installation, index) => (
                              <tr key={index}>
                                <td className="pl-15" scope="row">{((pageData.currentPage - 1) * perPageCount) + (index + 1)}</td>
                                <td>{installation?.MR?.mrCode ?? "----"}</td>
                                <td>{installation.readingDay || "----"}</td>
                                <td>{installation.accountId ?? "----"}</td>
                                <td>{installation.RRNumber ?? "----"}</td>
                                <td>{installation.tariff?.short ?? "----"}</td>
                                <Ellipsis>
                                  {'consumerName' in installation ? installation.consumerName : '-'}
                                </Ellipsis>
                                <td>{installation.sanctionedLoad.kW} KW, {installation?.sanctionedLoad?.hp ?? "--"} HP</td>
                                {'transformer' in installation ?
                                  <td>
                                    <a
                                      onClick={(event) => {
                                        event.preventDefault()
                                        fetchTCByNumber(installation?.readingDay, installation.MR?.mrCode)
                                        setModalVisibility(true);
                                        setSelectedTC(installation.transformer)
                                        setSelectedInstallation(installation)
                                      }
                                      }
                                      href="#" className="tc-number"
                                      style={{ backgroundColor: Colors.primaryColor }}><span className="active">TC-{installation.transformer.number}</span></a>
                                  </td>
                                  :
                                  <td>
                                    <a onClick={(event) => {
                                      event.preventDefault()
                                      fetchTCByNumber(installation?.readingDay, installation?.MR?.mrCode)
                                      setModalVisibility(true);
                                      setSelectedTC([])
                                      setSelectedInstallation(installation)
                                    }
                                    } href="#" className="tc-number" style={{ backgroundColor: Colors.secondaryColor }}><span className="active">Tag-TC</span></a>
                                  </td>
                                }
                              </tr>
                            ))
                          }

                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav className="float-end" aria-label="Page navigation example">

          <Paginator
            currentPage={pageData.currentPage}
            lastPage={pageData.lastPage}
            // onPressNext={() => { setPageData(prevState => prevState.currentPage >= pageData.lastPage ? prevState : { ...pageData, currentPage: pageData.currentPage + 1 }) }}
            onPressNext={() => { fetchMyInstallations(pageData.currentPage + 1) }}
            onPressPrevious={() => { fetchMyInstallations(pageData.currentPage - 1) }}
          />
        </nav>

      </div>
    </React.Fragment>
  );
}

export default Table;



































// import React, { useEffect, useState, useContext } from 'react';
// import Filter from '../../Shared/Filter';
// // import Modal from '../../Shared/Modal';
// import "react-datepicker/dist/react-datepicker.css";
// import filterIcon from '../../../assets/img/filter.png';
// import excelIcon from '../../../assets/img/export-dark-icon.png';
// import BillingTabSearch from '../Shared/BillingTabSearch';
// import TagTCModal from '../../Shared/TagTCModal';
// import { getInstallations, getInstallationsByMrCodeAndReadingDay, filterInstallations, getTCs, tagInstallation } from '../../../services/api/billingService'
// import { AuditMonthContext } from '../../../context/AuditMonthContext';
// import { NotifierContext } from '../../../context/NotifierContext';
// import NoDataFound from '../../../components/atoms/NoDataFound'
// import Paginator from '../../../components/molecules/Paginator';
// import Ellipsis from '../../../components/molecules/Ellipsis'
// import { perPageCount } from '../../../assets/offlineData/apiCallDetails'
// import { installationsTariffs, remarks } from '../../../assets/offlineData/FilterOptions'
// import { Colors } from '../../../theme/colors';
// import { clearTime } from '../../../services/utils/setTimeOut'

// const Table = () => {

//   const [loading, setLoading] = useState(false);
//   const [searchRRNumber, setSearchRRNumber] = useState("")

//   /////////////////Tag TC Modal//////////////////
//   const [tcReadingDay, setTcReadingDay] = useState("")
//   const [modalVisible, setModalVisibility] = useState(false);
//   const [loadingTc, setLoadingTc] = useState(false)
//   const [selectedTC, setSelectedTC] = useState([])
//   const [selectedInstallation, setSelectedInstallation] = useState([])
//   const [tagTcBtnDisabled, setTagTcBtnDisabled] = useState(false)
//   const [TCs, setTCs] = useState([])


//   ////////////////////////////////////////////////Filter States///////////////////////////////////////////
//   const [selectedMR, setSelectedMR] = useState("");
//   const [selectedReadingDay, setSelectedReadingDay] = useState("");

//   const [filterVisible, setFilterVisible] = useState(false);
//   const [remark, setRemark] = useState([])
//   const [tariff, setTariff] = useState([])

//   ////////////////////////////////////////////////////////////////////////////////////////////////////////
//   const [installationsCount, setInstallationsCount] = useState('')
//   const [installationData, setInstallationData] = useState([])
//   const [pageData, setPageData] = useState({
//     currentPage: 1,
//     nextPage: 1,
//     previousPage: 1,
//     lastPage: 1
//   })


//   const { auditMonthYear, mrCode } = useContext(AuditMonthContext)
//   const notifier = useContext(NotifierContext);

//   const filterHandler = (event) => {
//     event.preventDefault();
//     setFilterVisible(!filterVisible);
//   }

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       searchRRNumber.length >= 1 ? searchRRNumber.match(/^[A-Za-z0-9]*$/) && fetchMyInstallations() :
//         !(tariff.length >= 1 || remark.length >= 1) &&
//         !(selectedMR || selectedReadingDay) &&
//         searchRRNumber.length == 0 && fetchMyInstallations()
//     }, clearTime);
//     return () => {
//       clearTimeout(timer);
//     }
//   }, [searchRRNumber, pageData.currentPage, auditMonthYear])

//   useEffect(() => {
//     fetchTCByNumber()
//   }, [tcReadingDay, selectedInstallation, selectedMR])

//   const fetchMyInstallations = async () => {
//     setSelectedMR("")
//     setSelectedReadingDay("")
//     setLoading(true)
//     try {
//       setLoading(true)
//       const response = await getInstallations(auditMonthYear, pageData.currentPage, false, searchRRNumber);
//       setInstallationData(response.data.installations);
//       setInstallationsCount(response.data.count)
//       const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
//       setPageData({
//         ...pageData,
//         currentPage: currentPage && currentPage,
//         nextPage: nextPage && nextPage,
//         previousPage: previousPage && previousPage,
//         lastPage: lastPage && lastPage,
//       })
//       setLoading(false)
//     }
//     catch (err) {
//       notifier.notifyError(err)
//       setLoading(false)
//     }
//   }

//   const searchByMrCodeAndReadingDay = async () => {
//     if (!selectedMR && !selectedReadingDay) {
//       notifier.notifyError("Please select Mr code or Reading day")
//       return
//     }
//     setLoading(true)
//     setSearchRRNumber("")
//     setRemark([])
//     setTariff([])
//     try {
//       setLoading(true)
//       const response = await getInstallationsByMrCodeAndReadingDay(selectedReadingDay, auditMonthYear, pageData.currentPage, false, selectedMR);
//       setInstallationData(response.data.installations);
//       setInstallationsCount(response.data.count)
//       const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
//       setPageData({
//         ...pageData,
//         currentPage: currentPage && currentPage,
//         nextPage: nextPage && nextPage,
//         previousPage: previousPage && previousPage,
//         lastPage: lastPage && lastPage,
//       })
//       setLoading(false)
//     }
//     catch (err) {
//       notifier.notifyError(err)
//       setLoading(false)
//     }
//   }

//   const applyFilters = async () => {
//     setLoading(true)
//     setSelectedMR("")
//     setSelectedReadingDay("")
//     await setPageData({ ...pageData, currentPage: 1 })
//     try {
//       const response = await filterInstallations(auditMonthYear, pageData.currentPage, '', tariff, false, remark);
//       setInstallationData(response.data.installations);
//       setInstallationsCount(response.data.count)
//       const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
//       setPageData({
//         ...pageData,
//         currentPage: currentPage && currentPage,
//         nextPage: nextPage && nextPage,
//         previousPage: previousPage && previousPage,
//         lastPage: lastPage && lastPage,
//       })
//       setFilterVisible(false)
//       setLoading(false)
//     }
//     catch (err) {
//       notifier.notifyError(err)
//       setLoading(false)
//     }
//   }

//   const resetFilter = async () => {
//     setTariff([])
//     setRemark([])
//     fetchMyInstallations()
//   }

//   const onClearMrCodeAndReadingDay = () => {
//     setSelectedMR('')
//     setSelectedReadingDay('')
//     fetchMyInstallations()
//   }


//   const fetchTCByNumber = async () => {
//     setLoadingTc(true)
//     try {
//       const response = await getTCs(tcReadingDay, 1, 100, auditMonthYear, '', selectedMR)
//       setTCs(response.data.transformers)
//       setLoadingTc(false)
//     }
//     catch (err) {
//       notifier.notifyError(err)
//       setLoadingTc(false)
//     }
//   }

//   const tcTag = async () => {
//     setTagTcBtnDisabled(true)

//     if (selectedTC.length <= 0) {
//       notifier.notifyError("Please select tc to tag")
//       return
//     }

//     try {
//       const response = await tagInstallation(selectedTC.id, selectedInstallation.id)
//       setSelectedTC("")
//       notifier.notifySuccess("Successfully Tagged TC")
//       setModalVisibility(false);
//       fetchMyInstallations()
//       setTagTcBtnDisabled(false)

//     }
//     catch (err) {
//       notifier.notifyError(err)
//       setTagTcBtnDisabled(false)
//     }
//   }

//   const filterData = [
//     {
//       heading: "Tariff",
//       shape: "square",
//       options: [
//         ...installationsTariffs
//       ],
//       value: tariff,
//       setValue: (selectedValue) => { tariff.includes(selectedValue) ? setTariff(tariff.filter(item => item !== selectedValue)) : setTariff([...tariff, selectedValue]) }
//     },
//     {
//       heading: "Remark",
//       shape: "rounded",
//       options: [
//         ...remarks.myInstallations
//       ],
//       value: remark,
//       setValue: (selectedValue) => { remark.includes(selectedValue) ? setRemark(remark.filter(item => item !== selectedValue)) : setRemark([...remark, selectedValue]) }
//     }
//   ]

//   return (
//     <React.Fragment>
//       <Filter
//         filterData={filterData}
//         isFilterVisible={filterVisible}
//         disableApply={(tariff.length >= 1 || remark.length >= 1) ? false : true}
//         onFilterClose={filterHandler}
//         onFiltersApply={() => applyFilters()}
//         onResetApply={resetFilter}
//       />
//       <TagTCModal
//         btnDisable={tagTcBtnDisabled}
//         options={TCs}
//         loadingTc={loadingTc}
//         selectedTC={selectedTC}
//         onSelectTC={setSelectedTC}
//         isModalVisible={modalVisible}
//         onModalClose={(event) => {
//           event.preventDefault();
//           setModalVisibility(!modalVisible);
//         }}
//         onPressOK={() => tcTag()}
//       />
//       <div className="col-md-9">
//         <div className="row mt-35">
//           <div className="col-md-12">
//             <div className="comman-head">
//               <div className="installation-title">
//                 <h1>My Installations</h1>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-12">
//             <div className="main-tc-name-form mb-3">
//               <BillingTabSearch
//                 MRs={mrCode}
//                 selectedMR={selectedMR}
//                 showClearButton={selectedMR || selectedReadingDay ? true : false}
//                 onClear={onClearMrCodeAndReadingDay}
//                 setSelectedMR={setSelectedMR}
//                 selectedReadingDay={selectedReadingDay}
//                 setSelectedReadingDay={setSelectedReadingDay}
//                 onSearch={() => searchByMrCodeAndReadingDay()}
//               />
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="list-view-box">
//                     <div className="search-heading">
//                       <div className="digit-installation-title mb-3">
//                         <h3 className="mb-4 ms-0 font-medium-500">
//                           {installationsCount} Installations</h3>
//                       </div>
//                       <div className="row">
//                         <div className="col-12 col-md-6">
//                           <div className="search-space">
//                             <input
//                               type="text"
//                               maxLength="25"
//                               placeholder="RR Number"
//                               value={searchRRNumber}
//                               onChange={(e) => { setSearchRRNumber(e.target.value); setPageData({ ...pageData, currentPage: 1 }) }}
//                               className="form-control search" />
//                           </div>
//                         </div>
//                         <div className="col-12 col-md-6 text-right">
//                           <div className="export-filter">
//                             <a onClick={() => { }} className="export-btn">
//                               <img className="fa img-fluid" src={excelIcon} />&nbsp; <span>Export to Excel</span>
//                             </a>
//                             <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
//                               <img src={filterIcon} className="" width="18" height="12" />Filter
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="table-responsive">
//                       <table className="table">
//                         <thead>
//                           <tr>
//                             <th className="pl-15" scope="col">#</th>
//                             <th scope="col">MR Code</th>
//                             <th scope="col">Reading Day</th>
//                             <th scope="col">Account ID</th>
//                             <th scope="col">RR Number</th>
//                             <th scope="col">Tariff</th>
//                             <th scope="col">Consumer Name</th>
//                             <th scope="col">Sanction Load</th>
//                             <th scope="col">TC Number</th>
//                           </tr>
//                         </thead>
//                         <tbody className="text-center">
//                           {
//                             loading ? <NoDataFound colSpan={12} loading /> : installationData.length === 0 && <NoDataFound colSpan={12} loading={loading} />
//                           }
//                           {
//                             !loading &&
//                             installationData.map((installation, index) => (
//                               <tr key={index}>
//                                 <td className="pl-15" scope="row">{((pageData.currentPage - 1) * perPageCount) + (index + 1)}</td>
//                                 <td>{installation.MR.mrCode}</td>
//                                 <td>{installation.readingDay}</td>
//                                 <td>{installation.accountId}</td>
//                                 <td>{installation.RRNumber}</td>
//                                 <td>{installation.tariff.short}</td>
//                                 <Ellipsis>
//                                   {'consumerName' in installation ? installation.consumerName : '-'}
//                                 </Ellipsis>
//                                 <td>{installation.sanctionedLoad.kW} KW, {installation?.sanctionedLoad?.hp ?? "--"} HP</td>
//                                 {'transformer' in installation ?
//                                   <td>
//                                     <a
//                                       onClick={(event) => {
//                                         event.preventDefault()
//                                         setTcReadingDay(installation?.readingDay)
//                                         setModalVisibility(true);
//                                         setSelectedTC(installation)
//                                         setSelectedInstallation(installation)
//                                       }
//                                       }
//                                       href="#" className="tc-number"
//                                       style={{ backgroundColor: Colors.primaryColor }}><span className="active">TC-{installation.transformer.number}</span></a>
//                                   </td>
//                                   :
//                                   <td>
//                                     <a onClick={(event) => {
//                                       event.preventDefault()
//                                       setTcReadingDay(installation?.readingDay)
//                                       setModalVisibility(true);
//                                       setSelectedTC([])
//                                       setSelectedInstallation(installation)
//                                     }
//                                     } href="#" className="tc-number" style={{ backgroundColor: Colors.secondaryColor }}><span className="active">Tag-TC</span></a>
//                                   </td>
//                                 }
//                               </tr>
//                             ))
//                           }

//                         </tbody>
//                       </table>
//                     </div>

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <nav className="float-end" aria-label="Page navigation example">

//           <Paginator
//             currentPage={pageData.currentPage}
//             lastPage={pageData.lastPage}
//             // onPressNext={() => { setPageData(prevState => prevState.currentPage >= pageData.lastPage ? prevState : { ...pageData, currentPage: pageData.currentPage + 1 }) }}
//             onPressNext={() => { setPageData({ ...pageData, currentPage: pageData.currentPage + 1 }) }}
//             onPressPrevious={() => { setPageData({ ...pageData, currentPage: pageData.currentPage - 1 }) }}
//           />
//         </nav>

//       </div>
//     </React.Fragment>
//   );
// }

// export default Table;