const Ajv = require("ajv").default
const ajv = new Ajv({ allErrors: true })
require("ajv-errors")(ajv, { singleError: true }
)
require("ajv-keywords")(ajv, ["transform"])

export const addFeederValidation = ({ name, mdm_code, number }) => {
    const dataFormatter = {
        name,
        number,
        mdmCode: mdm_code
    }
    // const schema = {
    //     title: 'AddFeeder',
    //     description: 'Create Feeder object',
    //     type: 'object',
    //     required: ['number', 'mdmCode'],
    //     additionalProperties: false,
    //     properties: {
    //         name: {
    //             type: 'string',
    //             pattern: '^[A-Za-z -]+$',
    //             transform: ['trim'],
    //             minLength: 3,
    //             maxLength: 30,
    //             errorMessage: {
    //                 type: 'Feeder name should only include alphabets, space and hyphen',
    //                 pattern:
    //                     'Feeder name should only include alphabets, space and hyphen',
    //                 minLength: 'Minimum length of feeder name is 2',
    //                 maxLength: 'Maximum length of feeder name can be 30',
    //             },
    //         },
    //         number: {
    //             type: 'string',
    //             pattern: '^[a-zA-Z0-9]*$',
    //             transform: ['trim'],
    //             minLength: 2,
    //             maxLength: 30,
    //             errorMessage: {
    //                 type: 'Feeder number should only include alphabets and numbers',
    //                 pattern:
    //                     'Feeder number should only include alphabets and numbers',
    //                 minLength: 'Minimum length of feeder number is 2',
    //                 maxLength: 'Maximum length of feeder number can be 30',
    //             },
    //         },
    //         mdmCode: {
    //             type: 'string',
    //             pattern: '^[0-9]*$',
    //             transform: ['trim'],
    //             minLength: 3,
    //             maxLength: 30,
    //             errorMessage: {
    //                 type: 'Feeder mdmCode should only include numbers',
    //                 pattern: 'Feeder mdmCode should only include numbers',
    //                 minLength: 'Minimum length of feeder mdmCode is 3',
    //                 maxLength: 'Maximum length of feeder mdmCode can be 30',
    //             },
    //         },
    //     },
    // };

    // const validation = ajv.compile(schema)
    // const isValid = validation(dataFormatter)
    // if (isValid) 
    return dataFormatter
    // throw (validation.errors[0])

}

export const addPowerStationValidation = ({ stationCode, voltageClass, stationName }) => {
    const dataFormatter = {
        ...(stationName && { name: stationName }),
        ...(voltageClass && { voltageClass: Number(voltageClass) }),
        stationCode
    }

    // const schema = {
    //     title: 'AddPowerStation',
    //     description: 'Create power-station object',

    //     type: 'object',

    //     required: ['stationCode','name'],
    //     additionalProperties: false,
    //     properties: {
    //         name: {
    //             type: 'string',
    //             pattern: '^[A-Za-z -]+$',
    //             transform: ['trim'],
    //             minLength: 3,
    //             maxLength: 30,
    //             errorMessage: {
    //                 type: 'Power station name should only include alphabets, space and hyphen',
    //                 pattern:
    //                     'Power station name should only include alphabets, space and hyphen',
    //                 minLength: 'Minimum length of power station name is 2',
    //                 maxLength: 'Maximum length of power station name can be 30',
    //             },
    //         },
    //         stationCode: {
    //             type: 'string',
    //             pattern: '^[0-9]*$',
    //             transform: ['trim'],
    //             minLength: 3,
    //             maxLength: 30,
    //             errorMessage: {
    //                 type: ' Power Station Code should only include numbers',
    //                 pattern: ' Power Station Code should only include numbers',
    //                 minLength: 'Minimum length of stationCode is 3',
    //                 maxLength: 'Maximum length of stationCode can be 30',
    //             },
    //         },
    //         voltageClass: {
    //             type: 'integer',
    //             enum: [33, 66, 100, 220, 400],
    //             errorMessage: {
    //                 type: 'Voltage Class should be an integer',
    //                 enum: 'Value of Voltage Class should be one among these values : [33, 66, 100, 220, 400],',
    //             },
    //         },
    //     },
    //     errorMessage: {
    //         required: 'Missing mandatory properties',
    //         additionalProperties: 'No additional properties allowed',
    //         properties: {
    //             name: 'Invalid power-station name',
    //             stationCode: 'Invalid power-station stationCode',
    //             voltageClass: 'Invalid power-station voltageClass',
    //         },
    //     },
    // };

    // const validation = ajv.compile(schema)
    // const isValid = validation(dataFormatter)
    // if (isValid) 
    return dataFormatter
    // throw (validation.errors[0])
}