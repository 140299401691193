import React, { useState, useEffect } from 'react';
import coverImage from '../../assets/img/login.svg';

const ForgotPassword = (props) => {

  const [formFields, setFormFields] = useState({ phone: '' });
  const [isFormValid, setFormValid] = useState(false);

  useEffect(() => {
    /** Debouncing */
    const timer = setTimeout(() => {
      let checkForm = true;
      for (const [key, value] of Object.entries(formFields)) {
        if (validateField(key) !== '') {
          checkForm = false;
        }
      }
      setFormValid(checkForm);
    }, 200);
    return () => {
      clearTimeout(timer);
    }
  }, [formFields]);

  const validateField = (name) => {
    let error = '';
    /** Generic test */
    error = formFields[name].length == 0 ? 'Please enter valid value' : '';
    if (name === 'phone') {
      error = formFields[name].length != 10 || isNaN(formFields[name]) ? 'Phone Number Error' : '';
    }
    return error;
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!isFormValid) return;
    alert('Submit Handler');
  }

  return (
    <React.Fragment>
      <div className="unauth-main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-5 pe-0 ps-0">
              <div className="login-left-image">
                <img className="img-fluid" src={coverImage} />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 pe-0 ps-0">
              <div className="login-form-detail">
                <div className="welcome-title text-center mb-5">
                  <h4>Forgot Password</h4>
                  <span>Enter your phone number for reset password</span>
                </div>
                <form action="" method="">
                  <div className="mb-3 forms-input">
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                    <label className="mb-2">Phone Number</label>
                    <input type="text" onChange={handleChange} value={formFields['phone']} placeholder="Phone Number" name="phone" className="form-control" />
                  </div>
                  <button type="submit" onClick={submitHandler} className={`btn btn-primary mb-3 ${isFormValid ? 'custom-active' : ''}`}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;