import React, { useContext, useMemo, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BillingDetailsChart from "./BillingDetailsChart";
import CollectionDetailsChart from "./CollectionDetailsChart";
import DisconnectionDetailsChart from "./DisconnectionDetailsChart";
import DTCEnergyAuditChart from "./DTCEnergyAuditChart";
import './chart.scss'
import { NotifierContext } from "../../context/NotifierContext";
import { getBillingCount } from "../../services/api/billingService";
import { DateToYYYYMM } from "../../services/utils/date";
import { getAuditMetrics } from "../../services/api/tcAuditService";
import { getDisconnectionCount } from "../../services/api/dashboardService";

const Index = (props) => {

  const notifier = useContext(NotifierContext)

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  const [startDate, setStartDate] = useState(new Date());
  const [initialRender, setInitialRender] = useState(true);
  const [selectedCDMonth, setSelectedCDMonth] = useState(monthNames[new Date().getMonth()])
  const [selectedBDMonth, setSelectedBDMonth] = useState(monthNames[new Date().getMonth()])
  const [selectedDDMonth, setSelectedDDMonth] = useState(monthNames[new Date().getMonth()])
  const [selectedDTCMonth, setSelectedDTCMonth] = useState(monthNames[new Date().getMonth()])

  const [billingData, setBillingData] = useState([])
  const [collectionData, setCollectionData] = useState()
  const [disconnectionData, setDisconnectionData] = useState()
  const [DTCData, setDTCData] = useState({})

  useEffect(() => {
    setInitialRender(false)
    Promise.all([fetchBillingData(), fetchAuditMetrics(), fetchDisconnectionDetails()])
  }, [startDate])

  useEffect(() => {
    if (!initialRender) {
      fetchBillingData();
    }
  }, [selectedBDMonth])

  useEffect(() => {
    if (!initialRender) {
      fetchAuditMetrics();
    }
  }, [selectedDTCMonth])

  useEffect(() => {
    if (!initialRender) {
      fetchDisconnectionDetails();
    }
  }, [selectedDDMonth])

  //Fetch Data for Disconnection Details Chart
  const fetchDisconnectionDetails = async () => {
    try {
      const response = await getDisconnectionCount(DateToYYYYMM(new Date(startDate.getFullYear(), monthNames.indexOf(selectedDDMonth))))
      const { METER_REMOVED, DISCONNECTED, VACANT, POLE_DISCONNECTED, GOVT_INSTALLATION, AMOUNT_PAID } = response.data[0]
      setDisconnectionData([METER_REMOVED, DISCONNECTED, VACANT, POLE_DISCONNECTED, GOVT_INSTALLATION, AMOUNT_PAID])
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }

  //Fetch Data for Billing Details Chart
  const fetchBillingData = async () => {
    try {
      const response = await getBillingCount(DateToYYYYMM(new Date(startDate.getFullYear(), monthNames.indexOf(selectedBDMonth))))
      const { myInstallation, unbilled, vacant, mnr, doorLock, zeroConsumption } = response.data.billingCount;
      setBillingData([myInstallation, doorLock, unbilled, vacant, mnr, zeroConsumption])
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }

  //Fetch Data for DTC Energy Audit Chart
  const fetchAuditMetrics = async () => {
    try {
      const response = await getAuditMetrics(DateToYYYYMM(new Date(startDate.getFullYear(), monthNames.indexOf(selectedDTCMonth))))
      const { negativeLoss,
        zeroToFive,
        fiveToTen,
        tenToFifteen,
        fifteenToTwenty,
        twentyAndAbove,
        totalTransformers,
        audited,
        unaudited } = response.data
      setDTCData({
        negativeLoss,
        zeroToFive,
        fiveToTen,
        tenToFifteen,
        fifteenToTwenty,
        twentyAndAbove,
        totalTransformers,
        audited,
        unaudited
      })
    }
    catch (err) {
      notifier.notifyError(err)
    }
  }

  ////////////////////MEMOIZING Charts///////////////////////////////

  const BillingDetails = useMemo(() => {
    return (<BillingDetailsChart
      data={billingData}
    />)
  }, [billingData])

  const DTCEnergyAudit = useMemo(() => {
    return (
      <DTCEnergyAuditChart
        totalTCs={DTCData.totalTransformers}
        audited={DTCData.audited}
        unaudited={DTCData.unaudited}
      />
    )
  }, [DTCData])

  const DisconnectionDetails = useMemo(() => (
    <DisconnectionDetailsChart data={disconnectionData} />
  ), [disconnectionData])

  const CollectionDetails = useMemo(() => (
    <CollectionDetailsChart />
  ), [collectionData])


  return (
    <React.Fragment>
      <div className="dashboard-content">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="card top-tabs">
                <div className="card-body">
                  <p className="card-subtitle mb-2 roboto">Demand</p>
                  <h5 className="card-title mb-0 roboto">&#8377; 50000</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card top-tabs">
                <div className="card-body">
                  <p className="card-subtitle mb-2 roboto">Collection</p>
                  <h5 className="card-title mb-0 roboto">&#8377; 45000</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card top-tabs">
                <div className="card-body">
                  <p className="card-subtitle mb-2 roboto">Collection - Percentage</p>
                  <h5 className="card-title mb-0 roboto">10%</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 dashboard-charts">
            <div className="col-md-6 mb-4 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">Collection Details</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    // placeholderText="2021"
                    />
                  </div>
                  <div className="contain">
                    {CollectionDetails}
                  </div>
                  <div className="mt-3 year-list monthSelector" >
                    {monthNames.map(item => (
                      <span
                        key={item}
                        onClick={() => setSelectedCDMonth(item)}
                        className={`roboto font-medium-500 month ${selectedCDMonth === item ? 'activeMonth' : ''}`}>
                        {item}
                      </span>
                    ))}
                  </div>
                  {/* <ul className="mt-3 year-list"
                    style={styles.monthSelector}
                  >
                    {monthNames.map(item => (
                      <li
                        key={item}
                        style={{ cursor: 'pointer' }}
                        className={`${selectedCDMonth === item ? 'year-active' : ''} roboto font-medium-500`}
                        onClick={() => setSelectedCDMonth(item)}>{item}</li>
                    ))}
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">Billing Details</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    // placeholderText="2021"
                    />
                  </div>
                  <div className="contain">
                    {BillingDetails}
                  </div>
                  <div className="mt-3 year-list monthSelector">
                    {monthNames.map(item => (
                      <span
                        key={item}
                        onClick={() => setSelectedBDMonth(item)}
                        className={`roboto font-medium-500 month ${selectedBDMonth === item ? 'activeMonth' : ''}`}>
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">Disconnection Details</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    // placeholderText="2021"
                    />
                  </div>
                  <div className="contain">
                    {DisconnectionDetails}
                  </div>
                  <div className="mt-3 year-list monthSelector">
                    {monthNames.map(item => (
                      <span
                        key={item}
                        onClick={() => setSelectedDDMonth(item)}
                        className={`roboto font-medium-500 month ${selectedDDMonth === item ? 'activeMonth' : ''}`}>
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-group">
              <div className="card">
                <div className="card-body charts">
                  <div className="top-heading">
                    <h5 className="card-subtitle roboto">DTC Energy Audit</h5>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    // placeholderText="2021"
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                      <div className="contain">
                        {DTCEnergyAudit}
                      </div>
                    </div>
                    <div style={{ width: '30%' }} className="dashboard-negative-loss">
                      {/* <span className="title"><b>Negative Loses</b></span> */}
                      <ul>
                        <li>
                          <span style={{ width: '60%' }}>Negative Loses</span><span style={{ width: '40%' }}  >{DTCData.negativeLoss}</span>
                        </li>
                        <li>
                          <span>0 - 5%</span><span>{DTCData.zeroToFive}</span>
                        </li>
                        <li>
                          <span>5 - 10%</span><span>{DTCData.fiveToTen}</span>
                        </li>
                        <li>
                          <span>10 - 20%</span><span>{DTCData.tenToFifteen}</span>
                        </li>
                        <li>
                          <span>{'>'} 20%</span><span>{DTCData.twentyAndAbove}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-3 year-list monthSelector">
                    {monthNames.map(item => (
                      <span
                        key={item}
                        onClick={() => setSelectedDTCMonth(item)}
                        className={`roboto font-medium-500 month ${selectedDTCMonth === item ? 'activeMonth' : ''}`}>
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const styles = {
  monthSelector: {
    overflowX: 'auto',
    "scrollbar-width": "thin",
    // "-webkit-scrollbar-width": 20
  }
}

export default Index;
