export const tcReadingRemarks = [
    {
        label: "Meter Not Recording",
        value: "METER_NOT_RECORDING"
    },
    {
        label: "CT Burnt Out",
        value: "CT_BURNT_OUT"
    },
    {
        label: "No Display",
        value: "NO_DISPLAY"
    },
    {
        label: "Others",
        value: "OTHERS"
    },
]

export const executionType = [
    {
        label: "Self",
        value: "self"
    },
    {
        label: "Department",
        value: "department"
    },
]

export const tcCapacity = [
    {
        label: "25 KVA",
        value: "25"
    },
    {
        label: "63 KVA",
        value: "63"
    },
    {
        label: "100 KVA",
        value: "100"
    },
    {
        label: "160 KVA",
        value: "160"
    },
    {
        label: "250 KVA",
        value: "250"
    },
    {
        label: "315 KVA",
        value: "315"
    },
    {
        label: "500 KVA",
        value: "500"
    },
    {
        label: "990 KVA",
        value: "990"
    },
]

export const readingDay = [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15]

