import React from 'react'

const NoDataFound = ({ colSpan, style, message, loading = false }) => {
    return (
        <React.Fragment>
            <tr>
                <td colSpan={colSpan || 12} className='text-center' style={style}>{loading ? "Loading..." : message || "No Data Found!"}</td>
            </tr>
        </React.Fragment>
    );
}

export default NoDataFound;