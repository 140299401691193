import { DateToYYYYMM } from "../utils/date"

const Ajv = require("ajv").default
const ajv = new Ajv({ allErrors: true })
require("ajv-errors")(ajv, { singleError: true }
)
require("ajv-keywords")(ajv, ["transform"])


export const addTCValidator = (state) => {

    const dataFormatter = {
        feeder: {
            id: state.feederId
        },
        ...(state.taggedMRs.length > 0 && {
            taggedMRs: state.taggedMRs.map(item => {
                return ({ sub: item.value })
            })
        }),
        ...(state.readingMR && {
            readingMR: {
                sub: state.readingMR
            }
        }),
        ...(state.tcCapacity && { capacity: Number(state.tcCapacity) }),
        ...(state.executionType && { executionType: state.executionType }),
        readingDay: Number(state.readingDay),
        name: state.tcName,
        number: state.tcNumber,
        ...(state.timsCode && { timsCode: state.timsCode }),
        ...(state.dtlmsCode && { dtlmsCode: state.dtlmsCode }),
        ...(state.dtrCode && { dtrCode: state.dtrCode }),
        ...(state.tcMake && { make: state.tcMake }),
        ...(state.tcSerialNumber && { serialNumber: state.tcSerialNumber }),
        gpsCoordinates: {
            coordinates: {
                lat: Number(state.gpsCoordinates.latitude),
                long: Number(state.gpsCoordinates.longitude)
            }
        },
        meter: {
            ...(state.meterMake && { make: state.meterMake }),
            ...(state.meterSerialNumber && { serialNumber: state.meterSerialNumber }),
            ctRatio: {
                primaryCurrent: Number(state.primaryCurrent),
                secondaryCurrent: Number(state.secondaryCurrent)
            }
        },
        // ...(((state.initialReading || state.initialReadingRemarks) && state.initialReadingMonth) && {
        initialReading: {
            month: DateToYYYYMM(state.initialReadingMonth),
            ...(state.initialReading && { 'value': Number(state.initialReading) }),
            ...(state.remarks && { 'failureReason': state.remarks }),
        }
        // }),
    }


    // console.log(state.initialReadingRemarks);

    const schema = {
        title: 'AddTC',
        description: 'Create transformer-centre object',
        type: 'object',
        required: ['feeder', 'readingDay', 'name', 'number', 'taggedMRs', 'readingMR','executionType'],
        // additionalProperties: false,

        properties: {
            taggedMRs: {
                type: 'array',
                minLength: 1,
                errorMessage: {
                    minLength: 'Please Select a reading MR'
                },

            },

            readingMR: {
                type: 'object',
                required: ['sub'],
                additionalProperties: false,
                properties: {
                    type: { type: 'string' },
                    sub: {
                        type: 'string',
                        minLength: 1,
                        additionalProperties: false,
                    },
                },
                errorMessage: {
                    minLength: 'Please Select a reading MR'
                },

            },

            number: {
                type: 'string',
                transform: ["trim"],
                minLength: 1,
                maxLength: 10,
                pattern: '^[1-9][0-9]*$',
                errorMessage: {
                    type: 'Please enter numeric values for TC Number',
                    minLength: 'Please enter TC Number',
                    maxLength: 'Please enter less than 10 characters for TC Number',
                    pattern: 'TC Number should be digits and should not begin with 0'
                }
            },
            name: {
                type: 'string',
                transform: ["trim"],
                minLength: 3,
                maxLength: 500,
                errorMessage: {
                    type: 'Please enter TC Name',
                    minLength: 'Please enter more than 2 characters for TC Name',
                    maxLength: 'Please enter less than 30 characters for TC Name'
                }
            },
            readingDay: {
                type: 'integer',
                enum: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15],
                errorMessage: {
                    type: 'Please select Reading Day',
                    enum: 'Please select Reading Day'
                }
            },
            timsCode: {
                type: 'string',
                transform: ["trim"],
                minLength: 3,
                maxLength: 30,
                errorMessage: {
                    type: 'Please enter numeric values for TIMS Code',
                    minLength: 'Please enter more than 3 characters for TIMS Code',
                    maxLength: 'Please enter less than 30 characters for TIMS Code',
                },
            },
            dtlmsCode: {
                type: 'string',
                transform: ["trim"],
                minLength: 3,
                maxLength: 30,
                errorMessage: {
                    type: 'Please enter numeric values for DTLMS Code ',
                    minLength: 'Please enter more than 3 characters for DTLMS Code',
                    maxLength: 'Please enter less than 30 characters for DTLMS Code',
                },
            },
            dtrCode: {
                type: 'string',
                transform: ["trim"],
                minLength: 3,
                maxLength: 30,
                errorMessage: {
                    type: 'Please enter numeric values for DTR Code ',
                    minLength: 'Please enter more than 3 characters for DTR Code',
                    maxLength: 'Please enter less than 30 characters for DTR Code',
                },
            },
            make: {
                type: 'string',
                transform: ["trim"],
                minLength: 3,
                maxLength: 30,
                errorMessage: {
                    type: 'Please enter values for TC Make ',
                    minLength: 'Please enter more than 3 characters for TC Make',
                    maxLength: 'Please enter less than 30 characters for TC Make',
                },
            },
            serialNumber: {
                type: 'string',
                transform: ["trim"],
                // pattern: '^[a-zA-Z0-9]+$',
                minLength: 3,
                maxLength: 30,
                errorMessage: {
                    type: 'Please enter values for TC Serial Number',
                    minLength: 'Please enter more than 3 characters for TC Serial Number',
                    maxLength: 'Please enter less than 30 characters for TC Serial Number',
                    pattern: 'Please enter TC Serial Number only with string and number pattern'
                },
            },
            capacity: {
                type: 'integer',
                enum: [25, 63, 100, 160, 250, 315, 500, 990],
                errorMessage: {
                    type: 'Please select TC Capacity',
                    enum: 'Please select a valid TC Capacity'
                },
            },
            executionType: {
                type: 'string',
                enum: ['self', 'department'],
                errorMessage: {
                    type: 'Please select Execution Type',
                    enum: 'Please select Execution Type',
                },
            },
            gpsCoordinates: {
                type: 'object',
                required: ['coordinates'],
                additionalProperties: false,
                properties: {
                    type: { type: 'string', const: 'point' },
                    coordinates: {
                        type: 'object',
                        required: ['lat', 'long'],
                        errorMessage: {
                            required: 'Please provide GPS co-ordinates'
                        },
                        additionalProperties: false,
                        properties: {
                            lat: { type: 'number', minimum: -90, maximum: 90 },
                            long: {
                                type: 'number',
                                minimum: -180,
                                maximum: 180,
                            },
                        },
                    },
                },
            },
            feeder: {
                type: 'object',
                required: ['id'],
                errorMessage: {
                    required: 'Please select Feeder'
                },
                additionalProperties: false,
                properties: {
                    id: {
                        type: 'string',
                        pattern: '^[A-Za-z0-9-_]+$',
                        errorMessage: {
                            pattern: 'Please select a feeder'
                        },
                    },
                },
            },
            meter: {
                type: 'object',
                required: ['ctRatio'],
                additionalProperties: false,
                properties: {
                    make: {
                        type: 'string',
                        transform: ["trim"],
                        minLength: 3,
                        maxLength: 30,
                        errorMessage: {
                            type: 'Please enter Meter Make details',
                            minLength: 'Please enter more than 3 characters for Meter Make',
                            maxLength: 'Please enter less than 30 characters for Meter Make',
                        },
                    },
                    serialNumber: {
                        type: 'string',
                        transform: ["trim"],
                        // pattern: '^[0-9]',
                        minLength: 3,
                        maxLength: 30,
                        errorMessage: {
                            type: 'Please enter Serial Number details',
                            minLength: 'Please enter more than 3 characters for Meter Serial Number',
                            maxLength: 'Please enter less than 30 characters for Meter Serial Number',
                            pattern: 'Please enter Serial Number only with string and number pattern'
                        },
                    },
                    ctRatio: {
                        type: 'object',
                        required: ['primaryCurrent', 'secondaryCurrent'],
                        additionalProperties: false,
                        properties: {
                            primaryCurrent: {
                                type: 'integer',
                                minimum: 1,
                                errorMessage: {
                                    type: 'Please enter Number values for Primary Ratio ',
                                    minimum: 'Please enter positive number values for Primary Ratio ',
                                },
                            },
                            secondaryCurrent: {
                                type: 'integer',
                                minimum: 1,
                                errorMessage: {
                                    type: 'Please enter Number values for Secondary Ratio ',
                                    minimum: 'Please enter positive number values for Secondary Ratio ',
                                },
                            },
                        },
                    },
                },
            },
            initialReading: {
                type: 'object',
                required: ['month'],
                // additionalProperties: false,
                properties: {
                    month: {
                        type: 'string',
                        pattern: '^([2][0-9]{3})-(0[1-9]|1[0-2])$',
                        errorMessage: {
                            pattern: 'Please select month for initial reading',
                            type: 'Please select reading month ',
                        },
                    },
                    value: {
                        type: 'integer',
                        transform: ["trim"],
                        minimum: 0,
                        errorMessage: {
                            type: 'Please enter Number values for Reading ',
                            minimum: 'Please enter positive number values for Reading',
                        },
                    },
                    failureReason: {
                        type: 'string',
                        enum: [
                            'METER_NOT_RECORDING',
                            'CT_BURNT_OUT',
                            'NO_DISPLAY',
                            'OTHERS',
                        ],
                        errorMessage: {
                            enum: "Other values not allowed."
                        }
                    },
                },
                anyOf: [
                    {
                        required: ['month', 'value'],
                        // propertyNames: { not: { const: 'failureReason' } },
                        errorMessage: {
                            required: 'Please enter initial reading Details ',
                        },
                    },
                    {
                        required: ['month', 'failureReason'],
                        // propertyNames: { not: { const: 'value' } },
                        // errorMessage: {
                        //     required: 'Please enter initial reading Details ',
                        // },
                    },
                ],
            },

        },
        // 
    }

    const validation = ajv.compile(schema)
    const isValid = validation(dataFormatter)
    if (isValid) return dataFormatter
    throw (validation.errors[0])

}