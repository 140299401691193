const Ajv = require('ajv').default;
const addFormats = require('ajv-formats');

const ajv = new Ajv({ allErrors: true });
addFormats(ajv);
require('ajv-errors')(ajv);
require('ajv-keywords')(ajv, ['transform']);

export const validateProfileDetails = (input) => {
    const { contactNumber, firstName, lastName, gender, emailId, addressLine1, addressLine2, state, city, pinCode } = input
    const dataFormatter =
    {
        name: {
            ...(firstName && { firstName }),
            ...(lastName && { lastName })
        },
        phoneNumber: `+91${contactNumber}`,
        gender,
        email: emailId,
        address: {
            line1: addressLine1,
            line2: addressLine2,
            city,
            state,
            pinCode: pinCode
        }
    }

    const schema = {
        title: 'Update Profile',
        description: 'Update Profile',
        type: 'object',
        additionalProperties: false,
        properties: {
            name: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    firstName: {
                        type: 'string',
                        pattern: '^[a-zA-Z .]{3,}$',
                        minLength: 3,
                        maxLength: 30,
                        transform: ['trim'],
                        errorMessage: {
                            type: 'Enter a valid first name',
                            pattern: 'Enter a valid first name',
                            minLength: 'Please enter more than 3 characters for first name',
                            maxLength: 'Please enter less than 30 characters for first name',
                        },
                    },
                    lastName: {
                        type: 'string',
                        pattern: '^[a-zA-Z .]{3,}$',
                        minLength: 3,
                        maxLength: 30,
                        transform: ['trim'],
                        errorMessage: {
                            type: 'Enter a valid last name',
                            pattern: 'Enter a valid last name',
                            minLength: 'Please enter more than 3 characters for last name',
                            maxLength: 'Please enter less than 30 characters for last name',
                        },
                    },
                },
            },
            phoneNumber: {
                type: 'string',
                minLength: 13,
                maxLength: 13,
                pattern: '^\\+91[6789][0-9]{9}$',
                errorMessage: {
                    type: 'Enter a valid Phone Number',
                    pattern: 'Enter a valid Phone Number',
                    minLength: 'Please enter Phone Number with length 10',
                },
            },
            gender: {
                type: 'string',
                enum: ['male', 'female', 'others'],
            },
            email: {
                type: 'string',
                maxLength: 50,
                format: 'email',
                errorMessage: {
                    type: 'Enter a valid email id',
                    format: 'Enter a valid email id',
                    maxLength: 'Please enter less than 50 characters for email id',
                },
            },
            address: {
                type: 'object',
                additionalProperties: false,
                properties: {
                    line1: {
                        type: 'string',
                        maxLength: 50, errorMessage: {
                            type: 'Enter a valid address line 1',
                            maxLength: 'Please enter less than 50 characters for address line 1',
                        },

                    },
                    line2: {
                        type: 'string',
                        maxLength: 50,
                        errorMessage: {
                            type: 'Enter a valid address line 2',
                            maxLength: 'Please enter less than 50 characters for address line 2',
                        },
                    },
                    city: {
                        type: 'string',
                        maxLength: 50,
                        errorMessage: {
                            type: 'Enter a valid city',
                            maxLength: 'Please enter less than 50 characters for city',
                        },
                    },
                    state: {
                        type: 'string',
                        maxLength: 50,
                        errorMessage: {
                            type: 'Enter a valid state',
                            maxLength: 'Please enter less than 50 characters for state',
                        },
                    },
                    pinCode: {
                        type: 'string',
                        pattern: '^[1-9]{1}[0-9]{5}$',
                        errorMessage: {
                            type: 'Enter a valid pinCode',
                            pattern: 'Please enter pinCode in valid format',
                        },
                    },
                },
            },
        },
        errorMessage: {
            required: 'Missing mandatory properties',
            additionalProperties: 'No additional properties allowed',
            properties: {
                firstName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
                lastName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
                phoneNumber: 'Enter a valid Indian phone number',
                gender: 'Invalid gender',
                email: 'Invalid email',
                line1: 'Invalid data in address line1 ',
                line2: 'Invalid data in address line2 ',
                city: 'Invalid data in address city ',
                state: 'Invalid data in address state ',
                pinCode: 'Invalid data in pin Code ',
            },
        },
    };

    const validation = ajv.compile(schema);
    const isValid = validation(dataFormatter)
    if (!isValid) throw (validation.errors[0])


    return dataFormatter
}
