import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import closeIcon from '../../assets/img/close-icon.png';
import { NotifierContext } from '../../context/NotifierContext';
import { updatePowerStation } from '../../services/api/feederService';

const EditPowerStation = (props) => {

  const notifier = useContext(NotifierContext)
  const history = useHistory()
  const location = useLocation()

  const [isDisabled, setIsDisabled] = useState(false)

  const selectedStationCode = location?.state?.stationCode
  const selectedStationName = location?.state?.stationName
  const selectedVoltageClass = location?.state?.voltageClass

  const [stationFields, setStationFields] = useState({
    stationCode: selectedStationCode ?? null,
    voltageClass: selectedVoltageClass,
    stationName: selectedStationName
  })
  const [isFormValid] = useState(true);

  useEffect(() => {
    /** Debouncing */
    const timer = setTimeout(() => {
      let checkForm = true;
      for (const [key, value] of Object.entries(stationFields)) {
        if (validateField(key) !== '') {
          checkForm = false;
        }
      }
      // setFormValid(checkForm);
    }, 200);
    return () => {
      clearTimeout(timer);
    }
  }, [stationFields])


  //Function for handling searchable dropdown value change of station search
  const handleStationChange = (option) => {
    setStationFields({
      stationCode: option?.value ?? null,
      voltageClass: option?.voltageClass || "",
      stationName: option?.stationName || "",
      ...(option?.createdAt && { createdAt: option.createdAt })
    })
  }

  const validateField = (name) => {
    let error = '';
    /** Generic test */
    error = (stationFields[name] === null || stationFields[name].length == 0) ? 'Please enter valid value' : '';
    return error;
  }

  //Function to Power Station
  const submitHandler = async () => {
    try {
      setIsDisabled(true)
      await updatePowerStation({
        ...(stationFields.stationName && {name: stationFields.stationName}),
        ...(stationFields.voltageClass && {voltageClass: Number(stationFields.voltageClass)})
      },stationFields.stationCode)
      // const validatedData = addPowerStationValidation(stationFields)
      // alert(JSON.stringify(stationFields))
      notifier.notifySuccess(`Successfully updated the Power Station.`)
      history.goBack()
      setIsDisabled(false)

    }
    catch (err) {
      notifier.notifyError(err)
      setIsDisabled(false)
    }

  }




  return (
    <React.Fragment>
      <div className="add-substation">
        <div className="container pt-3">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="code-section">
                <span>Feeder Management&nbsp; |</span>
                <span className="add-new-substation-text"><a >&nbsp; Edit Station</a></span>
              </div>
              <hr className="horizontal-line" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="substation-title">
                <h1 className="mb-0">Edit Station</h1>
              </div>
            </div>
            <div className="col-md-6">
              <div className="close-mr">
                <Link onClick={history.goBack} className="btn btn-primary bg-transparent"><img src={closeIcon} />Close
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="main-substation-form p-3 mb-3">

                {/* ///////////////////////////////////////////////////////////Edit Station Section//////////////////////////////////////////////////////// */}

                <form>
                  <div className="forms-input pb-10">
                    <div className="form-substation-shadow">
                      <div className="row">
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">Station Code *</label>
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            placeholder="Station Code"
                            value={stationFields.stationCode}
                            onChange={(input) => setStationFields({ ...stationFields, stationCode: input.target.value })}
                          />
                        </div>
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">Voltage Class</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={stationFields.voltageClass}
                            onChange={(option) => setStationFields({ ...stationFields, voltageClass: option.target.value })}
                          >
                            <option value="">Voltage Class</option>
                            <option value="33">33KV</option>
                            <option value="66">66KV</option>
                            {/* <option value="100">100KV</option> */}
                            <option value="220">220KV</option>
                            <option value="400">400KV</option>
                          </select>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">Station Name *</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Station Name"
                            value={stationFields.stationName}
                            onChange={(input) => setStationFields({ ...stationFields, stationName: input.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-md-12 mr-submit-btn">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ background: isDisabled && '#c0c1c2', border: isDisabled && '#c0c1c2' }}
                      disabled={!isFormValid || isDisabled}
                      onClick={submitHandler}>
                      Submit {'\t'}
                      {isDisabled && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditPowerStation;
