import React, { createContext, useState } from 'react';

export const AuditResultContext = createContext()

const AuditResultProvider = ({ children }) => {

    const [activeItem, setActiveItem] = useState("") //To open and close accordian
    const [activeDataTab, setDataTab] = useState('total-transformers'); //To set main tabs in audit results as active
    
    ////////////////////////////To toggle sub-tabs in audit result//////////////////////////
    const [activeTransformerAuditedButton, setActiveTransformerAuditedButton] = useState('');
    const [activeTransformerUnauditedButton, setActiveTransformerUnauditedButton] = useState('');



    return (
        <AuditResultContext.Provider value={{
            activeItem, setActiveItem,
            activeDataTab, setDataTab,
            activeTransformerAuditedButton, setActiveTransformerAuditedButton,
            activeTransformerUnauditedButton, setActiveTransformerUnauditedButton,
        }}>
            {children}
        </AuditResultContext.Provider>
    );
}

export default AuditResultProvider;