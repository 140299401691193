import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import closeIcon from '../../assets/img/close-icon.png';
import { NotifierContext } from '../../context/NotifierContext';
import { updateFeeder } from '../../services/api/feederService';

const EditFeeder = (props) => {

    const notifier = useContext(NotifierContext)
    const history = useHistory()
    const location = useLocation()

    const selectedStationCode = location?.state?.stationCode
    const selectedStationName = location?.state?.stationName
    const selectedVoltageClass = location?.state?.voltageClass

    const selectedFeederMDMCode = location?.state?.feederMDMCode
    const selectedFeederNumber = location?.state?.feederNumber
    const selectedFeederName = location?.state?.feederName

    const [isDisabled, setIsDisabled] = useState(false)

    const [feederFields, setFeederFields] = useState({
        name: selectedFeederName || "",
        mdm_code: selectedFeederMDMCode || "",
        number: selectedFeederNumber || ""
    });
    const [stationFields, setStationFields] = useState({
        stationCode: selectedStationCode ?? null,
        voltageClass: selectedVoltageClass,
        stationName: selectedStationName
    })
    const [isFormValid, setFormValid] = useState(false);
    const [feeders, setFeeders] = useState([]);

    useEffect(() => {
        /** Debouncing */
        const timer = setTimeout(() => {
            let checkForm = true;
            for (const [key] of Object.entries(feederFields)) {
                if (validateField(key) !== '') {
                    checkForm = false;
                }
            }
            setFormValid(checkForm);
        }, 200);
        return () => {
            clearTimeout(timer);
        }
    }, [feederFields])

    //Function for handling searchable dropdown value change of feeder search
    // const handleFeederChange = (option) => {
    //     setFeederFields({
    //         name: option?.feederName || "",
    //         mdm_code: option?.value ?? null,
    //         number: option?.feederNumber || "",
    //         ...(option?.powerStation?.stationCode && { stationCode: option?.powerStation?.stationCode })
    //     })
    // }

    //Function for handling searchable dropdown value change of station search
    // const handleStationChange = (option) => {
    //     setStationFields({
    //         stationCode: option?.value ?? null,
    //         voltageClass: option?.voltageClass || "",
    //         stationName: option?.stationName || "",
    //         ...(option?.createdAt && { createdAt: option.createdAt })
    //     })
    // }

    //Fetch list of stations on search of stations
    // const fetchStations = async (input) => {
    //     try {
    //         if (input.length > 1) {
    //             const response = await searchPowerStations(input)
    //             const searchResult = response.data.map(i => {
    //                 return ({
    //                     label: i.stationCode,
    //                     value: i.stationCode,
    //                     voltageClass: i?.voltageClass || "",
    //                     stationName: i.name,
    //                     createdAt: i.createdAt
    //                 })
    //             })
    //             if (searchResult.length === 0) return ([{ label: `New Station :  ${input}`, value: input }])
    //             return searchResult
    //         }
    //     }
    //     catch (err) {
    //         notifier.notifyError(err)
    //     }
    // }

    //Fetch list of feeder on search of feeders
    // const fetchFeeders = async (input) => {
    //     try {
    //         if (input.length > 1) {
    //             if (!stationFields.stationCode) throw new Error("Please Fill Station Code")
    //             const response = await searchFeeders(input, stationFields.stationCode)
    //             const searchResult = response.data.feederList.map(i => {
    //                 return ({
    //                     label: i.mdmCode,
    //                     value: i.mdmCode,
    //                     feederNumber: i?.number || "",
    //                     feederName: i?.name || "",
    //                     powerStation: i.powerStation
    //                 })
    //             })
    //             if (searchResult.length === 0) return ([{ label: `New Feeder :  ${input}`, value: input }])
    //             return searchResult
    //         }
    //     }
    //     catch (err) {
    //         notifier.notifyError(err)
    //     }
    // }

    //handle change in input fields of feeders
    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const feederFieldsObject = {
            ...feederFields,
            [name]: value
        }
        setFeederFields(feederFieldsObject);
    }

    const validateField = (name) => {
        let error = '';
        /** Generic test */
        error = (feederFields[name] === null || feederFields[name].length == 0) ? 'Please enter valid value' : '';
        return error;
    }

    //Function to delete feeder
    // const deleteFeeder = (index) => {
    //     const feederList = [...feeders]
    //     feederList.splice(index, 1)
    //     setFeeders(feederList)
    // }

    //Function to Edit Feeder
    const submitHandler = async () => {
        try {
            setIsDisabled(true)
            // const validatedData = addPowerStationValidation(stationFields)
            await updateFeeder({
                name: feederFields.name,
                // number: feederFields.number
            }, selectedStationCode, selectedFeederMDMCode)
            notifier.notifySuccess(`Successfully updated the feeder`)
            history.goBack()
        }
        catch (err) {
            notifier.notifyError(err)
            setIsDisabled(false)
        }

    }

    return (
        <React.Fragment>
            <div className="add-substation">
                <div className="container pt-3">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="code-section">
                                <span>Feeder Management&nbsp; |</span>
                                <span className="add-new-substation-text"><a>&nbsp; Edit Feeder</a></span>
                            </div>
                            <hr className="horizontal-line" />
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="substation-title">
                                <h1 className="mb-0">Edit Feeder</h1>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="close-mr">
                                <Link onClick={history.goBack} className="btn btn-primary bg-transparent"><img src={closeIcon} />Close
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="main-substation-form p-3 mb-3">

                                {/* ///////////////////////////////////////////////////////////Edir Station Section//////////////////////////////////////////////////////// */}

                                <form>
                                    <div className="forms-input pb-10">
                                        <div className="form-substation-shadow">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="form-label font-regular-400">Station Code*</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        placeholder="Station Code"
                                                        value={stationFields.stationCode}
                                                        onChange={(input) => setStationFields({ ...stationFields, stationCode: input.target.value })}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label font-regular-400">Voltage Class</label>
                                                    <select
                                                        className="form-select"
                                                        disabled
                                                        aria-label="Default select example"
                                                        value={stationFields.voltageClass}
                                                        onChange={(option) => setStationFields({ ...stationFields, voltageClass: option.target.value })}
                                                    >
                                                        <option>Voltage Class</option>
                                                        <option value="33">33KV</option>
                                                        <option value="66">66KV</option>
                                                        <option value="100">100KV</option>
                                                        <option value="220">220KV</option>
                                                        <option value="400">400KV</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label font-regular-400">Station Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        placeholder="Station Name"
                                                        value={stationFields.stationName}
                                                        onChange={(input) => setStationFields({ ...stationFields, stationName: input.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* <hr className="divider" /> */}
                                <div className="main-feeder-div">
                                    <div className="feeder-title">
                                        <h3 className="mb-0">Edit Feeder</h3>
                                    </div>

                                    {/*////////////////////////////////////////////////////////Edit Feeder Section//////////////////////////////////////////////////////////*/}


                                    <form className="feeder-form">
                                        <div className="forms-input">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="form-label">Feeder MDM Code</label>
                                                    <input
                                                        // onChange={handleChange}
                                                        disabled
                                                        name="mdm_code"
                                                        value={feederFields['mdm_code']}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Feeder MDM Code" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label">Feeder Number</label>
                                                    <input
                                                        disabled
                                                        onChange={handleChange}
                                                        name="number"
                                                        value={feederFields['number']}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Feeder Number" />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="form-label">Feeder Name</label>
                                                    <input
                                                        onChange={handleChange}
                                                        name="name"
                                                        value={feederFields['name']}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Feeder Name" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 mr-submit-btn">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            style={{ background: isDisabled && '#c0c1c2', border: isDisabled && '#c0c1c2' }}
                                            disabled={!isFormValid || isDisabled}
                                            onClick={submitHandler}>
                                            Submit {'\t'}
                                            {isDisabled && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EditFeeder;
