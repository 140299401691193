import React, { useContext, useEffect, useState } from 'react';
import fileIcon from '../../../../assets/img/file-icon.svg';
import checkIcon from '../../../../assets/img/step-blue-circle-selected.svg';
import errorCheckIcon from '../../../../assets/img/step-error-icon.svg';
import successTick from '../../../../assets/img/successful-small-cricle-icon.svg';
import errorTick from '../../../../assets/img/error-red-small-circle-icon.svg';
import UploadImage from '../../../../assets/img/upload-icon@2x.png';
import { AuditMonthContext } from '../../../../context/AuditMonthContext';
import { NotifierContext } from '../../../../context/NotifierContext';
import {
    auditTCs,
    getDownLoadURL,
    getPresignedURL,
    getSampleFilePresignedURL,
    getUploadStatus,
    uploadFile
} from '../../../../services/api/tcAuditService';
import { Colors } from '../../../../theme/colors';




const FileUpload = ({ refreshStatus }) => {
    const filesForMonthArray = [
        {
            title: "TC Details",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: true,
            enum: "tcdetails",//This is used for upload and download
            statusKey: "TCDetails",//This is used to fetch audit status,
            uploadBtnStatus: true,
            loading: false
        },
        {
            title: "Installation Status Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "isr",//This is used for upload and download
            statusKey: "ISR",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false
        },
        {
            title: "TC RR Number Tagging",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: true,
            enum: "tcrrtag",//This is used for upload and download
            statusKey: "TCRRTag",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            title: "Unbilled Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "ub",//This is used for upload and download
            statusKey: "unbilled",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            title: "MNR Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "mnr",//This is used for upload and download
            statusKey: "MNR",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            title: "Vacant  Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "vc",//This is used for upload and download
            statusKey: "vacant",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            title: "Doorlock Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "dl",//This is used for upload and download
            statusKey: "doorLock",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false
        },
        {
            title: "Zero Consumption Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "zc",//This is used for upload and download
            statusKey: "zeroConsumption",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            title: "Abnormal Consumption Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "abnormal",//This is used for upload and download
            statusKey: "abnormal",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            title: "Subnormal Consumption Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "subnormal",//This is used for upload and download
            statusKey: "subnormal",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            // title: "Demand Consumption Balance (DCB) Report",
            title: "Consumption DCB Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "dcb",//This is used for upload and download
            statusKey: "DCB",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        },
        {
            title: "Revenue DCB Report",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            isUploaded: false,
            isValidated: false,
            isProcessed: false,
            downloadSampleFile: false,
            enum: "dcbRevenue",//This is used for upload and download
            statusKey: "dcbRevenue",//This is used to fetch audit status
            uploadBtnStatus: true,
            loading: false

        }
    ];


    const notifier = useContext(NotifierContext)
    const { auditMonthYear } = useContext(AuditMonthContext)

    const [fileBeingUploaded, setFileBeingUploaded] = useState(0);
    const [filesForMonth, setFilesForMonth] = useState(filesForMonthArray);
    const [loading, setLoading] = useState(true);
    const [auditStatus, setAuditStatus] = useState({})

    useEffect(() => {
        fetchStatus()
    }, [refreshStatus, auditMonthYear])

    const setFilesData = (status = "NOT_UPLOADED", item, index) => {
        switch (status) {
            case "NOT_UPLOADED":
                return ({
                    isUploaded: "NOT_UPLOADED",
                    isValidated: "NOT_VALIDATED",
                    isProcessed: "NOT_PROCESSED",
                    uploadBtnStatus: true
                })
            case "UPLOADED":
                return ({
                    isUploaded: "UPLOADED",
                    isValidated: "NOT_VALIDATED",
                    isProcessed: "NOT_PROCESSED",
                    uploadBtnStatus: false
                })
            case "VALIDATION_FAILED":
                return ({
                    isUploaded: "UPLOADED",
                    isValidated: "VALIDATION_FAILED",
                    isProcessed: "NOT_PROCESSED",
                    uploadBtnStatus: true
                })
            case "VALIDATED":
                return ({
                    isUploaded: "UPLOADED",
                    isValidated: "VALIDATED",
                    isProcessed: "NOT_PROCESSED",
                    uploadBtnStatus: false
                })
            case "PROCESSING_FAILED":
                return ({
                    isUploaded: "UPLOADED",
                    isValidated: "VALIDATED",
                    isProcessed: "PROCESSING_FAILED",
                    uploadBtnStatus: true
                })
            case "PROCESSED":
                return ({
                    isUploaded: "UPLOADED",
                    isValidated: "VALIDATED",
                    isProcessed: "PROCESSED",
                    uploadBtnStatus: false
                })
            case "PROCESSING":
                return ({
                    isUploaded: "UPLOADED",
                    isValidated: "NOT_VALIDATED",
                    isProcessed: "NOT_PROCESSED",
                    uploadBtnStatus: false
                })
            default:
                return ({
                    isUploaded: "NOT_UPLOADED",
                    isValidated: "NOT_VALIDATED",
                    isProcessed: "NOT_PROCESSED",
                    uploadBtnStatus: false
                })

        }
    }

    const fetchStatus = async () => {
        try {
            setLoading(true)
            const response = await getUploadStatus(auditMonthYear)
            setAuditStatus(response.data)
            const filesForMonthCopy = [...filesForMonth]
            setFilesForMonth(filesForMonth.map((item, index) => {
                const result = setFilesData(response.data[item.statusKey].status, item, index)
                return ({ ...filesForMonthCopy[index], ...result })

            }))
            setLoading(false)
        }
        catch (err) {
            notifier.notifyError(err)
        }
    }

    const displayStyle = {
        display: "-webkit-inline-box",
        cursor: "pointer"
    };


    const fileBrowserHandler = async (event, index) => {
        const filesForMonthCopy = [...filesForMonth]
        try {
            filesForMonthCopy[index].loading = true
            setFilesForMonth(filesForMonthCopy)
            const fileExtension = event.target.files[0].name.split(".").pop()
            if (event.target.dataset.enum === 'dcb') {
                if (fileExtension !== 'xlsx' && fileExtension !== 'xlsb' && fileExtension !== 'xls') {
                    throw new Error("This type of file is not allowed to upload. You can upload only files which have xls,xlsx,xlsb extensions")
                }
            }
            if (fileExtension !== 'xlsx' && fileExtension !== 'xls' && event.target.dataset.enum !== 'dcb') {
                throw new Error("This type of file is not allowed to upload. You can upload only files which have xls,xlsx extensions")
            }
            const response = await getPresignedURL(event.target.dataset.enum, auditMonthYear)
            await uploadFile(event.target.files[0], response.data[fileExtension])
            filesForMonthCopy[index].loading = false
            filesForMonthCopy[index].isUploaded = "UPLOADED"
            filesForMonthCopy[index].isValidated = "NOT_VALIDATED"
            filesForMonthCopy[index].isProcessed = "NOT_PROCESSED"
            filesForMonthCopy[index].uploadBtnStatus = false
            setFilesForMonth(filesForMonthCopy)
            notifier.notifySuccess(`Successfully Uploaded the ${event.target.dataset.title} file.`)
            // setFileBeingUploaded(fileBeingUploaded + 1);
        }
        catch (err) {
            notifier.notifyError(err)
            filesForMonthCopy[index].loading = false
            setFilesForMonth(filesForMonthCopy)
        }
    }

    const handleAudit = async () => {
        try {
            setLoading(true)
            const response = await auditTCs(auditMonthYear)
            notifier.notifySuccess(response.message)
            setLoading(false)
        }
        catch (err) {
            notifier.notifyError(err)
            setLoading(false)
        }
    }

    const downloadProcessedResult = async (fileName) => {
        try {
            const response = await getDownLoadURL(fileName, auditMonthYear)
            window.location.href = response.data
        }
        catch (err) {
            notifier.notifyError(err)
        }
    }

    const checkIfPrevFileIsProcessed = (index) => {
        if (index !== 0) {
            return (filesForMonth[index - 1].isProcessed === "PROCESSED") ? true : false
        }
        return true
    }

    const checkIfSkippable = (index) => {
        return (index === 0 || index === 2)
    }

    const handleSkip = (index) => {
        const confirmation = window.confirm("Are you sure you want to skip this upload.")
        if (confirmation) {
            const filesForMonthCopy = [...filesForMonth]
            if (index === 0 || index === 2) {
                filesForMonth[index].uploadBtnStatus = false
                filesForMonth[index].isProcessed = "PROCESSED"
                filesForMonth[index + 1].uploadBtnStatus = true
                setFilesForMonth(filesForMonthCopy)
            }
        }
    }

    const sampleFileHandler = async (fileName) => {
        try {
            const response = await getSampleFilePresignedURL(fileName)
            window.location.href = response.data
        }
        catch (err) {
            notifier.notifyError(err)
        }
    }

    return (
        <>
            {loading ?
                <div style={{ minHeight: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>
                : <div className="card border-0">
                    {
                        filesForMonth.map((fileType, index) => {
                            return (
                                <div key={index} className="card-body mb-3">
                                    {/* ////////////////////////////////////////Card/////////////////////////////////////////// */}
                                    <div className="tc-top-detail row">
                                        <div className="tc-details-left col-12 col-md-8 col-xl-10">
                                            <p className="mb-2 tc-title">
                                                {fileType.title}
                                                {"\n"}
                                                {process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION" && `( ${auditStatus[fileType.statusKey].status} )`}
                                            </p>
                                            {/* <p className="mb-2">{fileType.description}</p> */}
                                            {
                                                fileType.downloadSampleFile &&
                                                (<span>
                                                    <a href={fileType.downloadSampleFile} style={displayStyle} onClick={() => sampleFileHandler(fileType.enum)}>
                                                        <img className="fa fa-file-text img-fluid" src={fileIcon} />&nbsp;
                                                        <p className="mb-0 download-link">Download sample file</p>
                                                    </a>
                                                </span>)
                                            }
                                        </div>

                                        {/*/////////////////////////////////////////////Upload Button////////////////////////////////// */}
                                        <div className="tc-upload-btn col-12 col-md-4 col-xl-2">
                                            {
                                                // fileType.uploadBtnStatus && checkIfPrevFileIsProcessed(index) &&
                                                (<input data-title={fileType.title} data-enum={fileType.enum} className="form-control"
                                                    type="file"
                                                    onChange={(e) => fileBrowserHandler(e, index)}
                                                />)
                                            }
                                            <button className={`btn btn-primary ${(fileType.uploadBtnStatus && checkIfPrevFileIsProcessed(index)) ? 'upload-load-active' : 'upload-load-active'}`} disabled={fileType.loading}>
                                                {/* <i className="fa fa-upload" aria-hidden="true"></i> */}
                                                <img className="img-fluid icon-width-height-24" src={UploadImage} />
                                                Upload{'\t'}
                                                {fileType.loading && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                                            </button>
                                            {/* {checkIfSkippable(index) && <p style={{ cursor: 'pointer' }} onClick={() => { handleSkip(index) }}>Skip {' >>'} </p>} */}
                                            {/* <button href="#" className="btn btn-primary upload-load-active justify-content-center">
                                                    <i className="fa fa-undo" aria-hidden="true"></i>
                                                </button> */}
                                        </div>
                                    </div>
                                    {/* ///////////////////////////////////////////////Card Extension///////////////////////////////////////// */}
                                    <div className={`${fileType.isUploaded !== "NOT_UPLOADED" ? '' : 'd-none'}`}>
                                        <ul className="tc-progress-bar mt-4">
                                            <li>
                                                <span>
                                                    <p className={`mb-0 ${fileType.isUploaded === "UPLOADED" ? 'check-icon' : ''}`}>
                                                        {/* {!fileType.isUploaded && <span>1</span>} */}
                                                        {fileType.isUploaded === "UPLOADED" && <img className="fa fa-check img-fluid" src={checkIcon} />}
                                                        {fileType.isUploaded === "NOT_UPLOADED" && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
                                                    </p>
                                                </span>
                                                <p className="mb-0 text-muted mt-1">Uploaded</p>
                                            </li>
                                            {/* ///////////STICK 1//////////// */}
                                            <hr className={`${(fileType.isValidated === "VALIDATED" || fileType.isValidated === "VALIDATION_FAILED" || fileType.isProcessed === "PROCESSING_FAILED" || fileType.isProcessed === "PROCESSED") ? 'activated' : ''}`} />
                                            <li>
                                                <span>
                                                    <p className={`mb-0 ${fileType.isValidated === "VALIDATED" ? 'check-icon' : ''}`}>
                                                        {fileType.isValidated === "NOT_VALIDATED" && <span>2</span>}
                                                        {fileType.isValidated === "VALIDATED" && <img className="fa fa-check img-fluid" src={checkIcon} />}
                                                        {fileType.isValidated === "VALIDATION_FAILED" && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
                                                    </p>
                                                </span>
                                                <p className="mb-0 text-muted mt-1">Validated</p>
                                            </li>
                                            {/* ///////////////////////////////////////////STICK 2///////////////////////////////////// */}
                                            <hr className={`${fileType.isProcessed === "PROCESSED" || fileType.isProcessed === "PROCESSING_FAILED" ? 'activated' : ''}`} />
                                            <li>
                                                <span>
                                                    <p className={`mb-0 ${(fileType.isProcessed === "PROCESSED") ? 'check-icon' : ''}`}>
                                                        {fileType.isProcessed === "NOT_PROCESSED" && <span>3</span>}
                                                        {fileType.isProcessed === "PROCESSED" && <img className="fa fa-check img-fluid" src={checkIcon} />}
                                                        {fileType.isProcessed === "PROCESSING_FAILED" && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
                                                    </p>
                                                </span>
                                                <p className="mb-0 text-muted mt-1">Processed</p>
                                            </li>
                                        </ul>

                                        {/* //////////////////////////////////Failure message//////////////////////////////////// */}
                                        {auditStatus[fileType.statusKey].failureMessage
                                            &&
                                            <p className="error-msg">
                                                <img className="fa fa-exclamation-circle" width="20" src={errorTick} />
                                                &nbsp; {auditStatus[fileType.statusKey].failureMessage}
                                            </p>
                                        }
                                        {/* <p className="success-msg mb-0">
                                            <img className="fa fa-check-square" width="20" src={successTick} />
                                            &nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                        </p> */}

                                        {/* //////////////////////////Result Section//////////////////////////// */}
                                        <div style={{
                                            display: 'flex',
                                            flex: 1, flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            marginBottom: 10
                                        }}>
                                            <p style={{ fontSize: 16, fontFamily: 'HelveticaNeue-Medium', margin: 0 }}>Result</p>
                                            {fileType.isProcessed === "PROCESSED"
                                                &&
                                                <p className="mb-0" style={{ color: '#EB1DB4', fontSize: 15 }}>
                                                    <a
                                                        onClick={() => downloadProcessedResult(fileType.enum)}
                                                        style={{ cursor: 'pointer' }}>Download Processed Result</a>
                                                </p>
                                            }
                                        </div>
                                        <p className="mb-2 success-title">
                                            <img className="fa fa-check-square" src={successTick} />
                                            <span className="font-bold">Success : </span>
                                            {auditStatus[fileType.statusKey].count?.success
                                                ?
                                                `${auditStatus[fileType.statusKey].count?.success} Rows`
                                                :
                                                '-'
                                            }
                                        </p>
                                        <p className="mb-2 error-title">
                                            <img className="fa fa-exclamation-circle" src={errorTick} />
                                            <span className="font-bold">Failure : </span>
                                            {auditStatus[fileType.statusKey].count?.failure
                                                ?
                                                `${auditStatus[fileType.statusKey].count?.failure} Rows`
                                                :
                                                '-'
                                            }
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h5>Audit Status : {auditStatus.audit.status}</h5>
                        <div className="start-audit-btn mt-3 me-3 text-end">
                            <button
                                onClick={handleAudit}
                                className="btn btn-primary"
                                disabled={loading ||
                                    (filesForMonth[filesForMonth.length - 1].isProcessed !== "PROCESSED") ||
                                    auditStatus.audit.status === "AUDITED" ||
                                    auditStatus.audit.status === "PROCESSING"
                                }
                                style={{ background: Colors.secondaryColor }}>
                                <p className="mb-0">Start Audit</p>
                                {
                                    loading
                                    &&
                                    <span
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true">
                                    </span>
                                }
                            </button>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export default FileUpload;