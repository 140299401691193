import { API } from 'aws-amplify'

//////////////////////////////////////////////////////////POWER STATIONS APIS////////////////////////////////////////////////
export const getPowerStations = (pageNumber, perPage) => {
    return API.get('baseURL', '/ae/power-stations', {
        queryStringParameters: {
            page: pageNumber,
            perPage,
            tagged: true
        }
    })
}

export const searchPowerStations = (stationCode) => {
    return API.get('baseURL', '/ae/power-stations', {
        queryStringParameters: {
            page: 1,
            perPage: 100,
            stationCode
        }
    })
}

export const addPowerStation = (body) => {
    return API.post('baseURL', '/ae/power-stations', {
        body
    })
}

export const tagAEToPS = (stationCode) => {
    return API.patch('baseURL', `/ae/power-stations/${stationCode}/tag`)
}

export const updatePowerStation = (body, stationCode) => {
    return API.patch('baseURL', `/ae/power-stations/${stationCode}`, {
        body
    })
}

//////////////////////////////////////////////////////////Feeder APIS////////////////////////////////////////////////
export const getFeedersByPowerStationCode = (stationCode) => {
    return API.get('baseURL', '/ae/feeders', {
        queryStringParameters: {
            tagged: true,
            stationCode,
            perPage:100

        }
    })
}

export const searchFeeders = (mdmCode, stationCode) => {
    return API.get('baseURL', '/ae/feeders', {
        queryStringParameters: {
            page: 1,
            perPage: 100,
            mdmCode,
            // stationCode
        }
    })
}

export const addFeeder = (body, stationCode) => {
    return API.post('baseURL', `/ae/power-stations/${stationCode}/feeders/`, {
        body
    })
}

export const tagAEToFeeder = (stationCode, mdmCode) => {
    return API.patch('baseURL', `/ae/power-stations/${stationCode}/feeders/${mdmCode}/tag`)
}

export const updateFeeder = (body, stationCode, mdmCode) => {
    return API.patch('baseURL', `/ae/power-stations/${stationCode}/feeders/${mdmCode}`, {
        body
    })
}