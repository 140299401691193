import dashboard from '../../assets/img/dashboard-icon.svg'
import mr from '../../assets/img/mr-management-selected-icon.svg'
import feeder from '../../assets/img/mr-management-icon.svg'
import tcaudit from '../../assets/img/tc-audit-default-icon.svg'
import billing from '../../assets/img/group-4773.png'
import disconnection from '../../assets/img/disconnections-icon.png'

export const SidebarData = [
  {
    title: "Dashboard",
    icon: dashboard,
    link: "/dashboard"
  },
  {
    title: "MR Management",
    icon: mr,
    link: "/mr-management"
  },
  {
    title: "Feeder Management",
    icon: feeder,
    link: "/feeders"
  },
  {
    title: "Energy Audit",
    icon: tcaudit,
    link: "/tc-audit",
    hasSubmenu: true,
    isSubmenuShowing: false,
    subMenu: [
      {
        title: "TC Audit",
        link: "/tc-audit",
      },
      {
        title: "TC Details",
        link: "/tc-details",
      },
    ],
  },
  {
    title: "Billing",
    icon: billing,
    link: "/billing"
  },
  {
    title: "Disconnections",
    icon: disconnection,
    link: "/disconnections",
  }
]