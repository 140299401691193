import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AddIcon from '../../assets/img/add-icon@2x.png';
import EditIcon from '../../assets/img/edit-selected-icon@2x.png';
import downIcon from '../../assets/img/other-main-down-arrow.svg';
import upIcon from '../../assets/img/other-main-up-arrow.svg';
import { NotifierContext } from '../../context/NotifierContext';
import { getFeedersByPowerStationCode, getPowerStations } from '../../services/api/feederService';
import { fetchSectionAndDivisionCode } from '../../services/utils/meta';
import Paginator from '../../components/molecules/Paginator';
import NoDataFound from '../../components/atoms/NoDataFound';
import { DateToDDMMYYYY } from '../../services/utils/date';

export default function Listing() {

  const notifier = useContext(NotifierContext)

  const [stationList, setStationList] = useState([]);
  const [feeders, setFeeders] = useState([])
  const [activeItem, setActiveItem] = useState(-1);
  const [selectedFeeder, setSelectedFeeder] = useState([]);
  const [stationLoader, setStationLoader] = useState(true)
  const [feederLoader, setFeederLoader] = useState(false)
  const [pageData, setPageData] = useState({
    "lastPage": 0,
    "currentPage": 0,
    "nextPage": null,
    "previousPage": null,
    "limit": 20,
    "skip": 0
  })

  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [sectionCode, setSectionCode] = useState("----")
  const [subDivisionCode, setSubDivisionCode] = useState("----")

  useEffect(() => {
    fetchStations()
  }, [pageNumber])

  useEffect(() => { fetchMetaData() }, [])


  const fetchMetaData = async () => {
    const sectionCode = await fetchSectionAndDivisionCode('sectionCode')
    const subDivisionCode = await fetchSectionAndDivisionCode('subDivisionCode')
    setSectionCode(sectionCode)
    setSubDivisionCode(subDivisionCode)

  }

  //function to fetch power stations
  const fetchStations = async () => {
    try {
      setStationLoader(true)
      setStationList([])
      const response = await getPowerStations(pageNumber, perPage)
      setStationList(response.data.powerStationList)
      setPageData(response.data.pageData)
      setStationLoader(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setStationLoader(false)
    }
  }

  let history = useHistory();


  //Logic to toggle accordian i.e, open and close accordian and load feeders from api.
  const accordianHandler = async (event, stationCode) => {
    event.preventDefault();
    if (event.target.type === 'button') {
      history.push(event.target.dataset.url);
    }
    var key = event.currentTarget.getAttribute('data-key');
    setFeederLoader(true)
    setActiveItem(activeItem == key ? -1 : key);
    try {
      const response = await getFeedersByPowerStationCode(stationCode)
      setFeeders(response.data.feederList)
      setFeederLoader(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setFeederLoader(false)
    }

  }

  return (
    <React.Fragment>
      <div className="feeder-management-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h1 className="mb-0">Feeder Management</h1>
            </div>

            <div className="col-12 col-md-6 text-right">
              <Link to="/add-substation" className="btn btn-primary add-new-btn">
                <img className="icon-width-height-24" src={AddIcon} />&nbsp;  Add New Station
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <hr className="horizontal-line" />
              <div className="code-section">
                <span>Section Code: <span className="font-medium">{sectionCode}</span></span>
                <hr className="vertical-line mx-4" />
                <span>Sub Division Code: <span className="font-medium">{subDivisionCode}</span></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="feeder-management-list list-view-box mt-2" style={{ paddingTop: 25 }}>
                {/* <div className="search-heading">
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="search-space position-relative me-auto top-feeder-search">
                        <Select
                          name="form-field-name"
                          options={option}
                          onChange={logChange}
                          placeholder="Search by Feeder Name or MDM Code"
                          isSearchable
                        />
                        <img className="img-fluid fa" src={searchIcon} />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="substation-list"> */}
                {stationLoader ?
                  <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>Loading...</div>
                  :
                  stationList.length === 0 ?
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>No Stations Found</div>
                    :
                    stationList.map(function (station, index) {
                      return (
                        <React.Fragment key={index}>
                          <div className="substation-list">
                            <div className="substation-collapse">
                              <a
                                data-key={index}
                                onClick={(e) => accordianHandler(e, station.stationCode)}
                                className="collapse-text p-3"
                                data-bs-toggle="collapse"
                                href={`#collapseSubstation-${index}`}
                                role="button"
                                aria-expanded={index == activeItem ? 'true' : 'false'}
                                aria-controls="collapseExample"
                              >
                                <p className="mb-0  sub-station-title first-sub-title font-medium-500">Station Name: {station.name}</p>
                                <p className="sub-station-title mb-0 second-sub-title font-medium-500">Station Code: {station.stationCode}</p>
                                <img
                                  className="mr-0 icon-width-height edit-station"
                                  src={EditIcon}
                                  style={{ display: activeItem == index ? "none" : "block" }}
                                  onClick={() => history.push({
                                    pathname: `/power-station/${station.stationCode}/edit`,
                                    state: {
                                      action: "editPS",
                                      stationCode: station.stationCode,
                                      stationName: station.name,
                                      voltageClass: station?.voltageClass ?? ""
                                    }
                                  })} />
                                {/* <img
                                  className="mr-0 icon-width-height edit-station"
                                  src={EditIcon}
                                  style={{ display: activeItem == index ? "block" : "none" }}
                                  onClick={() => history.push({
                                    pathname: `/power-station/${station.stationCode}/edit`,
                                    state: {
                                      action: "editPS",
                                      stationCode: station.stationCode,
                                      stationName: station.name,
                                      voltageClass: station?.voltageClass ?? ""
                                    }
                                  })}
                                /> */}
                                <div className="add-feeder me-5">
                                  <button
                                    onClick={() => history.push({
                                      pathname: `/substations/${station.stationCode}/feeders/add`,
                                      state: {
                                        action: "addFeeder",
                                        stationCode: station.stationCode,
                                        stationName: station.name,
                                        voltageClass: station?.voltageClass ?? ""

                                      }
                                    })}
                                    type="button"
                                    className="btn btn-primary">
                                    <img className="img-fluid icon-width-height-24" src={AddIcon} />
                                    Add Feeder
                                  </button>
                                </div>
                                <img className="img-fluid fa fa-angle-down" src={downIcon} />
                                <img className="img-fluid fa fa-angle-up" src={upIcon} />
                              </a>
                            </div>
                            <div className={`collapse collapse-detail ${index == activeItem ? 'show' : ''}`} id={`collapseSubstation-${index}`}>
                              <div className="card card-body border-0 pe-0 ps-0 pt-0 pb-0">
                                <table className="table mb-0 table-responsive">
                                  <thead>
                                    <tr>
                                      <th className="ps-4" scope="col" style={{ borderBottom: '1px solid #dddddd' }}>#</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Feeder Number</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Feeder Name</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Feeder MDM Code</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }}>Added Date</th>
                                      <th scope="col" style={{ borderBottom: '1px solid #dddddd' }} className="text-right pr-20">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      feederLoader ?
                                        <NoDataFound message={"Loading..."} />
                                        :
                                        feeders.length === 0 ?
                                          <NoDataFound message={"No Feeders found!"} />
                                          :
                                          feeders.map((feeder, index) => {
                                            return (
                                              <tr index={feeder.id}>
                                                <td className="pl-15" scope="row">{index + 1}</td>
                                                <td>{feeder.number}</td>
                                                <td>{feeder.name}</td>
                                                <td>{feeder.mdmCode}</td>
                                                <td>{DateToDDMMYYYY(feeder.createdAt)}</td>
                                                <td className="action text-right">
                                                  <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => history.push({
                                                      pathname: `/feeder/${feeder.number}/edit`,
                                                      state: {
                                                        action: "editFeeder",
                                                        stationCode: station.stationCode,
                                                        stationName: station.name,
                                                        voltageClass: station?.voltageClass ?? "",
                                                        feederMDMCode: feeder.mdmCode,
                                                        feederNumber: feeder.number,
                                                        feederName: feeder.name,
                                                      }
                                                    })} className="me-3"
                                                  >
                                                    {/* <i className="fa fa-pencil" aria-hidden="true"></i> */}
                                                    <img className="mr-0 icon-width-height" src={EditIcon} />
                                                  </a>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })
                }
                {/* </div> */}
              </div>
            </div>
          </div>
          <Paginator
            currentPage={pageData.currentPage}
            lastPage={pageData.lastPage}
            onPressNext={() => { setPageNumber(prevState => prevState >= pageData.lastPage ? prevState : prevState + 1) }}
            onPressPrevious={() => { setPageNumber(prevState => prevState <= 1 ? prevState : prevState - 1) }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
