import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DeleteIcon from '../../assets/img/delete-selected-icon@2x.png';
import EditIcon from '../../assets/img/edit-selected-icon@2x.png';
import AddIcon from '../../assets/img/add-icon@2x.png';
import searchIcon from '../../assets/img/search-tc-icon.svg';
import { getMRs } from '../../services/api/mrService';
import { NotifierContext } from '../../context/NotifierContext';
import NoDataFound from '../../components/atoms/NoDataFound';
import { DateToDDMMYYYY } from '../../services/utils/date';
import Paginator from '../../components/molecules/Paginator';
import { fetchSectionAndDivisionCode } from '../../services/utils/meta';

export default function List() {

  const history = useHistory()

  const notifier = useContext(NotifierContext)
  const [mrs, setMR] = useState([]);
  const [searchString, setSearchString] = useState("")
  const [loading, setLoading] = useState(true)
  const [pageData, setPageData] = useState({
    "lastPage": 0,
    "currentPage": 0,
    "nextPage": null,
    "previousPage": null,
    "limit": 20,
    "skip": 0
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [sectionCode, setSectionCode] = useState("----")
  const [subDivisionCode, setSubDivisionCode] = useState("----")


  useEffect(() => { fetchMetaData() }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchMRList()
    }, 500);
    return () => {
      clearTimeout(timer);
    }
  }, [searchString, pageNumber]);

  const fetchMRList = async () => {
    try {
      setLoading(true)
      setMR([])
      const { data } = await getMRs(searchString, pageNumber, perPage)
      setMR(data.MRList);
      setPageData(data.pageData)
      setLoading(false)
    }
    catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const fetchMetaData = async () => {
    const sectionCode = await fetchSectionAndDivisionCode('sectionCode')
    const subDivisionCode = await fetchSectionAndDivisionCode('subDivisionCode')
    setSectionCode(sectionCode)
    setSubDivisionCode(subDivisionCode)

  }


  return (
    <React.Fragment>
      <div className="management-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h1 className="mb-0">MR Management</h1>
            </div>

            <div className="col-12 col-md-6 text-right">
              <Link className="btn btn-primary add-new-btn"
                onClick={() => history.push({
                  pathname: `/mr-management/add`,
                  state: {
                    action: "add"
                  }
                })
                }>
                <img className="icon-width-height-24" src={AddIcon} /> Add New MR
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <hr className="horizontal-line" />
              <div className="code-section">
                <span>Section Code: <span className="font-medium">{sectionCode}</span></span>
                <hr className="vertical-line mx-4" />
                <span>Sub Division Code: <span className="font-medium">{subDivisionCode}</span></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="main-management-table list-view-box mt-2">
                <div className="search-heading pt-0 pb-2">
                  <div className="col-md-4 position-relative">
                    <input type="text"
                      placeholder="MR Name or MR Code"
                      className="form-control mr-0"
                      value={searchString}
                      onChange={(e) => { setSearchString(e.target.value); setPageNumber(1) }}
                    />
                    <img className="fa img-fluid" src={searchIcon} />
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="pl-15" scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">MR Code</th>
                        <th scope="col">MR Phone Number</th>
                        <th scope="col">Added Date</th>
                        <th scope="col" className="text-right pr-20">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        mrs.map((mr, index) => (
                          <tr key={index}>
                            <td className="pl-15" scope="row">{((pageData.currentPage - 1) * perPage) + (index + 1)}</td>
                            <td>{`${mr.name.firstName} ${mr.name.lastName ?? ""}`}</td>
                            <td>{mr.userInfo.mrCode}</td>
                            <td>{mr.phoneNumber}</td>
                            <td>{DateToDDMMYYYY(mr.reportsTo.addedOn)}</td>
                            <td className="text-right pr-20">
                              <Link
                                onClick={() => history.push({
                                  pathname: `/mr-management/${mr.userInfo.mrCode}/edit`,
                                  state: {
                                    action: "edit",
                                    name: mr.name.firstName,
                                    mrCode: mr.userInfo.mrCode,
                                    phoneNumber: mr.phoneNumber.substring(3),
                                    id: mr.userId

                                  }
                                })
                                }>
                                <img className="img-fluid icon-width-height" src={EditIcon} />
                              </Link>
                              <Link onClick={() => alert("Feature coming soon!")}>
                                <img className="img-fluid icon-width-height mr-0" src={DeleteIcon} />
                              </Link>
                            </td>
                          </tr>
                        ))
                      }
                      {
                        mrs.length === 0 && <NoDataFound loading={loading} />
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Paginator
            currentPage={pageData.currentPage}
            lastPage={pageData.lastPage}
            onPressNext={() => { setPageNumber(prevState => prevState >= pageData.lastPage ? prevState : prevState + 1) }}
            onPressPrevious={() => { setPageNumber(prevState => prevState <= 1 ? prevState : prevState - 1) }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
