import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import close from '../../assets/img/close-icon.png'
import { NotifierContext } from '../../context/NotifierContext';
import { addMR, updateMR } from '../../services/api/mrService';
import { addMRValidation, updateMRValidation } from '../../services/validators/mrManagement';

const Add = (props) => {

  const history = useHistory()
  const location = useLocation()
  const notifier = useContext(NotifierContext)


  const [formFields, setFormFields] = useState({
    name: location?.state?.name || '',
    code: location?.state?.mrCode || '',
    phone: location?.state?.phoneNumber || ''
  });
  const [isDisabled, setDisability] = useState(false);


  const handleAddMR = async (event) => {
    event.preventDefault();
    try {
      setDisability(true)
      const validatedData = addMRValidation(formFields)
      await addMR(validatedData)
      setDisability(false)
      notifier.notifySuccess("Successfully added MR")
      history.goBack()
    }
    catch (err) {
      notifier.notifyError(err)
      setDisability(false)
    }
  }

  const handleUpdateMR = async (event) => {
    event.preventDefault();
    try {
      setDisability(true)
      const validatedData = updateMRValidation(formFields, location.state)
      console.log(validatedData)
      await updateMR(location?.state?.id, validatedData)
      setDisability(false)
      notifier.notifySuccess("Successfully Updated MR")
      history.goBack()
    }
    catch (err) {
      notifier.notifyError(err)
      setDisability(false)
    }
  }

  useEffect(() => {
    /** Debouncing */
    const timer = setTimeout(() => {
      let formValue = false
      for (const [key, value] of Object.entries(formFields)) {
        if (value == '') {
          formValue = true
        }

      }
      // setDisability(formValue)
    }, 200);
    return () => {
      clearTimeout(timer);
    }
  }, [formFields])

  const validateField = (name) => {
    let error = '';
    /** Generic test */
    error = formFields[name].length == 0 ? 'Please enter valid value' : '';
    if (name === 'phone') {
      error = formFields[name].length != 10 || isNaN(formFields[name]) ? 'Phone Number Error' : '';
    }
    return error;
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  return (
    <React.Fragment>
      <div className="add-mr-content">
        <div className="container pt-3">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="code-section">
                <span>MR Management&nbsp; |</span>
                <span className="add-new-mr-text"><a href="#" style={{ textTransform: 'capitalize' }}>&nbsp; {location?.state?.action} MR</a></span>
              </div>
              <hr className="horizontal-line" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="management-title">
                <h1 className="mb-0">Add New MR</h1>
              </div>
            </div>
            <div className="col-md-6">
              <div className="close-mr">
                <div onClick={history.goBack} className="btn btn-primary bg-transparent">
                  <img src={close} /><p className="mb-0">Close</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="main-management-form p-3 mb-3">
                <form>
                  <div className="forms-input">
                    <div className="inner-add-part">
                      <div className="row">
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">MR Name</label>
                          <input name="name" maxLength={30} onChange={handleChange} value={formFields['name']} className="form-control" type="text" placeholder="MR Name" />
                        </div>
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">MR Code</label>
                          <input
                            name="code"
                            value={formFields['code']}
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                            maxLength={15}
                            placeholder="MR Code"
                            disabled={location.state.action === "edit"}
                          />
                        </div>
                        <div className="col-md-3">
                          <label className="form-label font-regular-400">Phone Number</label>
                          <input maxLength={10} minLength={10} name="phone" onChange={handleChange} value={formFields['phone']} className="form-control" type="text" placeholder="Phone Number" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-1 text-end mr-submit-btn">
                        <button
                          type="submit"
                          disabled={isDisabled ?true: (formFields.name && formFields.phone && formFields.code)?false:true  }
                          onClick={location?.state?.action === "add" ? handleAddMR : handleUpdateMR}
                          className={`btn btn-primary ${!isDisabled ? '' : 'btn-grey'}`}>
                          Submit{'\t'}
                          {isDisabled && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Add;