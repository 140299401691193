import React, { Fragment, useContext, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import downIcon from '../../../assets/img/other-main-down-arrow.svg';
import upIcon from '../../../assets/img/main-tab-arrow-up.svg';
import { Link } from 'react-router-dom';
import FileUpload from './SubComponents/FileUpload';
import AuditResult from './SubComponents/AuditResult';
import { AuditResultContext } from '../../../context/AuditResultContext';

export default function TCAudit() {

  const { activeItem, setActiveItem } = useContext(AuditResultContext)
  const [refreshedStatus, setRefreshedStatus] = useState(false)

  const accordianHandler = (event) => {
    event.preventDefault();
    var key = event.currentTarget.getAttribute('data-panel');
    if (activeItem == key) {
      setActiveItem('');
    } else {
      setActiveItem(key);
    }
  }

  return (
    <Fragment>
      <div className="tc-audit-file-management-content">
        <div className="container">
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="comman-head">
                <div className="management-title">
                  <h1>TC Audit</h1>
                </div>
                <span style={{ marginRight: 15, cursor: "pointer" }} onClick={(e) => setRefreshedStatus(!refreshedStatus)}>Refresh Status</span>

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tc-audit-file-management-list">
                <div className="tc-audit-file-list mb-3">
                  <div className="substation-collapse tc-audit-file-active">
                    <Link onClick={accordianHandler} data-panel="audit-panel" className="collapse-text" role="button" aria-expanded="false" aria-controls="collapseAuditFile-1">
                      <div className="tc-audit-collpase-title">
                        <p className="mb-0 me-auto sub-station-title font-medium-500">TC Audit Files</p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {activeItem === 'audit-panel' ?
                          <img className="fa fa-angle-up" src={upIcon} />
                          : <img className="fa fa-angle-down" src={downIcon} />
                        }
                      </div>
                    </Link>
                  </div>
                  <div className={`collapse collapse-detail ${activeItem == 'audit-panel' ? 'show' : ''}`} id="collapseAuditFile-1">
                    <FileUpload refreshStatus={refreshedStatus} />
                  </div>
                </div>
                <div className="tc-audit-file-list audit-result-list mb-3">
                  <div className="substation-collapse tc-audit-file-active p-3">
                    <a onClick={accordianHandler} data-panel="audit-result-panel" className="collapse-text" role="button" aria-expanded="false" aria-controls="collapseAuditFile-2">
                      <div className="tc-audit-collpase-title">
                        <p className="mb-0 me-auto sub-station-title font-medium-500">Audit Result</p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                        {activeItem === 'audit-result-panel' ?
                          <img className="fa fa-angle-up" src={upIcon} />
                          : <img className="fa fa-angle-down" src={downIcon} />
                        }
                      </div>
                    </a>
                  </div>
                  <div className={`collapse collapse-detail ${activeItem == 'audit-result-panel' ? 'show' : ''}`} id="collapseAuditFile-2">
                    <AuditResult />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}












//////////////////////////////////V2/////////////////////////////////////////////////////////
// import React, { Fragment, useContext, useEffect, useState } from 'react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import filterImage from '../../../assets/img/filter.png';
// import filterIcon from '../../../assets/img/filter.png';
// import downloadImage from '../../../assets/img/file_download-24px.png';
// import UploadImage from '../../../assets/img/upload-icon@2x.png';
// import ViewImage from '../../../assets/img/view-selected-icon@2x.png';
// import downIcon from '../../../assets/img/other-main-down-arrow.svg';
// import fileIcon from '../../../assets/img/file-icon.svg';
// import checkIcon from '../../../assets/img/step-blue-circle-selected.svg';
// import errorCheckIcon from '../../../assets/img/step-error-icon.svg';
// import successTick from '../../../assets/img/successful-small-cricle-icon.svg';
// import errorTick from '../../../assets/img/error-red-small-circle-icon.svg';
// import upIcon from '../../../assets/img/other-main-up-arrow.svg';
// import searchIcon from '../../../assets/img/search-tc-icon.svg';
// import { Link } from 'react-router-dom';
// import Filter from '../../Shared/Filter';
// import { NotifierContext } from '../../../context/NotifierContext';
// import { getDownLoadURL, getPresignedURL, getUploadStatus, uploadFile } from '../../../services/api/tcAuditService';
// import { AuditMonthContext } from '../../../context/AuditMonthContext';

// export default function TCAudit() {

//   const notifier = useContext(NotifierContext);
//   const { auditMonthYear } = useContext(AuditMonthContext)

//   const displayStyle = {
//     display: "-webkit-inline-box",
//   };
//   const transformersArray = [
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '3 %'
//     },
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '3 %'
//     },
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '3 %'
//     },
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '3 %'
//     },
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '3 %'
//     },
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '21 %'
//     },
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '80 %'
//     },
//     {
//       mr_code: '123456',
//       reading_day: 1,
//       tc_no: 'TC 125',
//       tc_name: 'Rajajinagar',
//       installations: '540',
//       tc_consumtion: '540 Units',
//       installation_consumtion: '540 Units',
//       loss: '10 %'
//     }
//   ];

//   const [transformers, setTranformers] = useState(transformersArray);

//   const filesForMonthArray = [
//     {
//       title: "TC Details",
//       enum: 'tcdetails',
//       description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//       isUploaded: false,
//       statusKey: 'TCDetails',
//       fileStatus: "NOT_UPLOADED",
//       downloadSampleFile: 'https://bescom-pandas-test-dev.s3.ap-south-1.amazonaws.com/Sample_file_formats/TC_Create_Sample.xlsx'
//     },
//     {
//       title: "Installation Status Report",
//       enum: "isr",
//       description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//       isUploaded: false,
//       statusKey: 'ISR',
//       fileStatus: "NOT_UPLOADED",
//       downloadSampleFile: false
//     },
//     {
//       title: "TC RR Number Tagging",
//       enum: 'tcrrtag',
//       description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//       isUploaded: false,
//       statusKey: 'TCRRTag',
//       fileStatus: "NOT_UPLOADED",
//       downloadSampleFile: 'https://bescom-pandas-test-dev.s3.ap-south-1.amazonaws.com/Sample_file_formats/TC_RR_Tag_Sample.xlsx'
//     },
//     // {
//     //   title: "Unbilled Report",
//     //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     //   isUploaded: false,
//     //   isValidated: false,
//     //   isProcessed: false,
//     //   isUploadFailed: false,
//     //   isValidationFailed: false,
//     //   isProcessingFailed: false,
//     //   downloadSampleFile: false
//     // },
//     // {
//     //   title: "MNR Report",
//     //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     //   isUploaded: false,
//     //   isValidated: false,
//     //   isProcessed: false,
//     //   isUploadFailed: false,
//     //   isValidationFailed: false,
//     //   isProcessingFailed: false,
//     //   downloadSampleFile: false
//     // },
//     // {
//     //   title: "Vacant  Report",
//     //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     //   isUploaded: false,
//     //   isValidated: false,
//     //   isProcessed: false,
//     //   isUploadFailed: false,
//     //   isValidationFailed: false,
//     //   isProcessingFailed: false,
//     //   downloadSampleFile: false
//     // },
//     // {
//     //   title: "Doorlock Report",
//     //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     //   isUploaded: false,
//     //   isValidated: false,
//     //   isProcessed: false,
//     //   isUploadFailed: false,
//     //   isValidationFailed: false,
//     //   isProcessingFailed: false,
//     //   downloadSampleFile: false
//     // },
//     // {
//     //   title: "Zero Consumption Report",
//     //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     //   isUploaded: false,
//     //   isValidated: false,
//     //   isProcessed: false,
//     //   isUploadFailed: false,
//     //   isValidationFailed: false,
//     //   isProcessingFailed: false,
//     //   downloadSampleFile: false
//     // },
//     // {
//     //   title: "Demand Consumption Balance (DCB) Report",
//     //   description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     //   isUploaded: false,
//     //   isValidated: false,
//     //   isProcessed: false,
//     //   isUploadFailed: false,
//     //   isValidationFailed: false,
//     //   isProcessingFailed: false,
//     //   downloadSampleFile: false
//     // }
//   ];

//   const uploadSuccessIcon = (fileType) => (fileType.fileStatus === 'VALIDATED' || fileType.fileStatus === 'VALIDATION_FAILED' || fileType.fileStatus === 'PROCESSED' || fileType.fileStatus === 'PROCESSING_FAILED' || fileType.fileStatus === 'VALIDATED' || fileType.fileStatus === 'UPLOADED')

//   const [filesForMonth, setFilesForMonth] = useState(filesForMonthArray);
//   const [activeUpload, setActiveUpload] = useState(''); //state to open stepper
//   const [activeItem, setActiveItem] = useState('audit-panel');
//   const [activeDataTab, setDataTab] = useState('total-transformers');
//   const [fileBeingUploaded, setFileBeingUploaded] = useState(-1);

//   const [activeTransformerAuditedButton, setActiveTransformerAuditedButton] = useState('');
//   const [activeTransformerUnauditedButton, setActiveTransformerUnauditedButton] = useState('');

//   const [filterVisible, setFilterVisible] = useState(false);


//   const [uploadStatus, setUploadStatus] = useState({})
//   const [loading, setLoading] = useState(true)

//   useEffect(() => { fetchUploadStatus() }, [auditMonthYear])

//   const fetchUploadStatus = async () => {
//     try {
//       setLoading(true)
//       const response = await getUploadStatus(auditMonthYear)
//       setUploadStatus(response.data)
//       const updatedFilesForMonth = [...filesForMonth]
//       updatedFilesForMonth[0].fileStatus = response.data.TCDetails.status
//       updatedFilesForMonth[1].fileStatus = response.data.ISR.status
//       updatedFilesForMonth[2].fileStatus = response.data.TCRRTag.status
//       setFilesForMonth(updatedFilesForMonth)
//       setUploadButtonStatus()
//       setLoading(false)
//     }
//     catch (err) {
//       notifier.notifyError(err)
//     }
//   }

//   //Function to enable and disable upload button based on file status
//   const setUploadButtonStatus = () => {
//     filesForMonth.some((file, index) => {
//       //Disable upload button and then continue
//       // setFileBeingUploaded(-1)

//       //First File | next file not uploaded | First file is not uploaded or not processed or not validated
//       if (index === 0 && filesForMonth[index + 1].fileStatus === "NOT_UPLOADED" && (file.fileStatus === "NOT_UPLOADED" || file.fileStatus === "PROCESSING_FAILED" || file.fileStatus === "VALIDATION_FAILED")) {
//         console.log(file.title)
//         setFileBeingUploaded(0)
//         return true;
//       }
//       //Last File | prev file should not be not uploaded | Prev month should not be Not uploaded | prev month shud not be validation failed or processing failed
//       if ((index === filesForMonth.length - 1) && (filesForMonth[index - 1].fileStatus !== "NOT_UPLOADED" && filesForMonth[index - 1].fileStatus !== "PROCESSING_FAILED" && filesForMonth[index - 1].fileStatus !== "VALIDATION_FAILED")) {
//         console.log(file.enum, index)
//         setFileBeingUploaded(index)
//         return true
//       }

//       //Last file | prev month is not uploaded.
//       if ((index === filesForMonth.length - 1) && filesForMonth[index - 1].fileStatus === "NOT_UPLOADED") {
//         return true
//       }

//       //Not last file | Next month file is not uploaded | File not uploaded or processing failed or validation failed.
//       if (index !== filesForMonth.length - 1 && filesForMonth[index + 1].fileStatus === "NOT_UPLOADED" && (file.fileStatus === "PROCESSING_FAILED" || file.fileStatus === "VALIDATION_FAILED" || file.fileStatus === "NOT_UPLOADED")) {
//         setFileBeingUploaded(index)
//       }
//     })
//   }



//   const auditedTransformersButtonHandler = (tag) => {
//     setActiveTransformerAuditedButton(tag);
//     let transformersArrayLocal = [...transformersArray];
//     const randomNumber = Math.floor(Math.random() * 8) + 1;
//     setTranformers(transformersArrayLocal.slice(0, randomNumber));
//   }

//   const unauditedTransformersButtonHandler = (tag) => {
//     setActiveTransformerUnauditedButton(tag);
//     let transformersArrayLocal = [...transformersArray];
//     const randomNumber = Math.floor(Math.random() * 8) + 1;
//     setTranformers(transformersArrayLocal.slice(0, randomNumber));
//   }

//   const accordianHandler = (event) => {
//     event.preventDefault();
//     var key = event.currentTarget.getAttribute('data-panel');
//     if (activeItem == key) {
//       setActiveItem('');
//     } else {
//       setActiveItem(key);
//     }
//   }

//   const onResultTabHandler = (tab) => {
//     setDataTab(tab);
//   }

//   const fileBrowserHandler = async (event) => {
//     // console.log(event.target.dataset.enum)
//     // setActiveUpload(event.target.dataset.title);
//     // const filesForMonthClone = [...filesForMonth];
//     // setTimeout(() => {
//     //   filesForMonthClone.forEach(element => {
//     //     if (element.title === event.target.dataset.title) {
//     //       element.isUploaded = true;
//     //       element.isValidated = true;
//     //       element.isProcessed = true;
//     //     }
//     //   });
//     //   setActiveUpload(event.target.dataset.title);
//     //   setFilesForMonth(filesForMonthClone);
//     //   setFileBeingUploaded(fileBeingUploaded + 1);
//     // }, 1000);

//     try {
//       const fileExtension = event.target.files[0].name.split(".").pop()
//       if (fileExtension !== 'xlsx' && fileExtension !== 'xlsb' && fileExtension !== 'xls') {
//         throw new Error("This type of file is not allowed to upload. You can upload only files which have xls,xlsx,xlsb extensions")
//       }
//       const response = await getPresignedURL(event.target.dataset.enum, auditMonthYear)
//       await uploadFile(event.target.files[0], response.data[fileExtension])
//       notifier.notifySuccess(`Successfully Uploaded the ${event.target.dataset.title} file.`)
//       // setFileBeingUploaded(fileBeingUploaded + 1);
//     }
//     catch (err) {
//       notifier.notifyError(err)
//     }
//   }

//   const downloadProcessedResult = async (fileName) => {
//     try {
//       const response = await getDownLoadURL(fileName, auditMonthYear)
//       window.location.href = response.data
//     }
//     catch (err) {
//       notifier.notifyError(err)
//     }

//   }


//   const filterHandler = () => {
//     setFilterVisible(!filterVisible);
//   }

//   const applyFilters = (filters) => {
//     console.log(filters);
//   }



//   return (
//     <>
//       {loading ? <div
//         style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', minHeight: '80vh' }}>
//         Loading...
//       </div> :
//         <Fragment>
//           <Filter enabledFilters={{ durations: false, tariffs: false, tags: false, remarks: false, mrCodes: true }} isFilterVisible={filterVisible} onFilterClose={filterHandler} onFiltersApply={applyFilters} />
//           <div className="tc-audit-file-management-content">
//             <div className="container">
//               <div className="row mb-3">
//                 <div className="col-md-12">
//                   <div className="comman-head">
//                     <div className="management-title">
//                       <h1>TC Audit</h1>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="tc-audit-file-management-list">
//                     <div className="tc-audit-file-list mb-3">
//                       <div className="substation-collapse tc-audit-file-active">
//                         <Link
//                           // onClick={accordianHandler}
//                           data-panel="audit-panel"
//                           className="collapse-text"
//                           role="button"
//                           aria-expanded={activeItem ? true : false}
//                           aria-controls="collapseAuditFile-1"
//                         >
//                           <div className="tc-audit-collpase-title">
//                             <p className="mb-0 me-auto sub-station-title font-medium-500">TC Audit Files</p>
//                           </div>
//                           <span
//                             style={{ display: 'flex', alignSelf: 'center', marginRight: 20 }}
//                             onClick={fetchUploadStatus}>Refresh</span>
//                           <img className="img-fluid" className="fa fa-angle-down" src={downIcon} />
//                           <img className="img-fluid" className="fa fa-angle-up" src={upIcon} />
//                         </Link>
//                       </div>
//                       <div className={`collapse collapse-detail ${activeItem == 'audit-panel' ? 'show' : ''}`} id="collapseAuditFile-1">
//                         <div className="card border-0">
//                           {
//                             filesForMonth.map((fileType, index) => {
//                               return (
//                                 <div key={index} className="card-body mb-3">
//                                   <div className="tc-top-detail row">
//                                     <div className="tc-details-left col-12 col-md-8 col-xl-10">
//                                       <p className="mb-2 tc-title">{fileType.title} ( {fileType.fileStatus} )</p>
//                                       <p className="mb-2">{fileType.description}</p>
//                                       {
//                                         fileType.downloadSampleFile &&
//                                         (<span>
//                                           <a href={fileType.downloadSampleFile} style={displayStyle}>
//                                             <img className="fa fa-file-text img-fluid" src={fileIcon} />&nbsp;
//                                             <p className="mb-0 download-link">Download sample file</p>
//                                           </a>
//                                         </span>)
//                                       }
//                                     </div>
//                                     <div className="tc-upload-btn col-12 col-md-4 col-xl-2">
//                                       {
//                                         index == fileBeingUploaded &&
//                                         (<input data-title={fileType.title} data-enum={fileType.enum} className="form-control" type="file" onChange={fileBrowserHandler} />)
//                                       }
//                                       <button className={`btn btn-primary ${index == fileBeingUploaded ? 'upload-load-active' : ''}`}>
//                                         {/* <i className="fa fa-upload" aria-hidden="true"></i> */}
//                                         <img className="img-fluid icon-width-height-24" src={UploadImage} /> Upload
//                                       </button>
//                                       {fileType.title === "TC Details" && fileBeingUploaded === 0 && <p style={{ cursor: 'pointer' }} onClick={() => { setFileBeingUploaded(1) }}>Skip {'>>'} </p>}
//                                       {/* <button href="#" className="btn btn-primary upload-load-active justify-content-center">
//                                     <i className="fa fa-undo" aria-hidden="true"></i>
//                                   </button> */}
//                                     </div>
//                                   </div>
//                                   <div className={`${fileType.fileStatus !== 'NOT_UPLOADED' ? '' : 'd-none'}`}>
//                                     <ul className="tc-progress-bar mt-4">
//                                       <li>
//                                         <span>
//                                           <p className={`mb-0 ${'check-icon'} ${fileType.fileStatus === 'NOT_UPLOADED' ? 'cross-icon' : ''}`}>
//                                             {/* {!fileType.isUploaded && <span>1</span>} */}
//                                             {uploadSuccessIcon(fileType) && <img className="fa fa-check img-fluid" src={checkIcon} />}
//                                             {fileType.fileStatus === 'NOT_UPLOADED' && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
//                                           </p>
//                                         </span>
//                                         <p className="mb-0 text-muted mt-1">Uploaded</p>
//                                       </li>
//                                       <hr className={`${fileType.fileStatus === 'VALIDATED' || fileType.fileStatus === 'VALIDATION_FAILED' || fileType.fileStatus === 'PROCESSED' || fileType.fileStatus === 'PROCESSING_FAILED' ? 'activated' : ''}`} />
//                                       <li>
//                                         <span>
//                                           <p className={`mb-0 ${fileType.fileStatus === 'VALIDATED' || fileType.fileStatus === 'PROCESSED' || fileType.fileStatus === 'PROCESSING_FAILED' ? 'check-icon' : ''} ${fileType.fileStatus === 'VALIDATION_FAILED' ? 'cross-icon' : ''}`}>
//                                             {fileType.fileStatus === 'UPLOADED' && <span>2</span>}
//                                             {(fileType.fileStatus === 'VALIDATED' || fileType.fileStatus === 'PROCESSED' || fileType.fileStatus === 'PROCESSING_FAILED') && <img className="fa fa-check img-fluid" src={checkIcon} />}
//                                             {fileType.fileStatus === 'VALIDATION_FAILED' && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
//                                           </p>
//                                         </span>
//                                         <p className="mb-0 text-muted mt-1">Validated</p>
//                                       </li>
//                                       <hr className={`${fileType.fileStatus === "PROCESSED" || fileType.fileStatus === "PROCESSING_FAILED" ? 'activated' : ''}`} />
//                                       <li>
//                                         <span>
//                                           <p className={`mb-0 ${fileType.fileStatus === "PROCESSED" ? 'check-icon' : ''} ${fileType.fileStatus === "PROCESSING_FAILED" ? 'cross-icon' : ''}}`}>
//                                             {(fileType.fileStatus === 'VALIDATED' || fileType.fileStatus === 'UPLOADED' || fileType.fileStatus === 'VALIDATION_FAILED') && <span>3</span>}
//                                             {fileType.fileStatus === 'PROCESSED' && <img className="fa fa-check img-fluid" src={checkIcon} />}
//                                             {fileType.fileStatus === 'PROCESSING_FAILED' && <img className="fa fa-times img-fluid" src={errorCheckIcon} />}
//                                           </p>
//                                         </span>
//                                         <p className="mb-0 text-muted mt-1">Processed</p>
//                                       </li>
//                                     </ul>
//                                     {fileType.fileStatus === 'PROCESSED' && <p className="view-success-screen">
//                                       <a
//                                         onClick={() => downloadProcessedResult(fileType.enum)}
//                                         style={{ cursor: 'pointer' }}>Download Processed Result</a>
//                                     </p>}
//                                     <p className="error-msg"><img className="fa fa-exclamation-circle" width="20" src={errorTick} />&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
//                                     <p className="success-msg mb-0"><img className="fa fa-check-square" width="20" src={successTick} />&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
//                                     <p className="result-title">Result</p>
//                                     <p className="mb-2 success-title"><img className="fa fa-check-square" src={successTick} /> <span className="font-bold">Success:</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
//                                     <p className="mb-2 error-title"><img className="fa fa-exclamation-circle" src={errorTick} /> <span className="font-bold">Failure:</span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
//                                   </div>
//                                 </div>
//                               )
//                             })
//                           }
//                           {/* <div className="start-audit-btn mt-3 me-3 text-end">
//                         <a href="#" className="btn btn-primary">
//                           <p className="mb-0">Start Audit</p>
//                         </a>
//                       </div> */}
//                         </div>
//                       </div>
//                     </div>
//                     {/* ---------------------AUDIT RESULT CODE GOES HERE------------------------ */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Fragment>}</>
//   )
// }








// // -------------AUDIT RESULT CODE-------------------
// // {/* <div className="tc-audit-file-list audit-result-list mb-3">
// //   <div className="substation-collapse tc-audit-file-active p-3">
// //     <a href="#" onClick={accordianHandler} data-panel="audit-result-panel" className="collapse-text" role="button" aria-expanded="false" aria-controls="collapseAuditFile-2">
// //       <div className="tc-audit-collpase-title">
// //         <p className="mb-0 me-auto sub-station-title font-medium-500">Audit Result</p>
// //       </div>
// //       <img className="fa fa-angle-down" src={downIcon} />
// //       <img className="fa fa-angle-up" src={upIcon} />
// //     </a>
// //   </div>
// //   <div className={`collapse collapse-detail ${activeItem == 'audit-result-panel' ? 'show' : ''}`} id="collapseAuditFile-2">
// //     <div className="container audit-result-section">
// //       <div className="row">
// //         <div className="col-md-12">
// //           <ul className="nav nav-tabs" id="myTab" role="tablist">
// //             <li className="nav-item me-2" role="presentation">
// //               <button onClick={() => onResultTabHandler('total-transformers')} className={`nav-link ${activeDataTab == 'total-transformers' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
// //                 <p className="mb-0 font-medium-500">Total Transformers</p>
// //                 <hr />
// //                 <h4 className="mb-0">60</h4>
// //               </button>
// //             </li>
// //             <li className="nav-item me-2" role="presentation">
// //               <button onClick={() => onResultTabHandler('transformers-audited')} className={`nav-link ${activeDataTab == 'transformers-audited' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
// //                 <p className="mb-0 font-medium-500">Transformers Audited</p>
// //                 <hr />
// //                 <h4 className="mb-0">55</h4>
// //               </button>
// //             </li>
// //             <li className="nav-item me-2" role="presentation">
// //               <button onClick={() => onResultTabHandler('transformers-unaudited')} className={`nav-link ${activeDataTab == 'transformers-unaudited' ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
// //                 <p className="mb-0 font-medium-500">Transformers Unaudited</p>
// //                 <hr />
// //                 <h4 className="mb-0">45</h4>
// //               </button>
// //             </li>
// //           </ul>
// //         </div>
// //         <div className="col-xl-12 col-lg-12 col-md-9">
// //           <div className="tab-content" id="myTabContent">
// //             <div className={`tab-pane fade show ${activeDataTab == 'total-transformers' ? 'show active' : ''} first-toggle-tab mb-4`} role="tabpanel" aria-labelledby="home-tab">
// //               <div className="row top-transformer-tab">
// //                 <div className="col-md-12">
// //                   <div className="main-tc-table list-view-box ">
// //                     <div className="search-heading">
// //                       <div className="search-space position-relative me-auto">
// //                         <input type="text" placeholder="Search by TC Number or Name" className="form-control" />
// //                         <img className="img-fluid fa" src={searchIcon} />
// //                       </div>
// //                       <div className="download-result">
// //                         <a href="#" className="download-bt me-3">
// //                           <img src={downloadImage} className="me-2" />
// //                           <p className="mb-0 font-regular-400">Download Result</p>
// //                         </a>
// //                         <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
// //                           <img src={filterIcon} className="" width="18" height="12" />Filter
// //                         </button>
// //                       </div>
// //                     </div>

// //                     <div className="table-responsive">
// //                       <table className="table mt-0">
// //                         <thead>
// //                           <tr>
// //                             <th className="ps-4" scope="col">#</th>
// //                             <th scope="col">MR Code</th>
// //                             <th scope="col">Reading Day</th>
// //                             <th scope="col">TC No</th>
// //                             <th scope="col">TC Name</th>
// //                             <th scope="col">Installations</th>
// //                             <th scope="col">TC Consumption</th>
// //                             <th scope="col">Installations Consumption</th>
// //                             <th scope="col">% Loss</th>
// //                             <th scope="col">View</th>
// //                           </tr>
// //                         </thead>
// //                         <tbody>
// //                           {
// //                             transformers.map((transformer, index) => {
// //                               return (
// //                                 <tr>
// //                                   <td className="ps-4" scope="row">{index + 1}</td>
// //                                   <td>{transformer.mr_code}</td>
// //                                   <td>{transformer.reading_day}</td>
// //                                   <td>{transformer.tc_no}</td>
// //                                   <td>{transformer.tc_name}</td>
// //                                   <td>{transformer.installations}</td>
// //                                   <td>{transformer.tc_consumtion}</td>
// //                                   <td>{transformer.installation_consumtion}</td>
// //                                   <td>{transformer.loss}</td>
// //                                   <td>
// //                                     <a href="#">
// //                                       {/* <i className="fa fa-eye" aria-hidden="true"></i> */}
// //                                       <img className="img-fluid icon-width-height" src={ViewImage} />
// //                                     </a>
// //                                   </td>
// //                                 </tr>
// //                               )
// //                             })
// //                           }
// //                         </tbody>
// //                       </table>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-12">
// //                   <nav aria-label="Page navigation example" className="pagination-1 pt-3 pb-3">
// //                     <ul className="pagination mb-0">
// //                       <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
// //                       <li className="page-item"><a className="page-link" href="#">1</a></li>
// //                       <li className="page-item previous-item"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
// //                     </ul>
// //                   </nav>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className={`tab-pane fade second-toggle-tab mb-4 ${activeDataTab == 'transformers-audited' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="profile-tab">
// //               <div className="row top-transformer-tab">
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-4">
// //                     <div className={`cp card-body ${activeTransformerAuditedButton == 'negative-loss' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('negative-loss')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">Negative Loss</p>
// //                       <hr />
// //                       <h4 className="mb-0">60</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-5">
// //                     <div className={`cp card-body ${activeTransformerAuditedButton == 'zero-six' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('zero-six')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">0 - 6%</p>
// //                       <hr />
// //                       <h4 className="mb-0">50</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-6">
// //                     <div className={`cp card-body ${activeTransformerAuditedButton == 'six-ten' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('six-ten')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">6 - 10%</p>
// //                       <hr />
// //                       <h4 className="mb-0">45</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-7">
// //                     <div className={`cp card-body ${activeTransformerAuditedButton == 'ten-fifteen' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('ten-fifteen')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">10 - 15%</p>
// //                       <hr />
// //                       <h4 className="mb-0">35</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-8">
// //                     <div className={`cp card-body ${activeTransformerAuditedButton == 'fifteen-twenty' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('fifteen-twenty')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">15 - 20%</p>
// //                       <hr />
// //                       <h4 className="mb-0">25</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-8">
// //                     <div className={`cp card-body ${activeTransformerAuditedButton == 'above-twenty' ? 'active-card-body' : ''}`} onClick={(e) => auditedTransformersButtonHandler('above-twenty')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">Above 20%</p>
// //                       <hr />
// //                       <h4 className="mb-0">45</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-12">
// //                   <div className="main-tc-table mt-4 list-view-box">
// //                     <div className="search-heading">
// //                       <div className="search-space position-relative me-auto">
// //                         <input type="text" placeholder="Search by TC Number or Name" className="form-control" />
// //                         <img className="img-fluid fa" src={searchIcon} />
// //                       </div>
// //                       <div className="download-result">
// //                         <a href="#" className="download-bt me-3">
// //                           <img src={downloadImage} className="me-2" />
// //                           <p className="mb-0 font-regular-400">Download Result</p>
// //                         </a>
// //                         <button onClick={filterHandler} type="button" className="btn btn-primary filter-btn">
// //                           <img src={filterIcon} className="" width="18" height="12" />Filter
// //                         </button>
// //                       </div>
// //                     </div>

// //                     <div className="table-responsive">
// //                       <table className="table mt-0">
// //                         <thead>
// //                           <tr>
// //                             <th className="ps-4" scope="col">#</th>
// //                             <th scope="col">MR Code</th>
// //                             <th scope="col">Reading Day</th>
// //                             <th scope="col">TC No</th>
// //                             <th scope="col">TC Name</th>
// //                             <th scope="col">Installations</th>
// //                             <th scope="col">TC Consumption</th>
// //                             <th scope="col">Installations Consumption</th>
// //                             <th scope="col">% Loss</th>
// //                             <th scope="col">View</th>
// //                           </tr>
// //                         </thead>
// //                         <tbody>
// //                           {
// //                             transformers.map((transformer, index) => {
// //                               return (
// //                                 <tr>
// //                                   <td className="ps-4" scope="row">{index + 1}</td>
// //                                   <td>{transformer.mr_code}</td>
// //                                   <td>{transformer.reading_day}</td>
// //                                   <td>{transformer.tc_no}</td>
// //                                   <td>{transformer.tc_name}</td>
// //                                   <td>{transformer.installations}</td>
// //                                   <td>{transformer.tc_consumtion}</td>
// //                                   <td>{transformer.installation_consumtion}</td>
// //                                   <td>{transformer.loss}</td>
// //                                   <td>
// //                                     <Link to={`/tc-audit/${index + 1}?status=audited`}>
// //                                       <img className="img-fluid icon-width-height" src={ViewImage} />
// //                                     </Link>
// //                                   </td>
// //                                 </tr>
// //                               )
// //                             })
// //                           }
// //                         </tbody>
// //                       </table>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-12">
// //                   <nav aria-label="Page navigation example" className="pagination-1 pt-3 pb-3">
// //                     <ul className="pagination mb-0">
// //                       <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
// //                       <li className="page-item"><a className="page-link" href="#">1</a></li>
// //                       <li className="page-item previous-item mx-3"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
// //                     </ul>
// //                   </nav>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className={`tab-pane fade third-toggle-tab mb-4 ${activeDataTab == 'transformers-unaudited' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="contact-tab">
// //               <div className="row top-transformer-tab">
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-4">
// //                     <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-intagged' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-intagged')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">TC's Intagged</p>
// //                       <hr />
// //                       <h4 className="mb-0 font-medium-500">60</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-5">
// //                     <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-without-fr' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-without-fr')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">TC's Without FR</p>
// //                       <hr />
// //                       <h4 className="mb-0 font-medium-500">50</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-4">
// //                   <div className="card custom-card-tc-6">
// //                     <div className={`cp card-body ${activeTransformerUnauditedButton == 'tc-with-remarks' ? 'active-card-body' : ''}`} onClick={(e) => unauditedTransformersButtonHandler('tc-with-remarks')}>
// //                       <p className="card-text mb-0 me-auto font-regular-400">TC's With Remarks</p>
// //                       <hr />
// //                       <h4 className="mb-0 font-medium-500">45</h4>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-12">
// //                   <div className="main-tc-table list-view-box mt-4">
// //                     <div className="search-heading">
// //                       <div className="search-space position-relative me-auto">
// //                         <input type="text" placeholder="Search by TC Number or Name" className="form-control" />
// //                         <img className="img-fluid fa" src={searchIcon} />
// //                       </div>
// //                       <div className="download-result">
// //                         <a href="#" className="download-bt me-3">
// //                           <img src={downloadImage} className="me-2" />
// //                           <p className="mb-0">Download Result</p>
// //                         </a>
// //                         <button type="button" className="btn btn-primary filter-btn">
// //                           <img src={filterIcon} className="" width="18" height="12" />Filter
// //                         </button>
// //                       </div>
// //                     </div>

// //                     <div className="table-responsive">
// //                       <table className="table mt-0">
// //                         <thead>
// //                           <tr>
// //                             <th className="ps-4" scope="col">#</th>
// //                             <th scope="col">TC No</th>
// //                             <th scope="col">TC Name</th>
// //                             <th scope="col">Installations</th>
// //                             <th scope="col">TC Consumption</th>
// //                             <th scope="col">Installations Consumption</th>
// //                             <th scope="col" className="pr-20">Action</th>
// //                           </tr>
// //                         </thead>
// //                         <tbody>
// //                           {
// //                             transformers.map((transformer, index) => {
// //                               return (
// //                                 <tr>
// //                                   <td className="ps-4" scope="row">{index + 1}</td>
// //                                   <td className="font-regular-400">{transformer.tc_no}</td>
// //                                   <td className="font-regular-400">{transformer.tc_name}</td>
// //                                   <td className="font-regular-400">{transformer.installations}</td>
// //                                   <td className="font-regular-400">{transformer.tc_consumtion}</td>
// //                                   <td className="font-regular-400">{transformer.installation_consumtion}</td>
// //                                   <td>
// //                                     <Link className="audit me-2" to={`/tc-audit/${index + 1}?status=unaudited`}>Audit</Link>
// //                                     <Link to={`/tc-audit/${index + 1}?status=unaudited`}>
// //                                       <img className="img-fluid icon-width-height" src={ViewImage} />
// //                                     </Link>
// //                                   </td>
// //                                 </tr>
// //                               )
// //                             })
// //                           }
// //                         </tbody>
// //                       </table>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="col-md-12">
// //                   <nav aria-label="Page navigation example" className="pagination-1 pt-3 pb-3">
// //                     <ul className="pagination mb-0">
// //                       <li className="page-item next-item"><a className="page-link border-0" href="#">Previous</a></li>
// //                       <li className="page-item"><a className="page-link" href="#">1</a></li>
// //                       <li className="page-item previous-item mx-3"><span>Out of 100</span><a className="page-link border-0 active" href="#">Next</a></li>
// //                     </ul>
// //                   </nav>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   </div>
// // </div> */}